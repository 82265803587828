import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useTranslation } from 'react-i18next';

export const ResearchersStatusTooltips = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <TooltipContent id="tt-status-no-role" text={t('Researcher.NoRole')} />
      <TooltipContent id="tt-status-has-role" text={t('Researcher.HasRole')} />
    </>
  );
};

ResearchersStatusTooltips.displayName = 'ResearchersStatusTooltips';
