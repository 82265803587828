import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { adminActions, selectFilter } from 'api/adminSlice';
import { SelectCustomer } from 'components/SelectCustomer';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';

export const CustomerAdd = ({
  handleAdd,
  setIsAdding,
}: {
  handleAdd: (customerId: string) => void;
  setIsAdding: (isAdding: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);

  const handleChangeCustomer = (customerId: string) => {
    dispatch(adminActions.setFilterCustomerId(customerId));
  };

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (filter.customerId) {
      handleAdd(filter.customerId);
    }

    setIsAdding(false);
  };

  return (
    <Modal
      title={t('FeatureFlags.Rule.Customer.Add.Title')}
      handleKeyEnter={handleSave}
      handleKeyEsc={() => setIsAdding(false)}
    >
      <div>{t('FeatureFlags.Rule.Customer.Add.ConfirmText')}</div>

      <div>
        <SelectCustomer
          name={'customer'}
          customerId={filter.customerId}
          setCustomerId={handleChangeCustomer}
        />
      </div>

      <Buttons>
        <ButtonSecondary type="button" onClick={() => setIsAdding(false)}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>

        <Button type="button" onClick={handleSave}>
          {t('Common.Form.Save')}
        </Button>
      </Buttons>
    </Modal>
  );
};

CustomerAdd.displayName = 'CustomerAdd';
