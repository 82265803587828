import { Messages as MessagesFeature } from 'features/message';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Messages = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Navigation.Messages')}>
      <MessagesFeature />
    </View>
  );
};

Messages.displayName = 'Messages';
