import { Customers as CustomersFeature } from 'features/customer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Customers = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Navigation.Customers')}>
      <CustomersFeature />
    </View>
  );
};

Customers.displayName = 'Customers';
