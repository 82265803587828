import { Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { ApiError, BillingInfo } from 'models';
import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

export const CustomerProductBillingInfo = withTheme(
  ({
    theme,
    billingInfo,
    error,
    handleChange,
  }: {
    theme: Theme;
    billingInfo: BillingInfo;
    error?: ApiError;
    handleChange: (
      event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
  }): JSX.Element | null => {
    const { t } = useTranslation();

    return (
      <Style>
        <Heading4 style={{ marginTop: 30 }}>
          {t('Customer.BillingAddress.Title')}
        </Heading4>
        <div>
          <Label htmlFor="street">{t('Customer.BillingAddress.Street')}</Label>
          <Input
            type="text"
            id="street"
            name="street"
            value={billingInfo.street || ''}
            invalid={error && !billingInfo.street}
            onChange={handleChange}
            required
          />
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ width: 150 }}>
            <Label htmlFor="areaCode">
              {t('Customer.BillingAddress.PostalCode')}
            </Label>
            <Input
              type="text"
              id="areaCode"
              name="areaCode"
              value={billingInfo.areaCode || ''}
              invalid={error && !billingInfo.areaCode}
              style={{ width: 100 }}
              onChange={handleChange}
            />
          </div>

          <div style={{ flexGrow: 1 }}>
            <Label htmlFor="city">{t('Customer.BillingAddress.City')}</Label>
            <Input
              type="text"
              id="city"
              name="city"
              value={billingInfo.city || ''}
              invalid={error && !billingInfo.city}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <Label htmlFor="invoiceEmail">
            {t('Customer.BillingAddress.Reference')}
          </Label>
          <Input
            type="text"
            id="invoiceReference"
            name="invoiceReference"
            value={billingInfo.invoiceReference || ''}
            invalid={error && !billingInfo.invoiceReference}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          {' '}
          <Label htmlFor="invoiceEmail">
            {t('Customer.BillingAddress.Email')}
          </Label>
          <Input
            type="email"
            id="invoiceEmail"
            name="invoiceEmail"
            value={billingInfo.invoiceEmail || ''}
            invalid={error && !billingInfo.invoiceEmail}
            onChange={handleChange}
            required
          />
        </div>
      </Style>
    );
  }
);

CustomerProductBillingInfo.displayName = 'CustomerProductBillingInfo';

const Style = styled.div`
  > div {
    margin-bottom: 20px;
  }
`;
