import { Researchers as ResearchersFeature } from 'features/researchers';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Researchers = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Navigation.Researchers')}>
      <ResearchersFeature />
    </View>
  );
};

Researchers.displayName = 'Researchers';
