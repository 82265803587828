import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonLink,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  Td,
  TdCentered,
  TdRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import { useGetCustomersQuery } from 'api/customer/customerApi';
import produce from 'immer';
import { CustomerMutation, Rule } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPerson, BsPersonPlus, BsTrash } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { CustomerAdd } from './CustomerAdd';

export const RuleEdit = withTheme(
  ({
    selectedRule,
    rules,
    setRules,
    theme,
  }: {
    selectedRule: Rule;
    rules: Rule[];
    setRules: (rules: Rule[]) => void;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    const [isAdding, setIsAdding] = useState(false);
    const [rule, setRule] = useState<Rule>(selectedRule);

    const { data, isLoading, isFetching } = useGetCustomersQuery();

    const handleAddCustomer = (customerId: string) => {
      if (
        !data ||
        rule.values?.some((customer) => customer.id === customerId)
      ) {
        return;
      }

      const name = data.find((el) => el.id === customerId)?.name;
      const index = rules.findIndex((r) => r.id === rule.id);

      if (rule.values) {
        const newRule = {
          ...rule,
          values: [
            ...rule.values,
            {
              id: customerId,
              name,
            },
          ],
        };

        const newRules = produce(rules, (draftState) => {
          draftState[index] = { ...newRule };
        });

        setRule(newRule);
        setRules(newRules);
      }
    };

    const handleDeleteCustomer = (customerId: string) => {
      const index = rules.findIndex((r) => r.id === rule.id);
      const newCustomers = rule.values?.filter(
        (customer) => customer.id !== customerId
      );

      if (newCustomers) {
        const newRule = {
          ...rule,
          values: [...newCustomers],
        };

        const newRules = produce(rules, (draftState) => {
          draftState[index] = { ...newRule };
        });

        setRule(newRule);
        setRules(newRules);
      }
    };

    if (isLoading || isFetching) {
      return (
        <div style={{ margin: 10 }}>
          <LoaderSmall />
        </div>
      );
    }

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 15,
            marginBottom: 20,
          }}
        >
          <TooltipContent
            id="tt-customer-add"
            title={t('FeatureFlags.Rule.Customer.Add.TooltipTitle')}
            text={t('FeatureFlags.Rule.Customer.Add.Tooltip')}
          />
          <BadgeMini
            data-tip
            data-for="tt-customer-add"
            onClick={() => setIsAdding(true)}
            $inverted={true}
            style={{ backgroundColor: theme.colors.background }}
          >
            <BsPersonPlus />
          </BadgeMini>

          <ButtonLink
            data-tip
            data-for="tt-customer-add"
            onClick={() => setIsAdding(true)}
            style={{ marginLeft: 10 }}
          >
            {t('FeatureFlags.Rule.Customer.Add.Title')}
          </ButtonLink>

          <TooltipContent
            id="tt-rule-delete"
            title={t('FeatureFlags.Rule.Delete.TooltipTitle')}
            text={t('FeatureFlags.Rule.Delete.Tooltip')}
          />
        </div>

        {isAdding && (
          <CustomerAdd
            setIsAdding={setIsAdding}
            handleAdd={handleAddCustomer}
          />
        )}

        <Table>
          <tbody>
            {rule.values?.map((customer, index) => (
              <Tr key={index}>
                <TdCentered style={{ width: 30 }}>
                  <BsPerson />
                </TdCentered>
                <Td>{customer.name}</Td>
                <TdRight style={{ display: 'flex', justifyContent: 'end' }}>
                  <EditButtons
                    handleDeleteCustomer={handleDeleteCustomer}
                    customer={customer}
                    theme={theme}
                  />
                </TdRight>
              </Tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
);

RuleEdit.displayName = 'RuleEdit';

const EditButtons = ({
  customer,
  handleDeleteCustomer,
  theme,
}: {
  customer?: CustomerMutation;
  handleDeleteCustomer: (customerId: string) => void;
  theme: Theme;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (customer?.id) {
      handleDeleteCustomer(customer.id);
    }

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('FeatureFlags.Rule.Customer.Delete.ConfirmTitle')}>
          <div>{t('FeatureFlags.Rule.Customer.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button type="button" onClick={(e) => handleDelete(e)}>
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-customer-delete"
        title={t('FeatureFlags.Rule.Customer.Delete.TooltipTitle')}
        text={t('FeatureFlags.Rule.Customer.Delete.Tooltip')}
      />

      <div
        style={{
          width: 30,
        }}
      >
        <BadgeMini
          data-tip
          data-for="tt-customer-delete"
          onClick={(e) => handleConfirmDelete(e, true)}
          style={{ backgroundColor: theme.colors.surface }}
        >
          <BsTrash />
        </BadgeMini>
      </div>
    </>
  );
};
