import { adminApi, CacheTags } from 'api/adminApi';
import { Admin, PaginatedResult, User } from 'models';
import { AdminSearchUsers, AdminUser, ResearcherUser } from 'models/user';
import { nanoid } from 'nanoid';

const userApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Search users
     */
    search: build.query<PaginatedResult<AdminUser>, AdminSearchUsers>({
      query: (search) => {
        const filters = `${
          search.email ? `&filter.email=${search.email}` : ''
        }${search.userId ? `&filter.userId=${search.userId}` : ''}${
          search.name ? `&filter.name=${search.name}` : ''
        }${search.productId ? `&filter.productId=${search.productId}` : ''}${
          search.customerId ? `&filter.customerId=${search.customerId}` : ''
        }${
          search.isActive === true
            ? `&filter.isActive=true`
            : search.isActive === false
            ? `&filter.isActive=false`
            : ''
        }${
          search.roles && search.roles.length > 0
            ? search.roles.map((role) => `&filter.roles=${role}`).join('')
            : ''
        }${
          search.lastLoginFrom
            ? `&filter.lastLoginFrom=${search.lastLoginFrom}`
            : ''
        }${
          search.lastLoginTo ? `&filter.lastLoginTo=${search.lastLoginTo}` : ''
        }`;

        return `user/search?pageNumber=${search.paginator.page}&pageSize=${search.paginator.pageSize}&orderBy.propertyName=${search.paginator.orderBy}&orderBy.direction=${search.paginator.orderDirection}${filters}`;
      },
      transformResponse: (result: PaginatedResult<AdminUser>, _meta, _arg) => {
        return {
          ...result,
          records: result.records.map((user) => ({
            ...user,
            uid: nanoid(6),
          })),
        };
      },
      providesTags: [CacheTags.User],
    }),

    /**
     * Get global admins
     */
    getGlobalAdmins: build.query<User[], void>({
      query: () => `user/admins`,
      providesTags: [CacheTags.GlobalAdmin],
    }),

    /**
     * Get global admins
     */
    getResearchers: build.query<PaginatedResult<ResearcherUser>, void>({
      query: () => `user/researchers`,
      providesTags: [CacheTags.Researcher],
    }),

    /**
     * Create new user
     */
    addUser: build.mutation({
      query: (user: User | Admin) => ({
        url: `user/create`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [CacheTags.User, CacheTags.GlobalAdmin],
      transformResponse: (result: User, _meta, arg) => {
        return {
          ...arg,
          ...result,
        };
      },
    }),

    /**
     * Update user
     */
    updateUser: build.mutation({
      query: (user: User | Admin) => ({
        url: `user/${user.id}`,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [
        CacheTags.User,
        CacheTags.GlobalAdmin,
        CacheTags.Researcher,
      ],
      transformResponse: (result: User, _meta, arg) => {
        return {
          ...arg,
          ...result,
        };
      },
    }),

    /**
     * Delete user
     */
    deleteUser: build.mutation({
      query: (user: User | Admin) => ({
        url: `user`,
        method: 'DELETE',
        body: user,
      }),
      invalidatesTags: [CacheTags.User, CacheTags.GlobalAdmin],
    }),

    /**
     * Add researcher
     */
    addResearcher: build.mutation({
      query: ({
        productId,
        userId,
      }: {
        productId: string;
        userId: string;
      }) => ({
        url: `user/researchers`,
        method: 'POST',
        body: {
          productId,
          userId,
        },
      }),
      invalidatesTags: [CacheTags.User, CacheTags.Researcher],
      transformResponse: (result: User, _meta, arg) => {
        return {
          ...arg,
          ...result,
        };
      },
    }),

    /**
     * Delete researcher
     */
    deleteResearcher: build.mutation({
      query: ({
        productId,
        userId,
      }: {
        productId: string;
        userId: string;
      }) => ({
        url: `user/researchers`,
        method: 'DELETE',
        body: {
          productId,
          userId,
        },
      }),
      invalidatesTags: [CacheTags.User, CacheTags.Researcher],
    }),
  }),
});

export const {
  useSearchQuery,
  useLazySearchQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useGetGlobalAdminsQuery,
  useAddResearcherMutation,
  useDeleteResearcherMutation,
  useLazyGetResearchersQuery,
} = userApi;
