import { adminApi, CacheTags } from 'api/adminApi';
import {
  Invoice,
  InvoiceStatus,
  PaginatedResult,
  SearchInvoices,
} from 'models';
import { nanoid } from 'nanoid';

// TODO: Implement

const billingApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get invoices
     */
    getInvoices: build.query<PaginatedResult<Invoice>, SearchInvoices>({
      query: (search) => {
        const filters = `${
          search.status
            ? `&filter.status=${search.status}`
            : `&filter.status=${InvoiceStatus.Sent}`
        }${search.year ? `&filter.year=${search.year}` : ''}${
          search.productId ? `&filter.productId=${search.productId}` : ''
        }${search.customerId ? `&filter.customerId=${search.customerId}` : ''}`;

        return `license/invoice/list?pageNumber=${search.paginator.page}&pageSize=${search.paginator.pageSize}&orderBy.propertyName=${search.paginator.orderBy}&orderBy.direction=${search.paginator.orderDirection}${filters}`;
      },
      transformResponse: (result: PaginatedResult<Invoice>, _meta, _arg) => {
        return {
          ...result,
          records: result.records.map((invoice) => ({
            ...invoice,
            uid: nanoid(6),
          })),
        };
      },
      providesTags: [CacheTags.Invoice],
    }),

    /**
     * Add or update invoices
     */
    addOrUpdateInvoices: build.mutation({
      query: (invoices: Invoice[]) => ({
        url: `license/invoices`,
        method: 'PUT',
        body: invoices,
      }),
      invalidatesTags: [CacheTags.Invoice],
    }),
  }),
});

export const { useLazyGetInvoicesQuery, useAddOrUpdateInvoicesMutation } =
  billingApi;
