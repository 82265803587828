import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useTranslation } from 'react-i18next';

export const CustomerProductStatusTooltips = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <TooltipContent
        id="tt-status-notregistered"
        text={t('Customer.CustomerProduct.NotRegistered')}
      />
      <TooltipContent
        id="tt-status-deactivated"
        text={t('Customer.CustomerProduct.Deactivated')}
      />
      <TooltipContent
        id="tt-status-approved"
        text={t('Customer.CustomerProduct.Approved')}
      />
      <TooltipContent
        id="tt-status-notapproved"
        text={t('Customer.CustomerProduct.NotApproved')}
      />
      <TooltipContent
        id="tt-license-type-discontinued"
        text={t('Customer.CustomerProduct.LicenceTypeDiscontinued')}
      />
    </>
  );
};

CustomerProductStatusTooltips.displayName = 'CustomerProductStatusTooltips';
