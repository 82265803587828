import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterProducts, InvoiceStatus } from 'models';
import { RootState } from 'store';

type State = {
  filter: FilterProducts;
};

const initialState: State = {
  filter: {
    //    period: new Date().getFullYear(),
  },
};

export const { reducer: adminReducer, actions: adminActions } = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<FilterProducts>) => {
      state.filter = action.payload;
    },
    setFilterProductId: (state, action: PayloadAction<string>) => {
      state.filter.productId = action.payload;
    },
    setFilterLicenseTypeId: (state, action: PayloadAction<string>) => {
      state.filter.licenseTypeId = action.payload;
    },
    setFilterPeriod: (state, action: PayloadAction<number | undefined>) => {
      state.filter.period = action.payload;
    },
    setFilterActiveLicense: (state, action: PayloadAction<boolean>) => {
      state.filter.hasActiveLicense = action.payload;
    },
    setFilterCustomerId: (state, action: PayloadAction<string>) => {
      state.filter.customerId = action.payload;
    },
    setFilterStatus: (state, action: PayloadAction<InvoiceStatus>) => {
      state.filter.status = action.payload;
    },
    resetFilter: (state) => {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (build) => {
    build.addCase(appActions.resetApplication, () => initialState);
  },
});

export const selectFilter = (state: RootState): FilterProducts =>
  state.admin.filter;
