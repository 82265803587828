import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { SearchBox } from '@dimatech/shared/lib/components/Search';
import { Checkbox } from '@dimatech/shared/lib/components/form';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import {
  LinkWithTooltip,
  TooltipContent,
} from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatDate } from '@dimatech/shared/lib/utils';
import { parseISO } from 'date-fns';
import produce from 'immer';
import { Recipient } from 'models';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsCheckAll,
  BsCircleFill,
  BsPerson,
  BsTrash,
  BsX,
} from 'react-icons/bs';
import { withTheme } from 'styled-components';

export const RecipientsList = withTheme(
  ({
    recipients,
    setRecipients,
    handleDeleteRecipients,
    theme,
  }: {
    recipients: Recipient[];
    setRecipients: Dispatch<SetStateAction<Recipient[]>>;
    handleDeleteRecipients: (items: Recipient[]) => void;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    const [search, setSearch] = useState<string>('');
    const [filtered, setFiltered] = useState<Recipient[]>([]);
    const [showSelectAll, setShowSelectAll] = useState<boolean>(false);

    const {
      items: sortedRecipients,
      setPage,
      paginator,
      sorter,
    } = useUiSortablePaginator({
      orderBy: 'email',
      pageSize: 25,
      data: {
        totalRecords: filtered.length,
        records: filtered,
      },
    });

    const noOfSelected = recipients.filter(
      (recipient) => recipient.isSelected
    ).length;
    const noOfTotal = recipients.length;
    const isAllChecked = noOfSelected === noOfTotal;

    const handleSelectAll = (checked: boolean) => {
      const newRecipients = recipients.map((recipient) => ({
        ...recipient,
        isSelected: checked,
      }));

      setRecipients(newRecipients);
    };

    const handleSelect = (
      e: ChangeEvent<HTMLInputElement>,
      recipient: Recipient
    ) => {
      const index = recipients.findIndex((r) => r.userId === recipient.userId);

      const newRecipients = produce(recipients, (draftState) => {
        draftState[index] = {
          ...recipient,
          isSelected: e.target.checked,
        };
      });

      setRecipients(newRecipients);
    };

    useEffect(() => {
      const newRecipients = recipients.filter(
        (recipient) => recipient.read === null || recipient.read === undefined
      );

      setShowSelectAll(newRecipients.length > 0);

      setFiltered(
        recipients.filter((respondent) =>
          respondent.email?.toLowerCase().includes(search.toLowerCase())
        ) ?? []
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, recipients]);

    return (
      <>
        {recipients.length === 0 && (
          <div style={{ marginTop: 20 }}>
            {t('Message.Recipient.NoRecipients')}
          </div>
        )}

        {showSelectAll && (
          <>
            <div
              style={{
                margin: '10px 0',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <LinkWithTooltip
                handleClick={() => handleSelectAll(!isAllChecked)}
                title={
                  isAllChecked
                    ? t(`Common.UI.DeselectAll`)
                    : t(`Common.UI.SelectAll`)
                }
                isInverted={true}
                icon={isAllChecked ? <BsX /> : <BsCheckAll />}
                disabledTimerInMs={1}
              />

              {recipients.filter((r) => r.isSelected).length > 0 && (
                <LinkWithTooltip
                  style={{ marginLeft: 15 }}
                  handleClick={() => {
                    handleDeleteRecipients(
                      recipients.filter((r) => r.isSelected)
                    );
                  }}
                  title={t(`Message.Recipient.Delete.ForRemoval`, {
                    count: recipients.filter((r) => r.isSelected).length,
                  })}
                  isInverted={true}
                  icon={<BsTrash />}
                  isDisabled={noOfSelected === 0}
                  disabledTimerInMs={500}
                />
              )}
            </div>

            {recipients.length > 0 && (
              <SearchBox
                style={{
                  margin: '20px 0',
                }}
                name={search}
                setName={setSearch}
                placeholder={t('User.SearchPlaceholder')}
              />
            )}
          </>
        )}

        <Pagination
          currentPage={paginator.page}
          totalCount={filtered.length}
          pageSize={paginator.pageSize}
          handlePageChange={(page) => setPage(page)}
        />

        {recipients && recipients.length > 0 && (
          <Table style={{ marginTop: 20, marginBottom: 30 }}>
            <thead>
              <tr>
                <Th style={{ width: 35 }} />
                <Th sortKey="email" sorter={sorter} className="narrow">
                  {t('Message.Recipient.Email')}
                </Th>
                <Th sortKey="read" sorter={sorter} className="narrow">
                  {t('Message.Recipient.Read')}
                </Th>
                <ThRight style={{ width: 35 }} />
              </tr>
            </thead>

            <tbody>
              {sortedRecipients?.map((recipient, index) => (
                <Tr key={index}>
                  <Td>
                    {recipient.read === null || recipient.read === undefined ? (
                      <Checkbox
                        onChange={(e) => {
                          handleSelect(e, recipient);
                        }}
                        id={index + `_${recipient.userId}`}
                        name={recipient.userId}
                        value={recipient.userId}
                        checked={!!recipient.isSelected}
                      />
                    ) : (
                      <BsPerson />
                    )}
                  </Td>
                  <Td className="narrow">{recipient.email}</Td>
                  <Td className="narrow">
                    {recipient.read && (
                      <BsCircleFill
                        style={{
                          marginRight: 8,
                          color: theme.colors.success,
                        }}
                      />
                    )}

                    {recipient.read
                      ? formatDate(parseISO(recipient.read))
                      : '-'}
                  </Td>
                  <TdRight>
                    {!recipient.read && (
                      <>
                        <TooltipContent
                          id="tt-recipient-delete"
                          title={t('Message.Recipient.Delete.TooltipTitle')}
                          text={t('Message.Recipient.Delete.Tooltip')}
                        />

                        <BadgeMini
                          data-tip
                          data-for="tt-recipient-delete"
                          onClick={() => handleDeleteRecipients([recipient])}
                        >
                          <BsTrash />
                        </BadgeMini>
                      </>
                    )}
                  </TdRight>
                </Tr>
              ))}
            </tbody>
          </Table>
        )}

        <Pagination
          currentPage={paginator.page}
          totalCount={filtered.length}
          pageSize={paginator.pageSize}
          handlePageChange={(page) => setPage(page)}
          style={{ marginBottom: 20 }}
        />
      </>
    );
  }
);

RecipientsList.displayName = 'RecipientsList';
