import { PrivateRoute } from '@dimatech/features-core/lib/components/PrivateRoute';
import { AuthenticationProvider } from '@dimatech/features-core/lib/features/authentication';
import { useLaunchDarkly } from '@dimatech/features-core/lib/hooks';
import { CommonRoles } from '@dimatech/features-core/lib/models';
import { ApplicationProvider } from '@dimatech/shared/lib/application';
import { LocalizationProvider } from '@dimatech/shared/lib/localization';
import { ThemeProvider } from '@dimatech/shared/lib/themes';
import { TrackingProvider } from '@dimatech/shared/lib/tracking';
import { themes } from 'assets/themes';
import { GlobalStyles } from 'assets/themes/GlobalStyles';
import { ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Billing } from 'views/Billing';
import { Context } from 'views/Context';
import { Customers } from 'views/Customers';
import { FeatureFlags } from 'views/FeatureFlags';
import { GlobalAdmins } from 'views/GlobalAdmins';
import { Login } from 'views/Login';
import { Message } from 'views/Message';
import { Messages } from 'views/Messages';
import { Products } from 'views/Products';
import { Researchers } from 'views/Researchers';
import { StyleGuide } from 'views/StyleGuide';
import { Unauthorized } from 'views/Unauthorized';
import { Users } from 'views/Users';

const App = (): JSX.Element => {
  return (
    <AuthenticationProvider>
      <WithAuthentication>
        <LocalizationProvider>
          <TrackingProvider>
            <ThemeProvider themes={themes}>
              <ApplicationProvider>
                <GlobalStyles />
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route path="/style-guide" element={<StyleGuide />} />
                  <Route
                    path="/products"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Products />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/billing"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Billing />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admins"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <GlobalAdmins />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/features"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <FeatureFlags />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/context"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Context />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Users />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/researchers"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Researchers />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/messages"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Messages />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/message/:userMessageId"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Message />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <PrivateRoute requireRole={[CommonRoles.GlobalAdmin]}>
                        <Customers />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </ApplicationProvider>
            </ThemeProvider>
          </TrackingProvider>
        </LocalizationProvider>
      </WithAuthentication>
    </AuthenticationProvider>
  );
};

export default App;

// We need to separate this from App so we can access AuthenticationContext
const WithAuthentication = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  useLaunchDarkly();

  return <>{children}</>;
};
