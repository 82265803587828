import { Select } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { useGetProductsQuery } from 'api/product/productApi';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectProduct = ({
  productId,
  setProductId,
  style,
  autoFocus,
  name,
  disabled,
}: {
  productId?: string;
  setProductId: (product: string) => void;
  style?: CSSProperties;
  autoFocus?: boolean;
  name?: string;
  disabled?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { data: products, isLoading } = useGetProductsQuery();

  if (isLoading) {
    return (
      <div style={{ margin: 10 }}>
        <LoaderSmall />
      </div>
    );
  }

  return (
    <>
      <Select
        name={name}
        autoFocus={!!autoFocus}
        value={productId ?? ''}
        onChange={(e) => setProductId(e.currentTarget.value)}
        style={style}
        disabled={disabled}
      >
        <option value="">{t('Filter.AllProducts')}</option>
        {products?.map((product) => (
          <option key={product.id} value={product.id}>
            {product.name}
          </option>
        ))}
      </Select>
    </>
  );
};

SelectProduct.displayName = 'SelectProduct';
