import {
  Table,
  Td,
  TdCentered,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Product } from 'models';
import { useTranslation } from 'react-i18next';
import { BsBox } from 'react-icons/bs';

export const ProductsList = ({
  products,
}: {
  products?: Product[];
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Table>
      <thead>
        <tr>
          <Th />
          <Th>{t('Product.Product')}</Th>
          <Th>{t('Product.Description')}</Th>
          <Th>{t('Product.Id')}</Th>
        </tr>
      </thead>
      <tbody>
        {products?.map((item, index) => (
          <Tr key={index}>
            <TdCentered style={{ width: 30 }}>
              <BsBox />
            </TdCentered>
            <Td>{item.name}</Td>
            <Td>{item.description}</Td>
            <Td>{item.id}</Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};

ProductsList.displayName = 'ProductsList';
