import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonLink,
  ButtonSecondary,
  Buttons,
  Select,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  Td,
  TdCentered,
  TdRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import { toTitleCase } from '@dimatech/shared/lib/utils';
import { useGetCustomersQuery } from 'api/customer/customerApi';
import { useGetContextKindsQuery } from 'api/feature/featureApi';
import produce from 'immer';
import { Clause, ClauseValue, User } from 'models';
import { Dispatch, SetStateAction, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { BsPerson, BsPersonPlus, BsTrash } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { ClauseDelete } from './ClauseDelete';
import { CustomerAdd } from './CustomerAdd';
import { UserAdd } from './UserAdd';

export const ClauseProperties = withTheme(
  ({
    clause,
    index,
    clauses,
    setClauses,
    isValid,
    theme,
  }: {
    clause: Clause;
    index: number;
    clauses: Clause[];
    setClauses: Dispatch<SetStateAction<Clause[]>>;
    isValid: boolean;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    const { data: kinds } = useGetContextKindsQuery();
    const [isAddingValue, setIsAddingValue] = useState(false);
    const { data: customers } = useGetCustomersQuery();
    const contextKindName = toTitleCase(clause.contextKind as string);

    const handleAddCustomer = (customerId: string) => {
      if (
        !customers ||
        (clause &&
          clause.values?.some((customer) => customer.id === customerId))
      ) {
        return;
      }

      const name = customers.find(
        (customer) => customer.id === customerId
      )?.name;

      if (clause.values) {
        const newClause = {
          ...clause,
          values: [
            ...clause.values,
            {
              id: customerId,
              name,
            },
          ],
        };

        const newClauses = produce(clauses, (draftState) => {
          draftState[index] = { ...newClause };
        });

        setClauses(newClauses);
      } else {
        const newClause = {
          ...clause,
          values: [
            {
              id: customerId,
              name,
            },
          ],
        };

        const newClauses = produce(clauses, (draftState) => {
          draftState[index] = { ...newClause };
        });

        setClauses(newClauses);
      }
    };

    const handleAddUser = (user: User) => {
      if (clauses[index].values?.some((u) => u.email === user.email)) {
        return;
      }

      if (clause?.values) {
        const newClause = {
          ...clause,
          values: [
            ...clause.values,
            {
              id: user.id,
              email: user.email,
            },
          ],
        };

        const newClauses = produce(clauses, (draftState) => {
          draftState[index] = { ...newClause };
        });

        setClauses(newClauses);
      } else {
        const newClause = {
          ...clause,
          values: [
            {
              id: user.id,
              email: user.email,
            },
          ],
        };

        const newClauses = produce(clauses, (draftState) => {
          draftState[index] = { ...newClause };
        });

        setClauses(newClauses);
      }
    };

    const handleDeleteValue = (valueId: string) => {
      const newClause = {
        ...clause,
        values: clause.values?.filter((value) => value.id !== valueId),
      };

      const newClauses = produce(clauses, (draftState) => {
        draftState[index] = { ...newClause };
      });

      setClauses(newClauses);
    };

    const handleDeleteClause = (index: number) => {
      setClauses(
        produce(clauses, (draftState) => {
          draftState.splice(index, 1);
        })
      );
    };

    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div>
            <Select
              name={'contextKind'}
              value={clause.contextKind ?? ''}
              invalid={!isValid && !clause.contextKind}
              onChange={(e) => {
                setClauses(
                  produce(clauses, (draftState) => {
                    draftState[index] = {
                      ...clause,
                      contextKind: e.currentTarget.value,
                      values: [],
                    };
                  })
                );
              }}
              style={{ width: '300px', marginRight: '20px' }}
            >
              <option value="">
                {t('FeatureFlags.Rule.ContextKind.Title')}
              </option>
              {kinds?.map((kind) => (
                <option key={kind.key} value={kind.key}>
                  {t(`FeatureFlags.Rule.ContextKind.${kind.name}`)}
                </option>
              ))}
            </Select>

            <Select
              name="negate"
              id="negate"
              value={
                clause?.negate === true
                  ? '1'
                  : clause?.negate === false
                  ? '0'
                  : `${null}`
              }
              invalid={!isValid && typeof clause.negate !== 'boolean'}
              onChange={(e) => {
                setClauses(
                  produce(clauses, (draftState) => {
                    draftState[index] = {
                      ...clause,
                      negate:
                        e.target.value === '1'
                          ? true
                          : e.target.value === '0'
                          ? false
                          : null,
                    };
                  })
                );
              }}
              style={{ width: '300px', marginRight: '20px' }}
            >
              <option value="null">
                {t('FeatureFlags.Rule.Negate.Title')}
              </option>
              <option value="1">{t('FeatureFlags.Rule.Negate.IsOneOf')}</option>
              <option value="0">
                {t('FeatureFlags.Rule.Negate.IsNotOneOf')}
              </option>
            </Select>
          </div>

          {contextKindName !== '' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 15,
                marginBottom: 20,
              }}
            >
              <TooltipContent
                id={`tt-${contextKindName}-add`}
                title={t(
                  `FeatureFlags.Rule.${contextKindName}.Add.TooltipTitle`
                )}
                text={t(`FeatureFlags.Rule.${contextKindName}.Add.Tooltip`)}
              />
              <BadgeMini
                data-tip
                data-for={`tt-${contextKindName}-add`}
                onClick={() => setIsAddingValue(true)}
                $inverted={true}
                style={{ backgroundColor: theme.colors.background }}
              >
                <BsPersonPlus />
              </BadgeMini>
              <ButtonLink
                data-tip
                data-for={`tt-${contextKindName}-add`}
                onClick={() => setIsAddingValue(true)}
                style={{
                  marginLeft: 10,
                  borderColor:
                    !isValid && clause.values?.length === 0
                      ? theme.colors.error
                      : '',
                }}
              >
                {t(`FeatureFlags.Rule.${contextKindName}.Add.Title`)}
              </ButtonLink>
            </div>
          )}

          <ClauseDelete
            handleDeleteItem={() => handleDeleteClause(index)}
            index={index}
          />
        </div>

        <div>
          {isAddingValue === true && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {contextKindName === 'Customer' && (
                <CustomerAdd
                  setIsAdding={setIsAddingValue}
                  handleAdd={handleAddCustomer}
                />
              )}

              {contextKindName === 'User' && (
                <UserAdd
                  setIsAdding={setIsAddingValue}
                  handleAdd={handleAddUser}
                />
              )}
            </div>
          )}
          <Table>
            <tbody>
              {clause?.values?.map((value, index) => (
                <Tr key={index}>
                  <TdCentered style={{ width: 30 }}>
                    <BsPerson />
                  </TdCentered>
                  <Td>{value.name ?? value.email}</Td>
                  <TdRight style={{ display: 'flex', justifyContent: 'end' }}>
                    <EditButtons
                      handleDeleteItem={handleDeleteValue}
                      item={value}
                      contextKindName={contextKindName}
                    />
                  </TdRight>
                </Tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
);
ClauseProperties.displayName = 'ClauseProperties';

const EditButtons = ({
  item,
  handleDeleteItem,
  contextKindName,
}: {
  item: ClauseValue;
  handleDeleteItem: (itemId: string) => void;
  contextKindName: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (item.id) {
      handleDeleteItem(item.id);
    }

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal
          title={t(`FeatureFlags.Rule.${contextKindName}.Delete.ConfirmTitle`)}
        >
          <div>
            {t(`FeatureFlags.Rule.${contextKindName}.Delete.ConfirmText`)}
          </div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button type="button" onClick={(e) => handleDelete(e)}>
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id={`tt-${contextKindName}-delete`}
        title={t(`FeatureFlags.Rule.${contextKindName}.Delete.TooltipTitle`)}
        text={t(`FeatureFlags.Rule.${contextKindName}.Delete.Tooltip`)}
      />

      <div
        style={{
          width: 30,
        }}
      >
        <BadgeMini
          data-tip
          data-for={`tt-${contextKindName}-delete`}
          onClick={(e) => handleConfirmDelete(e, true)}
        >
          <BsTrash />
        </BadgeMini>
      </div>
    </>
  );
};
