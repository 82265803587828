import { Users as UsersFeature } from 'features/users';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Users = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Navigation.Administrators')}>
      <UsersFeature />
    </View>
  );
};

Users.displayName = 'Users';
