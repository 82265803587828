import {
  Table,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatDate } from '@dimatech/shared/lib/utils';
import { selectSelectedResearcher, userActions } from 'api/user/userSlice';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Product, ResearcherUser, SortDirection } from 'models';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircle, BsCircleFill, BsPersonBadge } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { ResearchersStatusTooltips } from './ResearchersStatusTooltips';

export const ResearchersList = withTheme(
  ({
    users,
    products,
    theme,
  }: {
    users?: ResearcherUser[];
    products: Product[];
    theme: Theme;
  }): JSX.Element => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const selectedResearcher = useAppSelector(selectSelectedResearcher);

    const { items, sorter } = useSortableData(users ?? [], {
      key: 'email',
      direction: SortDirection.Asc,
    });

    const handleSelect = (researcher: ResearcherUser) => {
      dispatch(userActions.selectResearcher(researcher));
    };

    return (
      <>
        {!selectedResearcher && (
          <>
            <ResearchersStatusTooltips />
            <Table style={{ minWidth: 300 }}>
              <thead>
                <tr>
                  <Th style={{ width: 35 }} />
                  <Th sortKey="email" sorter={sorter}>
                    {t('User.Email')}
                  </Th>
                  {products?.map((product) => (
                    <Fragment key={product.id}>
                      <Th
                        style={{ width: 200 }}
                        sortKey={`has-role-${product.id}`}
                        sorter={sorter}
                        sortPredicate={(a, b) => {
                          const productA = a.products?.find(
                            (p) => p === product.id
                          );

                          const productB = b.products?.find(
                            (p) => p === product.id
                          );
                          return {
                            a: productA,
                            b: productB,
                          };
                        }}
                      >
                        {product.name}
                      </Th>
                    </Fragment>
                  ))}
                  <ThRight sortKey="lastLoginDate" sorter={sorter}>
                    {t('User.LastLogin')}
                  </ThRight>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <Tr key={index} onSelect={() => handleSelect(item)}>
                    <Td>
                      <BsPersonBadge />
                    </Td>
                    <Td>{item.email}</Td>
                    {products?.map((product) => (
                      <Fragment key={product.id}>
                        <Td>
                          {item.products?.find((p) => p === product.id) ? (
                            <BsCircleFill
                              data-tip
                              data-for="tt-status-has-role"
                              style={{
                                marginRight: 8,
                                color: theme.colors.success,
                              }}
                            />
                          ) : (
                            <BsCircle
                              data-tip
                              data-for="tt-status-no-role"
                              style={{
                                marginRight: 8,
                              }}
                            />
                          )}
                        </Td>
                      </Fragment>
                    ))}
                    <TdRight
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item.lastLoginDate
                        ? formatDate(parseISO(item.lastLoginDate))
                        : '-'}
                    </TdRight>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </>
    );
  }
);

ResearchersList.displayName = 'ResearchersList';
