import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { Rule } from 'models';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { RuleEdit } from './RuleEdit';

export const RulesList = withTheme(
  ({
    rules,
    setRules,
    theme,
  }: {
    rules: Rule[];
    setRules: (rules: Rule[]) => void;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div>
        <Heading3 style={{ marginTop: 30 }}>
          {t('FeatureFlags.Rule.Customer.Customers')}
        </Heading3>

        {rules.length === 0 && (
          <div style={{ paddingBottom: '6px' }}>
            {t('FeatureFlags.Rule.NoRules')}
          </div>
        )}

        {rules.length > 0 && (
          <Style>
            {rules.map((rule, index) => (
              <Fragment key={index}>
                <div>
                  <RuleStyle>
                    {rule.value === true ? (
                      <div
                        style={{
                          borderBottom: `2px solid ${theme.colors.success}`,
                        }}
                      >
                        <BsCircleFill
                          style={{
                            color: theme.colors.success,
                          }}
                        />
                        <div>{t('FeatureFlags.OnOff.On')}</div>
                        {/* <div> {rule.op}</div> */}
                      </div>
                    ) : (
                      <div
                        style={{
                          borderBottom: `2px solid ${theme.colors.error}`,
                        }}
                      >
                        <BsCircleFill
                          style={{
                            color: theme.colors.error,
                          }}
                        />
                        <div>{t('FeatureFlags.OnOff.Off')}</div>
                        {/* <div>{rule.op}</div> */}
                      </div>
                    )}
                  </RuleStyle>

                  {rule.clauses && (
                    <RuleEdit
                      selectedRule={rule}
                      rules={rules}
                      setRules={setRules}
                    />
                  )}
                </div>
              </Fragment>
            ))}
          </Style>
        )}
      </div>
    );
  }
);

RulesList.displayName = 'RulesList';

const Style = styled.div`
  padding: 5px;

  > div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  table {
    border-top: 1px solid
      ${({ theme }: { theme: Theme }) => theme.colors.border};
  }
`;

const RuleStyle = styled.div`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
      margin-right: 7px;
    }
  }
`;
