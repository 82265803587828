import { GlobalAdmins as GlobalAdminsFeature } from 'features/global-admins';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const GlobalAdmins = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Navigation.Administrators')}>
      <GlobalAdminsFeature />
    </View>
  );
};

GlobalAdmins.displayName = 'GlobalAdmins';
