import {
  NavigationItem,
  NavigationSeparator,
} from '@dimatech/shared/lib/components/workspace';
import { Color } from '@dimatech/shared/lib/themes';
import { config } from 'config';
import { useTranslation } from 'react-i18next';
import {
  BsApp,
  BsBox,
  BsCardText,
  BsFlag,
  BsJournalCheck,
  BsLightbulb,
  BsPeople,
  BsPerson,
  BsPersonBadge,
  BsPiggyBank,
} from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';

export const Navigation = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <NavigationItem
        $selected={
          location.pathname === '/customers' || location.pathname === '/'
        }
      >
        <BsPeople />
        <Link to="/customers">{t('Navigation.Customers')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/billing'}>
        <BsPiggyBank />
        <Link to="/billing">{t('Navigation.Billing')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/products'}>
        <BsBox />
        <Link to="/products">{t('Navigation.Products')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/users'}>
        <BsPerson />
        <Link to="/users">{t('Navigation.Users')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/admins'}>
        <BsPersonBadge />
        <Link to="/admins">{t('Navigation.Administrators')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/features'}>
        <BsFlag />
        <Link to="/features">{t('Navigation.FeatureFlags')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/context'}>
        <BsJournalCheck />
        <Link to="/context">{t('Navigation.Contexts')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/researchers'}>
        <BsLightbulb />
        <Link to="/researchers">{t('Navigation.Researchers')}</Link>
      </NavigationItem>

      <NavigationItem $selected={location.pathname === '/messages'}>
        <BsCardText />
        <Link to="/messages">{t('Navigation.Messages')}</Link>
      </NavigationItem>

      <NavigationSeparator />

      <NavigationItem>
        <BsApp style={{ color: Color.DimiosBlue }} />
        <a href={config.products.dimios.url}>{t('Account.Dimios.Name')}</a>
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.RadicalRed }} />
        <a href={config.products.pios.url}>{t('Account.Pios.Name')}</a>
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.SandyBrown }} />
        <a href={config.products.dios.url}>{t('Account.Dios.Name')}</a>
      </NavigationItem>

      <NavigationItem>
        <BsApp style={{ color: Color.Amethyst }} />
        <a href={config.products.dikios.url}>{t('Account.Dikios.Name')}</a>
      </NavigationItem>
    </>
  );
};

Navigation.displayName = 'Navigation';
