import { Input } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import { Table, Td, Th, Tr } from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { saveTableAsExcel } from '@dimatech/shared/lib/utils';
import { selectFilter } from 'api/adminSlice';
import { useGetCustomersQuery } from 'api/customer/customerApi';
import {
  customerActions,
  selectSelectedCustomer,
} from 'api/customer/customerSlice';
import { useGetLicensesTypesQuery } from 'api/license/licenseApi';
import { useGetProductsQuery } from 'api/product/productApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CountryCode, CustomerMutation } from 'models';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel, BsSearch } from 'react-icons/bs';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import { mapCustomerProducts } from 'utils';
import { CustomerFilters } from './CustomerFilters';
import { CustomerProductStatus } from './CustomerProductStatus';
import { CustomerProductStatusTooltips } from './CustomerProductStatusTooltips';
import { Statistics } from './Statistics';

export const CustomersList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedCustomer = useAppSelector(selectSelectedCustomer);
  const filter = useAppSelector(selectFilter);
  const [customers, setCustomers] = useState<CustomerMutation[] | undefined>();
  const [filtered, setFiltered] = useState<CustomerMutation[]>([]);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 300);

  const { data, isFetching } = useGetCustomersQuery(true);
  const { data: products, isFetching: isFetchingProducts } =
    useGetProductsQuery();
  const { data: licensesTypes, isFetching: isFetchingLicensesTypes } =
    useGetLicensesTypesQuery();

  const { items, setPage, paginator, sorter } = useUiSortablePaginator({
    orderBy: 'name',
    pageSize: 100,
    data: {
      totalRecords: filtered.length,
      records: filtered,
    },
  });

  const handleSelect = (customer: CustomerMutation) => {
    dispatch(customerActions.selectCustomer(customer));
    dispatch(customerActions.selectCustomerProduct());
  };

  useEffect(() => {
    if (data && products && licensesTypes) {
      const customers = mapCustomerProducts(data, products, licensesTypes);

      setCustomers(customers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, products, licensesTypes]);

  useEffect(() => {
    const filtered =
      customers?.filter((customer) => {
        const normalized = debouncedSearch.toLowerCase();
        const year = filter.period as number;

        // Free text search
        if (
          normalized &&
          !customer.name?.toLowerCase().includes(normalized) &&
          !customer.organizationNumber?.includes(normalized)
        ) {
          return false;
        }

        // Is international
        if (filter.isInternational && customer.countryCode === CountryCode.SE) {
          return false;
        }

        if (
          !customer.products?.some(
            (product) =>
              // Filter on product
              (!filter.productId ||
                (product.productId === filter.productId &&
                  product.isRegistered)) &&
              // Active license
              (!filter.hasActiveLicense || product.haveActiveLicense) &&
              // License type
              (!filter.licenseTypeId ||
                product.licenses?.some(
                  (license) => license.licenseTypeId === filter.licenseTypeId
                )) &&
              // Period
              (!filter.period ||
                product.licenses?.some(
                  (license) =>
                    license.startDate < year &&
                    (!license.endDate || license.endDate > year)
                ))
          )
        ) {
          return false;
        }

        return true;
      }) ?? [];

    setFiltered(filtered);

    if (selectedCustomer) {
      dispatch(customerActions.selectCustomer());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, customers, filter]);

  useEffect(() => {
    if (customers && selectedCustomer) {
      const customer = customers.find((cu) => cu.id === selectedCustomer?.id);

      dispatch(customerActions.selectCustomer(customer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (isFetching || isFetchingProducts || isFetchingLicensesTypes) {
    return <LoaderSmall>{t('Common.UI.Loading')}</LoaderSmall>;
  }

  return (
    <>
      {data && data.length > 0 && (
        <SearchContainer>
          <div>
            <Input
              value={search}
              placeholder={t('Common.UI.Search')}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
            <BsSearch />
          </div>

          <CustomerFilters />

          <LinkWithTooltip
            style={{ marginTop: 10, marginBottom: 10, marginLeft: 'auto' }}
            handleClick={() =>
              saveTableAsExcel('exp-customers', t('Customer.Customers'))
            }
            title={t('Common.Export.SaveAsCsv')}
            tooltipTitle={t('Common.Export.SaveAsCsv')}
            tooltip={t('Common.Export.SaveElementAsCsv', {
              title: t('Customer.Customers'),
            })}
            icon={<BsFileExcel />}
          />
        </SearchContainer>
      )}

      {!selectedCustomer && (
        <>
          <CustomerProductStatusTooltips />

          <Pagination
            style={{ marginBottom: 20 }}
            currentPage={paginator.page}
            totalCount={filtered.length}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />

          <Table id="exp-customers">
            <thead>
              <tr>
                <Th />
                <Th style={{ minWidth: 130 }} sortKey="name" sorter={sorter}>
                  {t('Customer.Name')}
                </Th>
                <Th
                  style={{ minWidth: 100 }}
                  sortKey="countryCode"
                  sorter={sorter}
                >
                  {t('Localisation.Country.Title')}
                </Th>
                <Th
                  style={{ width: 150 }}
                  sortKey="organizationNumber"
                  sorter={sorter}
                >
                  {t('Customer.OrgNumber')}
                </Th>
                <Th
                  style={{ width: 150 }}
                  sortKey="customerType"
                  sorter={sorter}
                >
                  {t('Organisation.Type')}
                </Th>

                {products?.map((product) => (
                  <Fragment key={product.id}>
                    <Th
                      style={{ width: 200 }}
                      sortKey={`approved-${product.id}`}
                      sorter={sorter}
                      sortPredicate={(a, b) => {
                        const productA = a.products?.find(
                          (p) => p.productId === product.id
                        );

                        const productB = b.products?.find(
                          (p) => p.productId === product.id
                        );

                        const nameA = `${productA?.isRegistered?.toString()}${productA?.deactivated?.toString()}${productA?.isApproved?.toString()}${
                          productA?.activeLicenseType?.name
                        }`;

                        const nameB = `${productB?.isRegistered?.toString()}${productB?.deactivated?.toString()}${productB?.isApproved?.toString()}${
                          productB?.activeLicenseType?.name
                        }`;

                        return {
                          a: nameA,
                          b: nameB,
                        };
                      }}
                    >
                      {product.name}
                    </Th>
                  </Fragment>
                ))}
              </tr>
            </thead>

            <tbody>
              {items.map((item, index) => (
                <Tr key={index} onSelect={() => handleSelect(item)}>
                  <Td className="narrow">
                    {item.logoContent && (
                      <a
                        href={`https://sv.wikipedia.org/w/index.php?search=${item.name}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          borderBottom: 'none',
                        }}
                      >
                        <SvgImage item={item} />
                      </a>
                    )}
                  </Td>

                  <Td className="narrow">{item.name}</Td>
                  <Td>
                    {t(`Localisation.Country.Countries.${item.countryCode}`)}
                  </Td>
                  <Td>{item.organizationNumber}</Td>
                  <Td>{t(`Customer.Type.${item.customerType}`)}</Td>

                  {item.products?.map((customerProduct) => (
                    <Fragment key={customerProduct.productId}>
                      <Td data-value={customerProduct.activeLicenseType?.name}>
                        <CustomerProductStatus product={customerProduct} />
                        {customerProduct.haveActiveLicense &&
                          customerProduct.activeLicenseType &&
                          t(
                            `LicenseType.${customerProduct.activeLicenseType?.name.replace(
                              /\s/g,
                              '_'
                            )}`
                          )}
                        {}
                      </Td>
                    </Fragment>
                  ))}
                </Tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            currentPage={paginator.page}
            totalCount={filtered.length}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />

          {filtered && <Statistics customers={filtered} />}
        </>
      )}
    </>
  );
};

CustomersList.displayName = 'CustomersList';

const SearchContainer = styled.div`
  padding: 3px;

  width: 100%;
  max-width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  margin-bottom: 20px;

  label {
    margin-top: 0;
    margin-right: 10px;
    white-space: nowrap;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 15px;

    .react-datepicker-wrapper {
      input {
        max-width: 50px;
      }
    }
  }

  > div:first-of-type {
    > input {
      width: 300px;
      padding-right: 20px;
    }

    > svg {
      margin-left: -20px;
      cursor: pointer;
    }
  }
`;

const SvgImage = ({ item }: { item: CustomerMutation }): JSX.Element => {
  const src = `data:${item.logoContentType};base64,${item.logoContent}`;
  return (
    <img
      style={{ height: 16, width: 16, verticalAlign: 'middle' }}
      src={src}
      alt="Logo"
    />
  );
};
