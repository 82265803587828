import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { Paginator, SortDirection } from '@dimatech/shared/lib/models';
import { useGetProductsQuery } from 'api/product/productApi';
import { useLazyGetResearchersQuery } from 'api/user/userApi';
import { selectSelectedResearcher, userActions } from 'api/user/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import styled from 'styled-components';
import { Researcher } from './components/Researcher';
import { ResearchersList } from './components/ResearchersList';

export const Researchers = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedResearcher = useAppSelector(selectSelectedResearcher);
  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 100,
    orderBy: 'email',
    orderDirection: SortDirection.Asc,
  };

  const [getResearchers, { data, isFetching }] = useLazyGetResearchersQuery();

  const { data: products, isFetching: isFetchingProducts } =
    useGetProductsQuery();

  const handleGetResearchers = (paginator: Paginator = initialPaginator) => {
    dispatch(userActions.selectResearcher());

    getResearchers();
  };

  const handleCreate = () => {
    dispatch(userActions.selectResearcher({}));
  };

  useEffect(() => {
    handleGetResearchers(initialPaginator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Researcher.Researchers')}</Heading1>
        <div>
          <TooltipContent
            id="tt-researcher-add"
            title={t('Researcher.Add.TooltipTitle')}
            text={t('Researcher.Add.Tooltip')}
          />
          <BadgeMini
            data-tip
            data-for="tt-researcher-add"
            onClick={handleCreate}
            $inverted={true}
          >
            <BsPlus />
          </BadgeMini>
        </div>
      </ViewHeader>

      <Card width="100%">
        <CardBody isLoading={isFetching || isFetchingProducts}>
          <Style>
            <div>
              {products && (
                <ResearchersList users={data?.records} products={products} />
              )}

              {selectedResearcher && products && (
                <Researcher products={products} />
              )}
            </div>
          </Style>
        </CardBody>
      </Card>
    </>
  );
};

Researchers.displayName = 'Researchers';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > div:last-of-type {
    > div {
      margin-bottom: 20px;
      max-width: 700px;
    }
  }
`;
