import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonLink,
  Buttons,
  ButtonSecondary,
  Select,
} from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { useUpdateFeatureFlagMutation } from 'api/feature/featureApi';
import { Clause, Feature } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { ClauseAdd } from './ClauseAdd';
import { ClauseProperties } from './ClauseProperties';

export const RuleAdd = withTheme(
  ({ feature, theme }: { feature?: Feature; theme: Theme }): JSX.Element => {
    const { t } = useTranslation();
    const [addRule, setAddRule] = useState(false);
    const [clauses, setClauses] = useState<Clause[]>([]);
    const [variationId, setVariationId] = useState<string>('');

    const [isAddingClause, setIsAddingClause] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const [updateFeatureFlag] = useUpdateFeatureFlagMutation();

    const handleSave = () => {
      setIsValid(true);

      if (
        variationId === '' ||
        clauses.length === 0 ||
        clauses.some(
          (clause) =>
            clause.values?.length === 0 ||
            clause.contextKind === '' ||
            typeof clause.negate !== 'boolean'
        )
      ) {
        setIsValid(false);
        return;
      }

      if (feature?.key) {
        updateFeatureFlag({
          actions: [
            {
              ...feature,
              kind: 'AddRule',
              variationId,
              description: '',
              clauses: clauses.map((clause) => ({
                ...clause,
                values: clause.values
                  ? clause.values
                      .filter((value) => value.id)
                      .map((value) => value.id as string)
                  : [],
              })),
            },
          ],
          key: feature?.key,
        });
      }
      setAddRule(false);
      setIsAddingClause(false);
    };

    const handleCancel = () => {
      setAddRule(false);
      setClauses([]);
      setIsValid(true);
      setVariationId('');
    };

    return (
      <div>
        {!addRule && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: 15,
              marginBottom: 20,
            }}
          >
            <TooltipContent
              id="tt-rule-add"
              title={t('FeatureFlags.Rule.Add.TooltipTitle')}
              text={t('FeatureFlags.Rule.Add.Tooltip')}
            />
            <BadgeMini
              data-tip
              data-for="tt-rule-add"
              onClick={() => setAddRule(true)}
              $inverted={true}
              style={{ backgroundColor: theme.colors.background }}
            >
              <BsPlus />
            </BadgeMini>

            <ButtonLink
              data-tip
              data-for="tt-rule-add"
              onClick={() => setAddRule(true)}
              style={{ marginLeft: 10 }}
            >
              {t('FeatureFlags.Rule.Add.Title')}
            </ButtonLink>
          </div>
        )}

        {addRule && (
          <div
            style={{
              backgroundColor: theme.colors.background,
              padding: '10px',
            }}
          >
            <Heading2> {t('FeatureFlags.Rule.NewRule')}</Heading2>
            <Select
              name={'variationId'}
              value={variationId ?? ''}
              invalid={!isValid && !variationId}
              onChange={(e) => {
                setVariationId(e.currentTarget.value);
              }}
              style={{ width: '300px', marginRight: '20px' }}
            >
              <option value="">{t('FeatureFlags.Rule.Variation.Title')}</option>
              {feature?.variations?.map((variation) => (
                <option key={variation.id} value={variation.id}>
                  {t(`FeatureFlags.Rule.Variation.${variation.value}`)}
                </option>
              ))}
            </Select>

            {[...clauses]?.map((clause, index) => (
              <ClauseProperties
                key={index}
                clause={clause}
                index={index}
                clauses={clauses}
                setClauses={setClauses}
                theme={theme}
                isValid={isValid}
              />
            ))}

            <ClauseAdd
              clauses={clauses}
              setClauses={setClauses}
              setIsValid={setIsValid}
              isValid={isValid}
            />
            <Buttons>
              <ButtonSecondary type="button" onClick={handleCancel}>
                {t('Common.Form.Cancel')}
              </ButtonSecondary>

              {!isAddingClause && (
                <Button type="button" onClick={handleSave}>
                  {t('Common.Form.Save')}
                </Button>
              )}
            </Buttons>
          </div>
        )}
      </div>
    );
  }
);

RuleAdd.displayName = 'RuleAdd';
