import { Theme } from '@dimatech/shared/lib/themes';
import { CustomerProduct } from 'models';
import { useTranslation } from 'react-i18next';
import {
  BsCircle,
  BsCircleFill,
  BsExclamationCircleFill,
} from 'react-icons/bs';
import { withTheme } from 'styled-components';

export const CustomerProductStatus = withTheme(
  ({
    product,
    isLabelShown = false,
    theme,
  }: {
    product: CustomerProduct;
    isLabelShown?: boolean;
    theme: Theme;
  }): JSX.Element | null => {
    const { t } = useTranslation();

    return (
      <>
        {!product.isRegistered && (
          <>
            <BsCircle
              data-tip
              data-for="tt-status-notregistered"
              style={{ marginRight: 8 }}
            />
            {isLabelShown && t('Customer.CustomerProduct.NotRegistered')}
          </>
        )}

        {product.isRegistered && product.deactivated && (
          <>
            <BsCircleFill
              data-tip
              data-for="tt-status-deactivated"
              style={{ marginRight: 8, color: theme.colors.error }}
            />
            {isLabelShown && t('Customer.CustomerProduct.Deactivated')}
          </>
        )}

        {product.isRegistered && !product.deactivated && product.isApproved && (
          <>
            <BsCircleFill
              data-tip
              data-for="tt-status-approved"
              style={{
                marginRight: 8,
                color: theme.colors.success,
              }}
            />
            {isLabelShown && t('Customer.CustomerProduct.Approved')}
          </>
        )}

        {product.isRegistered && !product.deactivated && !product.isApproved && (
          <>
            <BsCircleFill
              data-tip
              data-for="tt-status-notapproved"
              style={{
                marginRight: 8,
                color: theme.colors.warning,
              }}
            />
            {isLabelShown && t('Customer.CustomerProduct.NotApproved')}
          </>
        )}

        {product.haveActiveLicense &&
          product.activeLicenseType &&
          product.activeLicenseType?.isDiscontinued && (
            <BsExclamationCircleFill
              data-tip
              data-for="tt-license-type-discontinued"
              style={{
                marginRight: 8,
                marginLeft: isLabelShown ? 8 : 0,
                color: theme.colors.error,
              }}
            />
          )}
      </>
    );
  }
);

CustomerProductStatus.displayName = 'CustomerProductStatus';
