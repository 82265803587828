import { adminApi, CacheTags } from 'api/adminApi';
import { BillingInfo, CustomerProduct, CustomerProductMutation } from 'models';

const customerProductApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get customer products
     */
    getCustomerProducts: build.query<CustomerProduct[], string>({
      query: (customerId) => `customer/${customerId}/products`,
      providesTags: [CacheTags.CustomerProduct],
    }),

    /**
     * Approve product registration for customer
     */
    approveCustomerProduct: build.mutation({
      query: ({
        customerId,
        productId,
      }: {
        customerId: string;
        productId: string;
      }) => ({
        url: `customer/product`,
        method: 'POST',
        body: {
          customerId,
          productId,
        },
      }),
      invalidatesTags: [CacheTags.Customer, CacheTags.CustomerProduct],
    }),

    /**
     * Disapprove product registration for customer
     */
    disapproveCustomerProduct: build.mutation({
      query: ({
        customerId,
        productId,
      }: {
        customerId: string;
        productId: string;
      }) => ({
        url: `customer/product/ban`,
        method: 'POST',
        body: {
          customerId,
          productId,
        },
      }),
      invalidatesTags: [CacheTags.Customer, CacheTags.CustomerProduct],
    }),

    /**
     * Register product for customer
     */
    registerCustomerProduct: build.mutation({
      query: ({
        customerId,
        licenseTypeId,
        billingInfo,
        productId,
      }: {
        customerId: string;
        licenseTypeId: string;
        billingInfo: BillingInfo;
        productId: string;
      }) => ({
        url: `customer/product`,
        method: 'PUT',
        body: {
          customerId,
          licenseTypeId,
          billingInfo,
          productId,
        },
      }),
      invalidatesTags: [CacheTags.Customer, CacheTags.CustomerProduct],
    }),

    /**
     * Unregister product for customer
     */
    deleteCustomerProduct: build.mutation({
      query: (customerProduct: CustomerProductMutation) => ({
        url: `customer/product`,
        method: 'DELETE',
        body: {
          ...customerProduct,
        },
      }),
      invalidatesTags: [CacheTags.Customer, CacheTags.CustomerProduct],
    }),

    /**
     * Register product for customer
     */
    updateCustomerProductBillingInfo: build.mutation({
      query: ({
        customerId,
        productId,
        billingInfo,
      }: {
        customerId: string;
        productId: string;
        billingInfo: BillingInfo;
      }) => ({
        url: `customer/${customerId}/billinginfo`,
        method: 'PUT',
        body: {
          productId,
          billingInfo,
        },
      }),
      invalidatesTags: [CacheTags.Customer, CacheTags.CustomerProduct],
    }),

    /**
     * Activate product registration for customer
     */
    activateCustomerProduct: build.mutation({
      query: ({
        customerId,
        productId,
      }: {
        customerId: string;
        productId: string;
      }) => ({
        url: `customer/product/activation`,
        method: 'POST',
        body: {
          customerId,
          productId,
        },
      }),
      invalidatesTags: [CacheTags.Customer, CacheTags.CustomerProduct],
    }),

    /**
     * Deactivate product registration for customer
     */
    deactivateCustomerProduct: build.mutation({
      query: ({
        customerId,
        productId,
      }: {
        customerId: string;
        productId: string;
      }) => ({
        url: `customer/product/deactivation`,
        method: 'POST',
        body: {
          customerId,
          productId,
        },
      }),
      invalidatesTags: [CacheTags.Customer, CacheTags.CustomerProduct],
    }),

    /**
     * Deactivate all customers with expired licenses
     */
    deactivateExpiredCustomerProducts: build.mutation({
      query: ({ productId }: { productId?: string }) => ({
        url: `customer/product/deactivation/expired`,
        method: 'PUT',
        body: {
          productId,
        },
      }),
      invalidatesTags: [CacheTags.Customer, CacheTags.CustomerProduct],
    }),
  }),
});

export const {
  useGetCustomerProductsQuery,
  useApproveCustomerProductMutation,
  useRegisterCustomerProductMutation,
  useDeleteCustomerProductMutation,
  useDisapproveCustomerProductMutation,
  useUpdateCustomerProductBillingInfoMutation,
  useActivateCustomerProductMutation,
  useDeactivateCustomerProductMutation,
  useDeactivateExpiredCustomerProductsMutation,
} = customerProductApi;
