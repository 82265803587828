import { CopyToClipboard } from '@dimatech/shared/lib/components/CopyToClipboard';
import { Button } from '@dimatech/shared/lib/components/form';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import {
  contextActions,
  selectSelectedContext,
} from 'api/feature/contextSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ContextModel } from 'models/context';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BsJournalCheck } from 'react-icons/bs';
import styled from 'styled-components';
import { ContextFilters } from './ContextFilters';

export const ContextsList = ({
  data,
  handleGetContexts,
  isError,
}: {
  data?: ContextModel[];
  handleGetContexts: () => void;
  isError: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedContext = useAppSelector(selectSelectedContext);

  const handleSelect = (context: ContextModel) => {
    dispatch(
      contextActions.selectedContext(
        selectedContext?.uid === context.uid ? undefined : context
      )
    );
  };

  const handleSearch = () => {
    handleGetContexts();
  };

  return (
    <>
      <Style>
        <ContextFilters />
        <Button type="button" onClick={() => handleSearch()}>
          {t('Context.Search')}
        </Button>
      </Style>

      {((data && data?.length === 0) || isError) && (
        <div>{t('Context.NoMatches')}</div>
      )}

      {data && data?.length > 0 && !isError && (
        <TableResponsiveContainer>
          <Table>
            <thead>
              <tr>
                <Th style={{ width: 35 }} />
                <Th>{t('Context.Kind')}</Th>
                <Th>{t('Context.Name')}</Th>
                <Th>{t('Context.Key')}</Th>
                <Th>{t('Context.CopyKey')}</Th>
                <ThRight>{t('Context.Instances')}</ThRight>
              </tr>
            </thead>
            <tbody>
              {data?.map((context, index) => (
                <Fragment key={index}>
                  <Tr
                    key={index}
                    isSelected={selectedContext?.uid === context.uid}
                    onSelect={(e) => {
                      if ((e.target as HTMLElement).nodeName !== 'TD') {
                        return;
                      }
                      handleSelect(context);
                    }}
                  >
                    <Td>
                      <BsJournalCheck />
                    </Td>
                    <Td>{context.context.kind}</Td>
                    <Td>
                      {context.context.kind === 'customer'
                        ? context.context.name
                        : context.context.key}
                    </Td>
                    <Td>{context.context.key}</Td>
                    <Td>
                      <CopyToClipboard
                        value={context.context.key}
                        text={t('Context.CopyKey')}
                        tooltipId="tt-link-copy"
                      />
                    </Td>
                    <TdRight>{context.associatedContexts}</TdRight>
                  </Tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>
      )}
    </>
  );
};

ContextsList.displayName = 'ContextsList';

const Style = styled.div`
  display: flex;
  padding: 0 10px 10px 0;
  flex-wrap: wrap;
  align-items: center;

  > select,
  div:first-of-type {
    width: 220px;
    margin-right: 20px;
  }

  > button {
    min-width: 60px;
  }
`;
