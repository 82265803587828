import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminUser, ResearcherUser, User } from 'models';
import { RootState } from 'store';

const initialState: {
  selectedUser?: User;
  selectedAdmin?: User;
  selectedResearcher?: User;
  selectedUsers?: AdminUser[];
} = {};

export const { reducer: userReducer, actions: userActions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    selectUser: (state, action: PayloadAction<User | undefined>) => {
      state.selectedUser = action.payload;
    },
    selectAdmin: (state, action: PayloadAction<User | undefined>) => {
      state.selectedAdmin = action.payload;
    },
    selectResearcher: (
      state,
      action: PayloadAction<ResearcherUser | undefined>
    ) => {
      state.selectedResearcher = action.payload;
    },
    selectedUsers: (state, action: PayloadAction<AdminUser[] | undefined>) => {
      state.selectedUsers = action.payload;
    },
  },
  extraReducers: (build) => {
    build.addCase(appActions.resetApplication, (state) => {
      state.selectedUser = initialState.selectedUser;
      state.selectedAdmin = initialState.selectedAdmin;
    });
  },
});

export const selectSelectedUser = (state: RootState): User | undefined =>
  state.user.selectedUser;

export const selectSelectedAdmin = (state: RootState): User | undefined =>
  state.user.selectedAdmin;

export const selectSelectedResearcher = (
  state: RootState
): ResearcherUser | undefined => state.user.selectedResearcher;

export const selectSelectedUsers = (
  state: RootState
): AdminUser[] | undefined => state.user.selectedUsers;
