import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { useGetGlobalAdminsQuery } from 'api/user/userApi';
import { selectSelectedAdmin, userActions } from 'api/user/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import styled from 'styled-components';
import { GlobalAdmin } from './components/GlobalAdmin';
import { GlobalAdminsList } from './components/GlobalAdminsList';

export const GlobalAdmins = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedAdmin = useAppSelector(selectSelectedAdmin);
  const { data: users, isLoading } = useGetGlobalAdminsQuery();

  const handleCreate = () => {
    dispatch(userActions.selectAdmin({}));
  };

  useEffect(() => {
    dispatch(userActions.selectAdmin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Administrator.Administrators')}</Heading1>
        <div>
          <TooltipContent
            id="tt-admin-add"
            title={t('Administrator.Add.TooltipTitle')}
            text={t('Administrator.Add.Tooltip')}
          />
          <BadgeMini
            data-tip
            data-for="tt-admin-add"
            onClick={handleCreate}
            $inverted={true}
          >
            <BsPlus />
          </BadgeMini>
        </div>
      </ViewHeader>

      <Card width="100%">
        <CardBody isLoading={isLoading}>
          <Style>
            <div>
              <GlobalAdminsList users={users} />

              {selectedAdmin && <GlobalAdmin />}
            </div>
          </Style>
        </CardBody>
      </Card>
    </>
  );
};

GlobalAdmins.displayName = 'GlobalAdmins';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > div:last-of-type {
    > div {
      margin-bottom: 20px;
      max-width: 700px;
    }
  }
`;
