import { FeatureFlags as FeatureFlagsFeature } from 'features/feature-flags';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const FeatureFlags = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('FeatureFlags.Title')}>
      <FeatureFlagsFeature />
    </View>
  );
};

FeatureFlags.displayName = 'FeatureFlags';
