import { Paginator } from '@dimatech/shared/lib/models';

export type SearchInvoices = {
  productId?: string;
  customerId?: string;
  year?: number;
  paginator: Paginator;
  status?: InvoiceStatus;
};

export type BillingInfo = {
  areaCode?: string;
  city?: string;
  street?: string;
  invoiceReference?: string;
  invoiceEmail?: string;
};

export type InvoiceMutation = {
  id?: string;
  sentDate?: string;
  status?: InvoiceStatus;
  startYear?: number;
  endYear?: number;
  amount?: number;
  comment?: string | null;
  externalInvoiceId?: string;

  productId?: string;
  customerId?: string;
  licenseId?: string;
};

export type Invoice = {
  id: string | null;
  amount?: number;

  customerId?: string;
  customerName?: string;
  productId?: string;
  status: InvoiceStatus;
  startYear: number;
  endYear: number;
  comment: string | null;
  organizationNumber?: string;
  contactName?: string;
  contactEmail?: string;
  invoiceEmail?: string;
  invoiceReference?: string;
  licenseTypeName?: string;
  licenseId?: string;
  licenseComment: string | null;
  externalInvoiceId: string;

  basePrice: number;
  hasOwnAgreement: boolean;
  ownAgreementComment: string | null;
  ownAgreementPrice: number | null;

  sentDate: string;
  created: string;
  uid: string;
  isSelected?: boolean;
};

export enum InvoiceStatus {
  New = 'New',
  Sent = 'Sent',
  Payed = 'Payed',
}
