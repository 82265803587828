import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { CopyToClipboard } from '@dimatech/shared/lib/components/CopyToClipboard';
import {
  Button,
  ButtonFooterWithToast,
  Buttons,
  ButtonSecondary,
  Input,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { useUpdateUserMutation } from 'api/user/userApi';
import { selectSelectedUser, userActions } from 'api/user/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { User as UserModel } from 'models';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const User = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedUser = useAppSelector(selectSelectedUser);

  const [updateUser, { isLoading, error }] = useUpdateUserMutation();

  const [user, setUser] = useState<UserModel>({});
  const [isChangesSaved, setIsChangesSaved] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser);
    }
  }, [selectedUser]);

  const handleChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.currentTarget;

    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  const handleSave = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!user.id) {
      return;
    }

    updateUser({
      ...user,
    })
      .unwrap()
      .then(() => {
        setIsChangesSaved(true);
      });
  };

  const handleCancel = () => {
    dispatch(userActions.selectUser());
  };

  return user ? (
    <div>
      {user?.id && <Heading2 style={{ marginTop: 5 }}>{user.email}</Heading2>}

      <AlertErrors error={error} translationPath="User.ValidationError" />

      <div>
        <Label htmlFor="name">{t('User.Email')}</Label>
        <Input
          type="text"
          id="email"
          name="email"
          value={user.email || ''}
          onChange={handleChange}
        />
      </div>

      {!user.id && (
        <div>
          <Label htmlFor="name">{t('User.Password')}</Label>
          <Input
            type="password"
            id="password"
            name="password"
            onChange={handleChange}
          />
        </div>
      )}

      {user.id && (
        <div>
          <Label htmlFor="name">{t('User.Id')}</Label>
          <div
            style={{
              marginTop: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input style={{ maxWidth: 200 }} value={user.id} disabled={true} />
            <CopyToClipboard
              value={user.id}
              text={t('User.CopyId')}
              tooltipId="tt-link-copy"
            />
          </div>
        </div>
      )}

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>

          <Button type="button" onClick={handleSave} disabled={isLoading}>
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </div>
  ) : null;
};

User.displayName = 'User';
