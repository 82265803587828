import {
  CustomerMutation,
  CustomerProduct,
  License,
  LicenseType,
  Product,
} from 'models';

/**
 * Map customer, products and licenses
 * @param customers
 * @param products
 * @param licensesTypes
 * @returns
 */
export const mapCustomerProducts = (
  customers: CustomerMutation[],
  products: Product[],
  licensesTypes: LicenseType[]
): CustomerMutation[] => {
  const mapped = customers?.map((customer) => {
    const customerProducts = products?.map((product) => {
      const customerProduct = customer.products?.find(
        (customerProduct) => customerProduct.productId === product.id
      );

      const activeLicense = customerProduct?.licenses?.find(
        (license: License) =>
          new Date(license.startDate.toString()) <= new Date() &&
          (!license.endDate ||
            new Date(license.endDate.toString()) > new Date())
      );

      const activeLicenseType = licensesTypes?.find(
        (type) => activeLicense?.licenseTypeId === type.id
      );

      return {
        ...product,
        ...customerProduct,
        activeLicenseType,
        activeLicense,
        isRegistered: !!customerProduct,
        isApproved: !!customerProduct?.isApproved,
        haveActiveLicense: !!activeLicense,
        deactivated: !!customerProduct?.deactivated,
        productId: product.id,
        customerId: customer?.id,
      } as CustomerProduct;
    });

    return {
      ...customer,
      products: customerProducts,
    } as CustomerMutation;
  });

  return mapped;
};
