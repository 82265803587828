import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureList, Rule, Variation } from 'models/features';
import { RootState } from 'store';

const initialState: {
  selectedFeature?: FeatureList;
  selectedVariation?: Variation;
  selectedRule?: Rule;
} = {};

export const { reducer: featureReducer, actions: featureActions } = createSlice(
  {
    name: 'feature',
    initialState,
    reducers: {
      selectedFeature: (
        state,
        action: PayloadAction<FeatureList | undefined>
      ) => {
        state.selectedFeature = action.payload;
      },
      selectedVariation: (
        state,
        action: PayloadAction<Variation | undefined>
      ) => {
        state.selectedVariation = action.payload;
      },
      selectedRule: (state, action: PayloadAction<Rule | undefined>) => {
        state.selectedRule = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedFeature = initialState.selectedFeature;
      });
    },
  }
);

export const selectSelectedFeature = (
  state: RootState
): FeatureList | undefined => state.features.selectedFeature;

export const selectSelectedRule = (state: RootState): Rule | undefined =>
  state.features.selectedRule;

export const selectSelectedVariation = (
  state: RootState
): Variation | undefined => state.features.selectedVariation;
