import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { isValidEmail } from '@dimatech/shared/lib/utils';
import { useLazyGetUserMessagesQuery } from 'api/message/messageApi';
import { initialState, selectFilter } from 'api/message/messageSlice';
import { useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { MessageList } from './components/MessageList';

export const Messages = (): JSX.Element | null => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const filter = useAppSelector(selectFilter);

  const [getUserMessages, { data: messages, isLoading, isFetching, isError }] =
    useLazyGetUserMessagesQuery();

  const handleGetMessages = () => {
    if (
      filter.email &&
      filter.email.length > 0 &&
      !isValidEmail(filter.email)
    ) {
      return;
    }

    getUserMessages({ filter });
  };

  const handleCreate = () => {
    navigate(`/message/new`);
  };

  useEffect(() => {
    if (filter === initialState.filter) {
      handleGetMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (isFetching) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Message.Messages')}</Heading1>
        <div>
          <TooltipContent
            id="tt-message-add"
            title={t('Message.Add.TooltipTitle')}
            text={t('Message.Add.Tooltip')}
          />
          <BadgeMini
            data-tip
            data-for="tt-message-add"
            onClick={handleCreate}
            $inverted={true}
          >
            <BsPlus />
          </BadgeMini>
        </div>
      </ViewHeader>

      <Card width="100%">
        <CardBody isLoading={isLoading || isFetching}>
          <MessageList
            messages={messages}
            handleGetMessages={handleGetMessages}
            isError={isError}
          />
        </CardBody>
      </Card>
    </>
  );
};

Messages.displayName = 'Messages';
