import { BackButton } from '@dimatech/shared/lib/components/BackButton';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { shorten } from '@dimatech/shared/lib/utils';
import { useGetUserMessagesQuery } from 'api/message/messageApi';
import {
  messageActions,
  selectFilter,
  selectSelectedMessage,
} from 'api/message/messageSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { MessageProperties } from './components/MessageProperties';

export const Message = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userMessageId = '' } = useParams();

  const filter = useAppSelector(selectFilter);
  const selectedMessage = useAppSelector(selectSelectedMessage);

  const {
    data: messages,
    isLoading,
    isFetching,
  } = useGetUserMessagesQuery({ filter });

  const handleBack = () => {
    navigate(`/messages`);
  };

  useEffect(() => {
    if (messages && userMessageId) {
      const selected = messages.find((m) => m.id === userMessageId);

      dispatch(messageActions.selectMessage(selected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  if (isFetching) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      <ViewHeader>
        <div>
          <BackButton handleBack={handleBack} />
        </div>

        <Heading1>
          {selectedMessage?.title ? shorten(selectedMessage.title, 50) : ''}
        </Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody isLoading={isLoading || isFetching}>
          <MessageProperties />
        </CardBody>
      </Card>
    </>
  );
};

Message.displayName = 'Message';
