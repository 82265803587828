import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { CopyToClipboard } from '@dimatech/shared/lib/components/CopyToClipboard';
import {
  Button,
  ButtonFooterWithToast,
  Buttons,
  ButtonSecondary,
  Input,
  Label,
  Select,
} from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { useUpdateCustomerMutation } from 'api/customer/customerApi';
import {
  customerActions,
  selectSelectedCustomer,
} from 'api/customer/customerSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CountryCode, CustomerMutation as CustomerModel, Locale } from 'models';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Customer = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedCustomer = useAppSelector(selectSelectedCustomer);

  const [updateCustomer, { isLoading, error }] = useUpdateCustomerMutation();

  const [customer, setCustomer] = useState<CustomerModel | undefined>({});
  const [isChangesSaved, setIsChangesSaved] = useState(false);

  useEffect(() => {
    setCustomer(selectedCustomer);
  }, [selectedCustomer]);

  const handleChange = (
    e: SyntheticEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const target = e.currentTarget;

    setCustomer({
      ...customer,
      [target.name]: target.value,
    });
  };

  const handleSave = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!customer?.id) {
      return;
    }

    updateCustomer({
      ...customer,
    })
      .unwrap()
      .then(() => {
        setIsChangesSaved(true);
      });
  };

  const handleCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(customerActions.selectCustomer());
  };

  return customer ? (
    <div>
      {customer.id && (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Heading2 style={{ marginTop: 5 }}>{customer.name}</Heading2>
          <CopyToClipboard
            style={{ marginLeft: 20 }}
            value={customer.id ?? ''}
            text={t('Customer.CopyId')}
            tooltipId="tt-link-copy"
          />
        </div>
      )}

      <AlertErrors error={error} translationPath="Register.ValidationError" />

      <div
        style={{
          marginTop: 20,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ marginRight: 20, minWidth: 200, width: '30%' }}>
          <Label htmlFor="name">{t('Customer.OrgNumber')}</Label>
          <Input
            type="text"
            id="organizationNumber"
            name="organizationNumber"
            value={customer.organizationNumber || ''}
            invalid={error && !customer.organizationNumber}
            onChange={handleChange}
          />
        </div>

        <div style={{ minWidth: 200, width: '70%' }}>
          <Label htmlFor="name">{t('Customer.Name')}</Label>
          <Input
            type="text"
            id="name"
            name="name"
            // Disabled name changes for existing customers, needs to be manually
            // renamed since name is duplicated in the database
            disabled={!!customer.id}
            value={customer.name || ''}
            invalid={error && !customer.name}
            onChange={handleChange}
          />
        </div>
      </div>

      <div
        style={{
          marginTop: 20,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ marginRight: 20, minWidth: 150, width: '50%' }}>
          <Label htmlFor="countryCode">{t('Localisation.Country.Title')}</Label>
          <Select
            name="countryCode"
            id="countryCode"
            value={customer.countryCode}
            onChange={handleChange}
          >
            {Object.values(CountryCode).map((value) => (
              <option key={value} value={value}>
                {t(`Localisation.Country.Countries.${value}`)}
              </option>
            ))}
          </Select>
        </div>

        <div style={{ marginRight: 20, minWidth: 150, width: '50%' }}>
          <Label htmlFor="countryCode">{t('Localisation.Locale.Title')}</Label>
          <Select
            name="locale"
            id="locale"
            value={customer.locale}
            onChange={handleChange}
          >
            {[...Object.keys(Locale)]
              .sort((a, b) => (a > b ? 1 : -1))
              .map((key) => (
                <option key={key} value={Locale[key as keyof typeof Locale]}>
                  {t(`Localisation.Locale.Locales.${key}.Name`)}
                </option>
              ))}
          </Select>
        </div>
      </div>

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        <Buttons style={{ justifyContent: 'flex-start' }}>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.UI.Back')}
          </ButtonSecondary>
        </Buttons>

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>

          <Button type="button" onClick={handleSave} disabled={isLoading}>
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </div>
  ) : null;
};

Customer.displayName = 'Customer';
