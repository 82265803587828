import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import {
  Button,
  Buttons,
  ButtonSecondary,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { User } from '@dimatech/shared/lib/models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const UserAdd = ({
  handleAdd,
  setIsAdding,
}: {
  handleAdd: (user: User) => void;
  setIsAdding: (isAdding: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const [user, setUser] = useState<User | null>(null);

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!user?.email) {
      return;
    }

    handleAdd(user);
    setIsAdding(false);
  };

  return (
    <Modal
      title={t('FeatureFlags.Rule.User.Add.Title')}
      handleKeyEnter={handleSave}
      handleKeyEsc={() => setIsAdding(false)}
    >
      <div>{t('FeatureFlags.Rule.User.Add.Text')}</div>

      <Label htmlFor="respondent">
        {t('FeatureFlags.Rule.User.Add.Email')}
      </Label>
      <SearchUser user={user} setUser={setUser} admin={true} />

      <Buttons>
        <ButtonSecondary type="button" onClick={() => setIsAdding(false)}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>
        <Button type="button" onClick={handleSave}>
          {t('FeatureFlags.Rule.User.Add.Title')}
        </Button>
      </Buttons>
    </Modal>
  );
};

UserAdd.displayName = 'UserAdd';
