import { Label } from '@dimatech/shared/lib/components/form';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { ViewHeader } from '@dimatech/shared/lib/components/workspace';
import {
  copyToClipboard,
  formatDate,
  saveTableAsExcel,
} from '@dimatech/shared/lib/utils';
import { selectSelectedUser, userActions } from 'api/user/userSlice';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { AdminUser, PaginatedResult, Paginator, User } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileExcel, BsFiles, BsPerson } from 'react-icons/bs';

export const UsersList = ({
  data,
  initialPaginator,
  handlePaginatorChange,
}: {
  data?: PaginatedResult<AdminUser>;
  initialPaginator: Paginator;
  handlePaginatorChange: (paginator: Paginator) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(selectSelectedUser);
  const [users, setUsers] = useState<AdminUser[]>([]);

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange,
  });

  const handleSelectOne = (user: User) => {
    dispatch(userActions.selectUser(user));
  };

  const handleCopy = () => {
    if (data?.records) {
      const text = data.records.map((user) => user.email).join(';');
      copyToClipboard(text);
    }
  };

  useEffect(() => {
    if (data) {
      setUsers(data?.records);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.records]);

  if (!data) {
    return null;
  }

  return data && data.totalRecords === 0 ? (
    <div>{t('User.NoMatches')}</div>
  ) : (
    <>
      {!selectedUser && (
        <>
          <ViewHeader>
            <Heading2 style={{ display: 'flex' }}>
              <LinkWithTooltip
                style={{
                  margin: '10px 20px 10px 0',
                }}
                handleClick={() =>
                  saveTableAsExcel('exp-users', t('User.Users'))
                }
                title={t('Common.Export.SaveAsCsv')}
                tooltipTitle={t('Common.Export.SaveAsCsv')}
                tooltip={t('Common.Export.SaveElementAsCsv', {
                  title: t('User.Users'),
                })}
                icon={<BsFileExcel />}
              />

              <LinkWithTooltip
                style={{ marginTop: 10, marginBottom: 10 }}
                handleClick={() => handleCopy()}
                title={t('User.Copy.Text')}
                tooltipTitle={t('User.Copy.TooltipTitle')}
                tooltip={t('User.Copy.Tooltip')}
                icon={<BsFiles />}
              />
            </Heading2>
          </ViewHeader>

          <Pagination
            currentPage={paginator.page}
            totalCount={data.totalRecords}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />

          <TableResponsiveContainer>
            <Table style={{ minWidth: 300 }} id="exp-users">
              <thead>
                <tr>
                  <Th style={{ width: 35 }} isExportIgnored={true} />
                  <Th sortKey="email" sorter={sorter}>
                    {t('User.Email')}
                  </Th>
                  <ThRight
                    sortKey="lastLoginDate"
                    sorter={sorter}
                    isExportIgnored={true}
                  >
                    {t('User.LastLogin')}
                  </ThRight>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => (
                  <Tr
                    key={index}
                    onSelect={(e) => {
                      handleSelectOne(item);
                    }}
                  >
                    <Td data-export-ignore={true}>
                      <BsPerson />
                    </Td>
                    <Td>
                      <Label
                        style={{ margin: 0 }}
                        htmlFor={index + `_${item.id}`}
                      >
                        {item.email}
                      </Label>
                    </Td>
                    <TdRight
                      data-export-ignore={true}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <Label
                        style={{ margin: 0, justifyContent: 'end' }}
                        htmlFor={index + `_${item.id}`}
                      >
                        {item.lastLoginDate
                          ? formatDate(parseISO(item.lastLoginDate))
                          : '-'}
                      </Label>
                    </TdRight>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableResponsiveContainer>

          <Pagination
            currentPage={paginator.page}
            totalCount={data.totalRecords}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />
        </>
      )}
    </>
  );
};

UsersList.displayName = 'UsersList';
