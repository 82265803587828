import { Input } from '@dimatech/shared/lib/components/form';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  Th,
  ThCentered,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatDate } from '@dimatech/shared/lib/utils';
import {
  featureActions,
  selectSelectedFeature,
} from 'api/feature/featureSlice';
import { SelectProduct } from 'components/SelectProduct';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FeatureList, SortDirection } from 'models';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircle, BsCircleFill, BsSearch } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { FeatureFlagsStatusTooltips } from './FeatureFlagsStatusTooltips';

export const FeaturesList = withTheme(
  ({
    theme,
    search,
    productId,
    setSearch,
    setProductId,
    features,
  }: {
    theme: Theme;
    search: string;
    productId: string;
    setSearch: Dispatch<SetStateAction<string>>;
    setProductId: Dispatch<SetStateAction<string>>;
    features?: FeatureList[];
  }): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedFeature = useAppSelector(selectSelectedFeature);

    const { items, sorter } = useSortableData(features ?? [], {
      key: 'name',
      direction: SortDirection.Asc,
    });

    const handleSelect = (feature: FeatureList) => {
      dispatch(featureActions.selectedFeature(feature));
    };

    return (
      <>
        <SearchContainer>
          <Input
            value={search}
            placeholder={t('Common.UI.Search')}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          <BsSearch />

          <SelectProduct
            name={'product'}
            productId={productId}
            setProductId={(productId) => setProductId(productId)}
          />
        </SearchContainer>

        <FeatureFlagsStatusTooltips />

        {features && features.length === 0 && (
          <div>{t('FeatureFlags.FeaturesNotFound')}</div>
        )}

        {!selectedFeature && features && features.length > 0 && (
          <TableResponsiveContainer>
            <Table>
              <thead>
                <tr>
                  <ThCentered sortKey="on" sorter={sorter}>
                    {t('FeatureFlags.OnOff.Title')}
                  </ThCentered>
                  <Th sortKey="name" sorter={sorter}>
                    {t('FeatureFlags.Name')}
                  </Th>
                  <Th sortKey="description" sorter={sorter}>
                    {t('FeatureFlags.Description')}
                  </Th>
                  <Th sortKey="creationDate" sorter={sorter}>
                    {t('FeatureFlags.CreationDate')}
                  </Th>
                </tr>
              </thead>
              <tbody>
                {items.map((feature, index) => (
                  <Tr
                    isHoverable={true}
                    key={index}
                    onSelect={() => handleSelect(feature)}
                  >
                    <TdCentered>
                      {feature.on === true ? (
                        <>
                          <BsCircleFill
                            data-tip
                            data-for="tt-status-on"
                            style={{
                              marginRight: 8,
                              color: theme.colors.success,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <BsCircle
                            data-tip
                            data-for="tt-status-off"
                            style={{ marginRight: 8 }}
                          />
                        </>
                      )}
                    </TdCentered>
                    <Td>{feature.name}</Td>
                    <Td>{feature.description}</Td>
                    <Td style={{ whiteSpace: 'nowrap' }}>
                      {feature.creationDate
                        ? formatDate(parseISO(feature.creationDate))
                        : '-'}
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </TableResponsiveContainer>
        )}
      </>
    );
  }
);

FeaturesList.displayName = 'FeaturesList';

const SearchContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 20px;

  > input {
    padding-right: 20px;
    max-width: 400px;
  }

  > svg {
    margin-left: -20px;
    cursor: pointer;
  }

  select {
    margin-left: 40px;
    max-width: 200px;
  }
`;
