import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Message, MessageFilter } from 'models';

import { RootState } from 'store';

type State = {
  filter: MessageFilter;
  selectedMessage?: Message;
};

export const initialState: State = {
  filter: {
    publishedOnly: false,
  },
};

export const { reducer: messageReducer, actions: messageActions } = createSlice(
  {
    name: 'message',
    initialState,
    reducers: {
      selectMessage: (state, action: PayloadAction<Message | undefined>) => {
        state.selectedMessage = action.payload;
      },
      setFilter: (state, action: PayloadAction<MessageFilter>) => {
        state.filter = action.payload;
      },
      resetFilter: (state) => {
        state.filter = initialState.filter;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedMessage = initialState.selectedMessage;
      });
    },
  }
);

export const selectSelectedMessage = (state: RootState): Message | undefined =>
  state.message.selectedMessage;

export const selectFilter = (state: RootState): MessageFilter =>
  state.message.filter;
