import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonLink,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Table, Td, TdRight, Tr } from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import produce from 'immer';
import { User, Variation } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPerson, BsPersonPlus, BsTrash } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { UserAdd } from './UserAdd';

export const TargetEdit = withTheme(
  ({
    selectedVariation,
    variations,
    setVariations,
    theme,
  }: {
    selectedVariation: Variation;
    variations: Variation[];
    setVariations: (variations: Variation[]) => void;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    const [isAdding, setIsAdding] = useState(false);
    const [variation, setVariation] = useState<Variation>(selectedVariation);

    const handleAddUser = (user: User) => {
      if (variation.users.some((us) => us.email === user.email)) {
        return;
      }

      const index = variations.findIndex((v) => v.id === variation.id);

      const newVariation = {
        ...variation,
        users: [...variation.users, user],
      };

      const newVariations = produce(variations, (draftState) => {
        draftState[index] = { ...newVariation };
      });

      setVariation(newVariation);
      setVariations(newVariations);
    };

    const handleDeleteUser = (user: User) => {
      const index = variations.findIndex((v) => v.id === variation.id);
      const newUsers = variation.users.filter((us) => us.id !== user.id);

      const newVariation = {
        ...variation,
        users: [...newUsers],
      };

      const newVariations = produce(variations, (draftState) => {
        draftState[index] = { ...newVariation };
      });

      setVariation(newVariation);
      setVariations(newVariations);
    };

    return (
      <>
        <div style={{ display: 'flex', marginTop: 15, marginBottom: 20 }}>
          <TooltipContent
            id="tt-user-add"
            title={t('FeatureFlags.Rule.User.Add.TooltipTitle')}
            text={t('FeatureFlags.Rule.User.Add.Tooltip')}
          />
          <BadgeMini
            data-tip
            data-for="tt-user-add"
            onClick={() => setIsAdding(true)}
            $inverted={true}
            style={{ backgroundColor: theme.colors.background }}
          >
            <BsPersonPlus />
          </BadgeMini>

          <ButtonLink
            data-tip
            data-for="tt-user-add"
            onClick={() => setIsAdding(true)}
            style={{ marginLeft: 10 }}
          >
            {t('FeatureFlags.Rule.User.Add.Title')}
          </ButtonLink>
        </div>

        {isAdding && (
          <UserAdd setIsAdding={setIsAdding} handleAdd={handleAddUser} />
        )}

        {variation.users && variation.users.length === 0 && (
          <div style={{ paddingBottom: 6 }}>
            {t('FeatureFlags.Users.NoUsers')}
          </div>
        )}

        <Table>
          <tbody>
            {variation.users.map((user, index) => (
              <Tr key={index}>
                <Td style={{ width: 30 }} className="narrow">
                  <BsPerson />
                </Td>
                <Td>{user.email}</Td>
                <TdRight style={{ display: 'flex', justifyContent: 'end' }}>
                  <EditButtons
                    handleDeleteUser={handleDeleteUser}
                    user={user}
                    theme={theme}
                  />
                </TdRight>
              </Tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
);

TargetEdit.displayName = 'TargetEdit';

const EditButtons = ({
  user,
  handleDeleteUser,
  theme,
}: {
  user?: User;
  handleDeleteUser: (user: User) => void;
  theme: Theme;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (user) {
      handleDeleteUser(user);
    }

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('FeatureFlags.Rule.User.Delete.ConfirmTitle')}>
          <div>{t('FeatureFlags.Rule.User.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button type="button" onClick={(e) => handleDelete(e)}>
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-user-delete"
        title={t('FeatureFlags.Rule.User.Delete.TooltipTitle')}
        text={t('FeatureFlags.Rule.User.Delete.Tooltip')}
      />

      <div
        style={{
          width: 30,
        }}
      >
        <BadgeMini
          data-tip
          data-for="tt-user-delete"
          onClick={(e) => handleConfirmDelete(e, true)}
          style={{ backgroundColor: theme.colors.surface }}
        >
          <BsTrash />
        </BadgeMini>
      </div>
    </>
  );
};
