import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InvoiceList } from './components/InvoiceList';

export const Billing = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Invoice.Title')}</Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody>
          <Style>
            <div>
              <InvoiceList />
            </div>
          </Style>
        </CardBody>
      </Card>
    </>
  );
};

Billing.displayName = 'Billing';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
