import {
  Button,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useDeactivateExpiredCustomerProductsMutation } from 'api/customer/customerProductApi';
import { SelectProduct } from 'components/SelectProduct';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DeactivateExpiredCustomers = ({
  setIsShowingDeactivateCustomers,
}: {
  setIsShowingDeactivateCustomers: (isShowing: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const [productId, setProductId] = useState<string | undefined>(undefined);
  const [deactivateCustomers, { isLoading: isDeactivatingCustomers }] =
    useDeactivateExpiredCustomerProductsMutation();

  const handleDeactivateCustomers = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    deactivateCustomers({ productId }).then(() => {
      setIsShowingDeactivateCustomers(false);
    });
  };

  return (
    <Modal
      handleKeyEsc={() => setIsShowingDeactivateCustomers(false)}
      title={t('Customer.Customers.DeActivateExpired.Title')}
    >
      <div>{t('Customer.Customers.DeActivateExpired.Description')}</div>
      <div>
        <SelectProduct
          name="product"
          setProductId={setProductId}
          productId={productId}
        />
      </div>
      <Buttons style={{ margin: '10px' }}>
        <ButtonSecondary
          type="button"
          onClick={() => setIsShowingDeactivateCustomers(false)}
        >
          {t('Common.Form.Cancel')}
        </ButtonSecondary>

        <Button
          type="button"
          onClick={handleDeactivateCustomers}
          disabled={isDeactivatingCustomers}
        >
          {t('Customer.Customers.DeActivateExpired.DeActivate')}
        </Button>
      </Buttons>
    </Modal>
  );
};

DeactivateExpiredCustomers.displayName = 'DeactivateExpiredCustomers';
