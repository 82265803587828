import { Select } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { useGetLicensesTypesQuery } from 'api/license/licenseApi';
import { useGetProductsQuery } from 'api/product/productApi';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectLicenseType = ({
  licenseTypeId,
  setLicenseTypeId,
  placeholder,
  style,
  autoFocus,
  name,
}: {
  licenseTypeId?: string;
  setLicenseTypeId: (licenseTypeId: string) => void;
  placeholder?: string;
  style?: CSSProperties;
  autoFocus?: boolean;
  name?: string;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const { data: licensesTypesList, isLoading } = useGetLicensesTypesQuery();

  const { data: products, isFetching: isFetchingProducts } =
    useGetProductsQuery();

  const options = licensesTypesList
    ?.filter((item) => !item.isDiscontinued)
    ?.map((licenseType) => {
      return (
        <option value={licenseType.id} key={licenseType.id}>
          {
            products?.find((product) => product.id === licenseType.productId)
              ?.name
          }{' '}
          {t(`LicenseType.${licenseType.name.replace(/\s/g, '_')}`)}
        </option>
      );
    });

  if (isLoading || isFetchingProducts) {
    return (
      <div style={{ margin: 10 }}>
        <LoaderSmall />
      </div>
    );
  }

  return (
    <Select
      name={name}
      autoFocus={!!autoFocus}
      value={licenseTypeId}
      onChange={(e) => setLicenseTypeId(e.currentTarget.value)}
      style={style}
    >
      {(placeholder || options?.length !== 1) && (
        <option value="">
          {placeholder ? placeholder : t('Filter.AllLicenceTypes')}
        </option>
      )}
      {options}
    </Select>
  );
};

SelectLicenseType.displayName = 'SelectLicenseType';
