import { Loader } from '@dimatech/shared/lib/components/loader';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsPercent } from '@dimatech/shared/lib/utils';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetRecipientsQuery } from 'api/message/messageApi';
import { userActions } from 'api/user/userSlice';
import { useAppDispatch } from 'hooks';
import { Message, Recipient } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPersonPlus } from 'react-icons/bs';
import { withTheme } from 'styled-components';
import { RecipientsAdd } from './RecipientsAdd';
import { RecipientsList } from './RecipientsList';

export const Recipients = withTheme(
  ({
    userMessage,
    handleAddRecipients,
    handleDeleteRecipients,
    theme,
  }: {
    userMessage: Message;
    handleAddRecipients: (recipients: Recipient[]) => void;
    handleDeleteRecipients: (recipients: Recipient[]) => void;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [isAddingRecipients, setIsAddingRecipients] = useState(false);
    const [recipients, setRecipients] = useState<Recipient[]>([]);

    const { data, isLoading, isFetching } = useGetRecipientsQuery(
      userMessage?.id ? { userMessageId: userMessage.id } : skipToken
    );

    const addRecipients = (users: Recipient[]) => {
      const allRecipients = recipients.concat(
        users.filter(
          (user) => !recipients.some((r) => r.userId === user.userId)
        )
      );

      setRecipients(allRecipients);
      handleAddRecipients(users);

      setIsAddingRecipients(false);

      dispatch(userActions.selectedUsers());
    };

    const deleteRecipients = (users: Recipient[]) => {
      const allRecipients = recipients.filter(
        (recipient) => !users.some((r) => r.userId === recipient.userId)
      );

      setRecipients(allRecipients);
      handleDeleteRecipients(users);

      dispatch(userActions.selectedUsers());
    };

    useEffect(() => {
      setRecipients(data ?? []);
    }, [data, setRecipients]);

    if (isLoading || isFetching) {
      return <Loader>{t('Common.UI.Loading')}</Loader>;
    }

    return (
      <>
        <Heading2>
          {t('Message.Recipient.NoOfRecipients', {
            count: recipients.length,
            read: recipients.filter((r) => !!r.read).length,
            percentage: formatAsPercent(
              (recipients.filter((r) => !!r.read).length / recipients.length) *
                100,
              '0',
              0
            ),
          })}
        </Heading2>

        <div style={{ display: 'flex', marginTop: 15, marginBottom: 0 }}>
          <LinkWithTooltip
            handleClick={() => setIsAddingRecipients(true)}
            title={t('Message.Recipient.Add.Title')}
            tooltipTitle={t('Message.Recipient.Add.TooltipTitle')}
            tooltip={t('Message.Recipient.Add.Tooltip')}
            isPrimary={true}
            icon={<BsPersonPlus />}
          />
        </div>

        {isAddingRecipients && (
          <RecipientsAdd
            setIsAddingRecipients={setIsAddingRecipients}
            handleAddRecipients={addRecipients}
          />
        )}

        <RecipientsList
          recipients={recipients}
          setRecipients={setRecipients}
          handleDeleteRecipients={deleteRecipients}
        />
      </>
    );
  }
);

Recipients.displayName = 'Recipients';
