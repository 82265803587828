import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Checkbox,
  DatePicker,
  Input,
  Label,
  Select,
  TextArea,
  YearPicker,
} from '@dimatech/shared/lib/components/form';
import { Heading4 } from '@dimatech/shared/lib/components/typography';
import { formatAsCurrency } from '@dimatech/shared/lib/utils';
import {
  customerActions,
  selectSelectedCustomerLicense,
  selectSelectedCustomerProduct,
} from 'api/customer/customerSlice';
import { useGetLicensesTypesQuery } from 'api/license/licenseApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { License } from 'models';
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillDashCircleFill, BsPlus } from 'react-icons/bs';
import styled from 'styled-components';
import { CustomerProductLicensesList } from './CustomerProductLicensesList';

export const CustomerProductLicense = ({
  license,
  setLicense,
  isNewLicense,
  setIsNewLicense,
  isValid,
  setIsValid,
  isLicenseDiscontinued,
  setIsLicenseDiscontinued,
}: {
  license?: License;
  setLicense: Dispatch<SetStateAction<License | undefined>>;
  isNewLicense: boolean;
  setIsNewLicense: Dispatch<SetStateAction<boolean>>;
  isValid: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  isLicenseDiscontinued: boolean;
  setIsLicenseDiscontinued: Dispatch<SetStateAction<boolean>>;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedCustomerProduct = useAppSelector(selectSelectedCustomerProduct);
  const selectedCustomerLicense = useAppSelector(selectSelectedCustomerLicense);

  const { data: licensesTypesList } = useGetLicensesTypesQuery(
    selectedCustomerProduct?.productId
  );

  const options = licensesTypesList
    ?.filter((item) => !item.isDiscontinued)
    ?.map((type) => {
      return (
        <option value={type.id} key={type.id}>
          {`${
            type.isDiscontinued
              ? `${t('Customer.CustomerProduct.LicenceTypeDiscontinued')}: `
              : ''
          }${t(
            `LicenseType.${type.name.replace(/\s/g, '_')}`
          )} / ${formatAsCurrency(type.price)} ${t(
            'Customer.License.Currency'
          )}`}
        </option>
      );
    });

  const handleChangeLicense = (event: ChangeEvent<HTMLSelectElement>) => {
    const licenseType = licensesTypesList?.find(
      (license) => license.id === event.currentTarget.value
    );

    const isDiscontinued = !!licenseType?.isDiscontinued && isNewLicense;

    setIsLicenseDiscontinued(isDiscontinued);
    setIsValid(isDiscontinued);

    handleChange(event);
  };

  const handleChange = (
    event:
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
  ) => {
    const target = event.currentTarget;
    if (license) {
      setLicense({
        ...license,
        [target.name]: target.value,
      });
    }
  };

  const handleAdding = () => {
    setIsValid(true);
    setIsNewLicense(!isNewLicense);

    if (isNewLicense) {
      dispatch(customerActions.selectCustomerLicense(undefined));
    } else {
      dispatch(
        customerActions.selectCustomerLicense({
          id: '',
          created: new Date().toISOString(),
          startDate: new Date().getFullYear(),
          endDate: null,
          licenseTypeId: '',
          licenseType: undefined,
          doNotInvoice: false,
          hasOwnAgreement: false,
          ownAgreementPrice: 0,
          ownAgreementComment: '',
          comment: '',
          basePrice: 0,
          invoices: [],
        })
      );
    }
  };

  useEffect(() => {
    setLicense(selectedCustomerLicense);
  }, [selectedCustomerLicense, setLicense]);

  return (
    <Style>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginTop: 25,
          marginBottom: 10,
        }}
      >
        <Heading4>{t('Customer.License.Licenses')}</Heading4>

        <BadgeMini
          data-tip
          data-for="tt-add"
          onClick={handleAdding}
          style={{
            marginLeft: 10,
            marginBottom: 2,
          }}
        >
          {isNewLicense ? <BsFillDashCircleFill /> : <BsPlus />}
        </BadgeMini>
      </div>

      <CustomerProductLicensesList setIsNewLicense={setIsNewLicense} />

      {license && (
        <>
          <div>
            <Label htmlFor="licenseTypeId">
              {t('Customer.License.LicenceType')}
            </Label>
            <Select
              id="licenseTypeId"
              name="licenseTypeId"
              value={license?.licenseTypeId}
              onChange={(e) => handleChangeLicense(e)}
              invalid={!isValid && !license?.licenseTypeId}
            >
              <option value="">- {t('Common.UI.Select')}</option>
              {options}
            </Select>

            {isLicenseDiscontinued && (
              <AlertError style={{ marginTop: 10 }}>
                {t(
                  'Customer.CustomerProduct.LicenceTypeDiscontinuedSelectOther'
                )}
              </AlertError>
            )}
          </div>

          <div>
            <Label htmlFor="doNotInvoice">
              <Checkbox
                id="doNotInvoice"
                name="doNotInvoice"
                style={{ marginRight: 10 }}
                checked={license?.doNotInvoice ?? false}
                onChange={(e) =>
                  setLicense({
                    ...license,
                    doNotInvoice: e.currentTarget.checked,
                  })
                }
              />
              {t('Customer.License.DoNotInvoice')}
            </Label>
          </div>

          <div style={{ display: 'flex', width: 400 }}>
            <div className="license-created">
              <Label htmlFor="created">{t('Customer.License.Created')}</Label>
              <DatePicker
                readonly={true}
                name="created"
                date={license?.created ?? null}
                setDate={(date: Date | null) =>
                  setLicense({
                    ...license,
                    created: date ? date.toISOString().toString() : '',
                  })
                }
              />
            </div>

            <div style={{ width: 150 }}>
              <Label htmlFor="startYear">
                {t('Customer.License.StartYear')}
              </Label>
              <YearPicker
                name="startYear"
                date={
                  license?.startDate && license.startDate > 1990
                    ? license?.startDate.toString()
                    : undefined
                }
                setDate={(date: Date | null) =>
                  setLicense({
                    ...license,
                    startDate: Number(date?.getFullYear()),
                  })
                }
              />
            </div>
            <div style={{ width: 150 }}>
              <Label htmlFor="endYear">{t('Customer.License.EndYear')}</Label>
              <YearPicker
                name="endYear"
                date={license?.endDate?.toString()}
                setDate={(date: Date | null) =>
                  setLicense({
                    ...license,
                    endDate: date ? Number(date?.getFullYear()) : null,
                  })
                }
              />
            </div>
          </div>
          <div>
            <Label htmlFor="comment">{t('Customer.License.Comment')}</Label>
            <TextArea
              id="comment"
              name="comment"
              value={license?.comment ?? ''}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div>
            <Label htmlFor="hasOwnAgreement">
              <Checkbox
                id="hasOwnAgreement"
                name="hasOwnAgreement"
                style={{ marginRight: 10 }}
                checked={!!license?.hasOwnAgreement}
                onChange={(e) => {
                  setLicense({
                    ...license,
                    hasOwnAgreement: e.currentTarget.checked,
                  });
                }}
              />
              {t('Customer.License.HasOwnAgreement')}
            </Label>

            {!!license?.hasOwnAgreement && (
              <>
                <div>
                  <Label htmlFor="ownAgreementPrice">
                    {t('Customer.License.OwnAgreementPrice')}
                  </Label>
                  <Input
                    type="text"
                    id="ownAgreementPrice"
                    name="ownAgreementPrice"
                    value={
                      license?.ownAgreementPrice === 0
                        ? ''
                        : license?.ownAgreementPrice || ''
                    }
                    onChange={(e) => {
                      setLicense({
                        ...license,
                        ownAgreementPrice: Number(e.currentTarget.value),
                      });
                    }}
                    style={{ width: 200 }}
                  />
                </div>

                <div>
                  <Label htmlFor="ownAgreementComment">
                    {t('Customer.License.OwnAgreementComment')}
                  </Label>
                  <TextArea
                    id="ownAgreementComment"
                    name="ownAgreementComment"
                    value={license?.ownAgreementComment ?? ''}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </Style>
  );
};

CustomerProductLicense.displayName = 'CustomerProductLicense';

const Style = styled.div`
  padding: 3px;

  .react-datepicker-wrapper {
    input {
      max-width: 70px;
    }
  }

  > div {
    margin-bottom: 20px;

    .license-created {
      width: 180px;

      .react-datepicker-wrapper {
        input {
          max-width: 100px;
        }
      }
    }
  }
`;
