import {
  Button,
  ButtonFooterWithToast,
  Buttons,
  ButtonSecondary,
  DatePicker,
  Input,
  Label,
  Select,
  TextArea,
  YearPicker,
} from '@dimatech/shared/lib/components/form';
import { Heading2, Heading3 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsCurrency } from '@dimatech/shared/lib/utils';
import { useAddOrUpdateInvoicesMutation } from 'api/billing/billingApi';
import {
  billingActions,
  selectSelectedInvoice,
  selectSelectedInvoices,
} from 'api/billing/billingSlice';
import { useGetProductsQuery } from 'api/product/productApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import produce from 'immer';
import { Invoice as InvoiceModel, InvoiceStatus } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const Invoice = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedInvoice = useAppSelector(selectSelectedInvoice);
  const selectedInvoices = useAppSelector(selectSelectedInvoices);
  const { data: products } = useGetProductsQuery();

  const [invoice, setInvoice] = useState<InvoiceModel>();
  const [isChangesSaved, setIsChangesSaved] = useState(false);

  const [addOrUpdate] = useAddOrUpdateInvoicesMutation();

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!invoice) {
      return;
    }

    addOrUpdate([invoice])
      .unwrap()
      .then(() => {
        if (selectedInvoices) {
          const index = selectedInvoices.findIndex(
            (inv) => inv.id === invoice.id
          );
          const newInvoices = produce(selectedInvoices, (draftState) => {
            draftState[index] = { ...invoice };
          });
          dispatch(billingActions.selectInvoices(newInvoices));
        }
      });

    setIsChangesSaved(true);
  };

  const handleCancel = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(billingActions.selectInvoice());
  };

  useEffect(() => {
    setInvoice(selectedInvoice ? { ...selectedInvoice } : undefined);
  }, [selectedInvoice]);

  return invoice ? (
    <>
      <Style>
        <Heading2 style={{ marginTop: 5 }}>{`${
          products?.find((p) => p.id === invoice.productId)?.name
        } - ${invoice.customerName} - ${invoice.organizationNumber}`}</Heading2>

        <div>
          <div>
            <Label htmlFor="amount">{t('Invoice.Amount')}</Label>
            <Input
              type="number"
              id="amount"
              name="amount"
              value={invoice?.amount ? invoice.amount : ''}
              onChange={(e) =>
                setInvoice({
                  ...invoice,
                  amount: e.currentTarget.value
                    ? Number(e.currentTarget.value)
                    : undefined,
                })
              }
            />
          </div>

          <div>
            <Label htmlFor="startYear">{t('Invoice.StartYear')}</Label>
            <YearPicker
              name="startYear"
              date={invoice?.startYear?.toString() ?? ''}
              setDate={(date: Date | null) =>
                setInvoice({
                  ...invoice,
                  startYear: Number(date?.getFullYear()),
                })
              }
            />
          </div>

          <div>
            <Label htmlFor="endYear">{t('Invoice.EndYear')}</Label>
            <YearPicker
              name="endYear"
              date={invoice?.endYear?.toString() ?? ''}
              setDate={(date: Date | null) =>
                setInvoice({
                  ...invoice,
                  endYear: Number(date?.getFullYear()),
                })
              }
            />
          </div>
        </div>

        <div>
          <div>
            <Label htmlFor="status">{t('Invoice.Status')}</Label>
            <Select
              id="status"
              name="status"
              value={invoice.status ?? ''}
              onChange={(e) =>
                setInvoice({
                  ...invoice,
                  status: e.currentTarget.value as InvoiceStatus,
                })
              }
            >
              <option value="">- {t('Common.UI.Select')}</option>
              {Object.keys(InvoiceStatus).map((value) => (
                <option key={value} value={value}>
                  {t(`Billing.InvoiceStatus.${value}`)}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Label htmlFor="sentDate">{t('Invoice.SentDate')}</Label>
            <DatePicker
              name="sentDate"
              date={invoice?.sentDate ?? null}
              setDate={(date: Date | null) =>
                setInvoice({
                  ...invoice,
                  sentDate: date ? date.toISOString().toString() : '',
                })
              }
            />
          </div>
        </div>

        <div>
          <div>
            <Label htmlFor="comment">{t('Invoice.Comment')}</Label>
            <TextArea
              id="comment"
              name="comment"
              value={invoice?.comment ?? ''}
              onChange={(e) =>
                setInvoice({
                  ...invoice,
                  comment: e.currentTarget.value,
                })
              }
            />
          </div>
        </div>
      </Style>

      <LicenseInformation invoice={invoice} />

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>

          <Button type="button" onClick={handleSave}>
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </>
  ) : null;
};

Invoice.displayName = 'Invoice';

const Style = styled.form`
  margin-top: 30px;

  > div {
    margin-top: 20px;
    display: flex;
    align-items: flex-end;

    select {
      min-width: 150px;
    }

    > div {
      padding-right: 20px;
    }
  }

  > div:last-of-type {
    div {
      width: 100%;
    }
  }
`;

const LicenseInformation = ({ invoice }: { invoice: InvoiceModel }) => {
  const { t } = useTranslation();

  return (
    <LicenseInformationStyle>
      <Heading3>{t('Invoice.LicenseType')}</Heading3>
      <div>
        <div>
          <div>{`${invoice.licenseTypeName ?? '-'} / ${
            invoice.basePrice ? formatAsCurrency(invoice.basePrice) : '-'
          } ${t('Customer.License.Currency')}`}</div>
        </div>
      </div>

      <div>
        <div>
          <strong>{t('Customer.License.Comment')}</strong>
          <div>{invoice.licenseComment || '-'}</div>
        </div>
      </div>

      {invoice.hasOwnAgreement && (
        <>
          <strong style={{ marginTop: 20 }}>
            {t('Customer.License.HasOwnAgreement')}
          </strong>
          <div style={{ width: '100%' }}>
            <div style={{ flexBasis: 200 }}>
              <strong>{t('Customer.License.OwnAgreementPrice')}</strong>
              <div>
                {invoice.ownAgreementPrice
                  ? formatAsCurrency(invoice.ownAgreementPrice)
                  : '-'}
              </div>
            </div>

            <div>
              <strong>{t('Customer.License.OwnAgreementComment')}</strong>
              <div>{invoice.ownAgreementComment ?? '-'}</div>
            </div>
          </div>
        </>
      )}
    </LicenseInformationStyle>
  );
};

const LicenseInformationStyle = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 10px 20px 10px;

  background-color: ${({ theme }: { theme: Theme }) => theme.colors.background};

  > div {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
`;
