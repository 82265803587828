import { CommonRoles } from '@dimatech/features-core/lib/models';
import {
  ButtonSecondary,
  DateTimePicker,
  Input,
  MultiSelect,
  Select,
} from '@dimatech/shared/lib/components/form';
import {
  filterUsersActions,
  selectFilterUsers,
} from 'api/user/filterUsersSlice';
import { SelectCustomer } from 'components/SelectCustomer';
import { SelectProduct } from 'components/SelectProduct';

import { useAppDispatch, useAppSelector } from 'hooks';
import { IsActive } from 'models';
import { useTranslation } from 'react-i18next';

export const UserFilters = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilterUsers);

  const handleChangeEmail = (email: string) => {
    dispatch(filterUsersActions.setFilter({ ...filter, email }));
  };

  const handleChangeUserId = (userId: string) => {
    dispatch(filterUsersActions.setFilter({ ...filter, userId }));
  };

  const handleChangeIsActive = (isActive: boolean | undefined) => {
    dispatch(filterUsersActions.setFilter({ ...filter, isActive }));
  };

  const optionsRoles = Object.keys(CommonRoles)
    .filter(
      (role) =>
        role !== CommonRoles.CommonUser &&
        role !== CommonRoles.DemoUser &&
        role !== CommonRoles.Reader
    )
    .map((value) => ({
      id: value as CommonRoles,
      name: t(`User.FilterRoles.${value}`),
      parentId: null,
      payload: value as CommonRoles,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const handleChangeRoles = (roles: CommonRoles[]) => {
    dispatch(filterUsersActions.setFilter({ ...filter, roles }));
  };

  const handleChangeLastLoginFrom = (lastLoginFrom: Date | null) => {
    if (lastLoginFrom !== null) {
      dispatch(
        filterUsersActions.setFilter({
          ...filter,
          lastLoginFrom: lastLoginFrom?.toISOString(),
        })
      );
    }
  };
  const handleChangeLastLoginTo = (lastLoginTo: Date | null) => {
    if (lastLoginTo !== null) {
      dispatch(
        filterUsersActions.setFilter({
          ...filter,
          lastLoginTo: lastLoginTo?.toISOString(),
        })
      );
    }
  };

  const handleChangeCustomer = (customerId: string) => {
    dispatch(filterUsersActions.setFilter({ ...filter, customerId }));
  };
  const handleChangeProduct = (productId: string) => {
    dispatch(filterUsersActions.setFilter({ ...filter, productId }));
  };

  const handleResetFilter = () => {
    dispatch(filterUsersActions.resetFilter());
  };

  const newDate = new Date();
  const date = newDate.getFullYear() - 2;
  const min = new Date(`January 1, ${date} 00:00:00`);

  return (
    <>
      <Input
        name="email"
        value={filter.email ?? ''}
        placeholder={t('User.SearchEmail')}
        style={{ marginRight: 20 }}
        onChange={(e) => handleChangeEmail(e.currentTarget.value)}
      />

      <Input
        name="userId"
        value={filter.userId ?? ''}
        placeholder={t('User.SearchId')}
        style={{ marginRight: 20 }}
        onChange={(e) => handleChangeUserId(e.currentTarget.value)}
      />

      <Select
        style={{ width: 250, marginRight: 20 }}
        name="isActive"
        id="isActive"
        value={
          filter.isActive === true
            ? IsActive.true
            : filter.isActive === false
            ? IsActive.false
            : ''
        }
        onChange={(e) =>
          handleChangeIsActive(
            e.currentTarget.value && e.currentTarget.value === IsActive.true
              ? true
              : e.currentTarget.value === IsActive.false
              ? false
              : undefined
          )
        }
      >
        <option value="">{t('User.IsActive.All')}</option>
        {Object.keys(IsActive).map((value) => (
          <option key={value} value={value}>
            {t(`User.IsActive.${value}`)}
          </option>
        ))}
      </Select>

      <MultiSelect
        style={{ width: 250, marginRight: 20 }}
        placeholder={`- ${t('Common.UI.Select')}`}
        placeholderAll={t('Common.UI.All')}
        options={optionsRoles ?? []}
        setSelected={(selectedNodes) => {
          const roles = selectedNodes.map((node) => node.payload);
          handleChangeRoles(roles);
        }}
        selected={optionsRoles.filter((node) =>
          filter.roles?.includes(node.id)
        )}
      />

      <div style={{ marginRight: 20 }}>
        <DateTimePicker
          name="lastLoginFrom"
          date={filter.lastLoginFrom ?? null}
          setDate={(date) => {
            handleChangeLastLoginFrom(date);
          }}
          isValid={true}
          placeholderText={t('User.LastLoginFrom')}
          min={min}
        />
      </div>

      <div style={{ marginRight: 20 }}>
        <DateTimePicker
          name="lastLoginTo"
          date={filter.lastLoginTo ?? null}
          setDate={(date) => {
            handleChangeLastLoginTo(date);
          }}
          isValid={true}
          placeholderText={t('User.LastLoginTo')}
          min={min}
        />
      </div>

      <div>
        <SelectCustomer
          name={'customer'}
          style={{ width: 200, marginRight: 20 }}
          customerId={filter.customerId}
          setCustomerId={handleChangeCustomer}
        />
      </div>

      <div>
        <SelectProduct
          name={'product'}
          style={{ width: 200, marginRight: 20 }}
          productId={filter.productId}
          setProductId={handleChangeProduct}
        />
      </div>

      <ButtonSecondary
        type="button"
        onClick={() => handleResetFilter()}
        style={{ marginRight: 20 }}
      >
        {t('User.ResetFilters')}
      </ButtonSecondary>
    </>
  );
};

UserFilters.displayName = 'UserFilters';
