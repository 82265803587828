import { Select } from '@dimatech/shared/lib/components/form';
import { InvoiceStatus } from 'models';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectStatus = ({
  status,
  setStatus,
  style,
  autoFocus,
  isAnyAllowed,
  name,
}: {
  status?: InvoiceStatus;
  setStatus: (status: InvoiceStatus) => void;
  style?: CSSProperties;
  autoFocus?: boolean;
  isAnyAllowed?: boolean;
  name?: string;
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <Select
        name={name}
        autoFocus={!!autoFocus}
        value={status}
        onChange={(e) => setStatus(e.currentTarget.value as InvoiceStatus)}
        style={style}
      >
        {isAnyAllowed && <option value="">{t('Filter.AllStatuses')}</option>}
        {Object.keys(InvoiceStatus).map((value) => (
          <option key={value} value={value}>
            {t(`Billing.InvoiceStatus.${value}`)}
          </option>
        ))}
      </Select>
    </>
  );
};

SelectStatus.displayName = 'SelectStatus';
