import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerMutation,
  CustomerProduct,
  CustomerProductUser,
  License,
} from 'models';
import { RootState } from 'store';

const initialState: {
  selectedCustomer?: CustomerMutation;
  selectedCustomerProduct?: CustomerProduct;
  selectedCustomerProductUser?: CustomerProductUser;
  selectedCustomerLicense?: License;
} = {};

export const { reducer: customerReducer, actions: customerActions } =
  createSlice({
    name: 'customer',
    initialState,
    reducers: {
      selectCustomer: (
        state,
        action: PayloadAction<CustomerMutation | undefined>
      ) => {
        state.selectedCustomer = action.payload;
      },
      selectCustomerProduct: (
        state,
        action: PayloadAction<CustomerProduct | undefined>
      ) => {
        state.selectedCustomerProduct = action.payload;
      },
      selectCustomerProductUser: (
        state,
        action: PayloadAction<CustomerProductUser | undefined>
      ) => {
        state.selectedCustomerProductUser = action.payload;
      },
      selectCustomerLicense: (
        state,
        action: PayloadAction<License | undefined>
      ) => {
        state.selectedCustomerLicense = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedCustomer = initialState.selectedCustomer;
        state.selectedCustomerProduct = initialState.selectedCustomerProduct;
        state.selectedCustomerProductUser =
          initialState.selectedCustomerProductUser;
        state.selectedCustomerLicense = initialState.selectedCustomerLicense;
      });
    },
  });

export const selectSelectedCustomer = (
  state: RootState
): CustomerMutation | undefined => state.customer.selectedCustomer;

export const selectSelectedCustomerProduct = (
  state: RootState
): CustomerProduct | undefined => state.customer.selectedCustomerProduct;

export const selectSelectedCustomerProductUser = (
  state: RootState
): CustomerProductUser | undefined =>
  state.customer.selectedCustomerProductUser;

export const selectSelectedCustomerLicense = (
  state: RootState
): License | undefined => state.customer.selectedCustomerLicense;
