import { MessageTypeIcon } from '@dimatech/features-core/lib/features/messenger/MessageTypeIcon';
import { UserMessageType } from '@dimatech/features-core/lib/models';
import { Button } from '@dimatech/shared/lib/components/form';
import {
  Pagination,
  useUiSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  extractTextFromHTML,
  formatDate,
  shorten,
} from '@dimatech/shared/lib/utils';
import { parseISO } from 'date-fns';
import { Message, SortDirection } from 'models';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckCircleFill, BsCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { MessageFilters } from './MessageFilters';
import { MessageListEditButtons } from './MessageListEditButtons';

export const MessageList = withTheme(
  ({
    messages,
    handleGetMessages,
    isError,
    theme,
  }: {
    messages?: Message[];
    handleGetMessages: () => void;
    isError: boolean;
    theme: Theme;
  }): JSX.Element | null => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
      items: sortedMessages,
      setPage,
      paginator,
      sorter,
    } = useUiSortablePaginator({
      orderBy: 'publishDate',
      orderDirection: SortDirection.Desc,
      pageSize: 25,
      data: {
        totalRecords: (messages ?? []).length,
        records: messages ?? [],
      },
    });

    const handleSelect = (userMessageId: string) => {
      navigate(`/message/${userMessageId}`);
    };

    return (
      <>
        <FiltersStyle>
          <MessageFilters />

          <Button type="button" onClick={() => handleGetMessages()}>
            {t('Message.Search')}
          </Button>
        </FiltersStyle>

        {((messages && messages?.length === 0) || isError) && (
          <div>{t('Message.NoMatches')}</div>
        )}

        {messages && messages?.length > 0 && !isError && (
          <>
            <Pagination
              style={{ marginBottom: 20 }}
              currentPage={paginator.page}
              totalCount={messages.length}
              pageSize={paginator.pageSize}
              handlePageChange={(page) => setPage(page)}
            />

            <TableResponsiveContainer>
              <Table>
                <thead>
                  <tr>
                    <Th style={{ width: 35 }} />
                    <Th sortKey="type" sorter={sorter} style={{ width: '1%' }}>
                      {t('Message.Type.Title')}
                    </Th>
                    <ThRight
                      sortKey="publishDate"
                      sorter={sorter}
                      style={{ width: '1%' }}
                    >
                      {t('Message.PublishDate.Title')}
                    </ThRight>
                    <Th sortKey="title" sorter={sorter}>
                      {t('Message.Title')}
                    </Th>
                    <Th sortKey="text" sorter={sorter}>
                      {t('Message.Text')}
                    </Th>
                    <ThRight style={{ width: 35 }} />
                  </tr>
                </thead>

                <tbody>
                  {sortedMessages.map((message, index) => (
                    <Fragment key={message.id}>
                      <Tr
                        isHoverable={true}
                        key={index}
                        onSelect={() => handleSelect(message.id as string)}
                      >
                        <Td>
                          <MessageTypeIcon
                            messageType={message.type as UserMessageType}
                          />
                        </Td>
                        <Td style={{ whiteSpace: 'nowrap' }}>
                          {t(`Message.Type.${message.type}`)}
                        </Td>
                        <TdRight style={{ whiteSpace: 'nowrap' }}>
                          {message.publishDate &&
                            parseISO(message.publishDate) < new Date() && (
                              <BsCheckCircleFill
                                style={{
                                  marginRight: 8,
                                  color: theme.colors.success,
                                }}
                              />
                            )}

                          {message.publishDate &&
                            parseISO(message.publishDate) > new Date() && (
                              <BsCircleFill
                                style={{
                                  marginRight: 8,
                                  color: theme.colors.lowPriority,
                                }}
                              />
                            )}

                          {message.publishDate
                            ? formatDate(parseISO(message.publishDate))
                            : '-'}
                        </TdRight>
                        <Td>{message.title}</Td>
                        <Td>
                          {shorten(
                            extractTextFromHTML(message?.text ?? ''),
                            300
                          )}
                        </Td>
                        <TdRight>
                          <MessageListEditButtons message={message} />
                        </TdRight>
                      </Tr>
                    </Fragment>
                  ))}
                </tbody>
              </Table>
            </TableResponsiveContainer>

            <Pagination
              style={{ marginBottom: 20 }}
              currentPage={paginator.page}
              totalCount={messages.length}
              pageSize={paginator.pageSize}
              handlePageChange={(page) => setPage(page)}
            />
          </>
        )}
      </>
    );
  }
);

MessageList.displayName = 'MessageList';

const FiltersStyle = styled.div`
  padding: 3px;
  width: 100%;
  max-width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;

  > input,
  select {
    margin-right: 20px;
    width: 200px;
    min-width: 100px;
  }

  > div {
    margin-right: 20px;
  }
`;
