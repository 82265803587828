import { Table, Td, Tr } from '@dimatech/shared/lib/components/table';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import {
  customerActions,
  selectSelectedCustomer,
  selectSelectedCustomerProduct,
} from 'api/customer/customerSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CustomerProduct } from 'models';
import { useTranslation } from 'react-i18next';
import { BsBox } from 'react-icons/bs';
import { CustomerProductStatus } from './CustomerProductStatus';
import { CustomerProductStatusTooltips } from './CustomerProductStatusTooltips';

export const CustomerProductsList = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedCustomer = useAppSelector(selectSelectedCustomer);
  const selectedCustomerProduct = useAppSelector(selectSelectedCustomerProduct);

  const handleSelect = (product: CustomerProduct) => {
    dispatch(customerActions.selectCustomerProduct(product));
    dispatch(customerActions.selectCustomerProductUser());
    dispatch(customerActions.selectCustomerLicense());
  };

  return selectedCustomer ? (
    <div style={{ marginTop: 30, width: '100%' }}>
      <Heading3>{t('Customer.Products')}</Heading3>

      <CustomerProductStatusTooltips />

      <Table style={{ width: '100%', marginTop: 10 }}>
        <tbody>
          {selectedCustomer.products?.map((product, index) => (
            <Tr
              key={index}
              isSelected={
                selectedCustomerProduct?.productId === product.productId
              }
              onSelect={() => handleSelect(product)}
            >
              <Td style={{ width: 25 }}>
                <BsBox />
              </Td>
              <Td style={{ width: 100 }}>{product.name}</Td>
              <Td style={{ maxWidth: 140, whiteSpace: 'nowrap' }}>
                <CustomerProductStatus product={product} isLabelShown={true} />
              </Td>
              <Td style={{ maxWidth: 140, whiteSpace: 'nowrap' }}>
                {product.haveActiveLicense &&
                  product.activeLicenseType &&
                  t(
                    `LicenseType.${product.activeLicenseType?.name.replace(
                      /\s/g,
                      '_'
                    )}`
                  )}
              </Td>

              <Td
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                {product.activeLicense && (
                  <>
                    {`${
                      product.activeLicense.startDate > 1900
                        ? product.activeLicense.startDate
                        : '????'
                    } - ${
                      product.activeLicense.endDate
                        ? product.activeLicense.endDate
                        : '->'
                    }`}
                  </>
                )}
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </div>
  ) : null;
};

CustomerProductsList.displayName = 'CustomerProductsList';
