import { useMemo, useSyncExternalStore } from 'react';
var subscribe = function (callback) {
    window.addEventListener('resize', callback);
    return function () {
        window.removeEventListener('resize', callback);
    };
};
/**
 * Hook that returns the dimensions of the element and the window and subscribes to window resize events
 * NOTE: Experimental, do not use this unless it is optimized!
 * @param ref
 * @returns
 */
export var useDimensions = function (ref) {
    var dimensions = useSyncExternalStore(subscribe, function () {
        var _a, _b, _c, _d, _e, _f, _g;
        var rect = (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        var win = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        var element = {
            width: (_b = rect === null || rect === void 0 ? void 0 : rect.width) !== null && _b !== void 0 ? _b : 0,
            height: (_c = rect === null || rect === void 0 ? void 0 : rect.height) !== null && _c !== void 0 ? _c : 0,
            top: (_d = rect === null || rect === void 0 ? void 0 : rect.top) !== null && _d !== void 0 ? _d : 0,
            left: (_e = rect === null || rect === void 0 ? void 0 : rect.left) !== null && _e !== void 0 ? _e : 0,
            availableWidth: win.width - ((_f = rect === null || rect === void 0 ? void 0 : rect.left) !== null && _f !== void 0 ? _f : 0),
            availableHeight: win.height - ((_g = rect === null || rect === void 0 ? void 0 : rect.top) !== null && _g !== void 0 ? _g : 0),
        };
        return JSON.stringify({
            element: element,
            window: win,
        });
    });
    return useMemo(function () { return JSON.parse(dimensions); }, [dimensions]);
};
