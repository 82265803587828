import { YearPicker } from '@dimatech/shared/lib/components/form';
import { formatDate } from '@dimatech/shared/lib/utils';
export const SelectPeriod = ({
  period,
  setPeriod,
  isAnyAllowed,
  inverted = false,
  name,
}: {
  period?: number;
  setPeriod: (period?: number) => void;
  isAnyAllowed?: boolean;
  inverted?: boolean;
  name?: string;
}): JSX.Element | null => {
  const date =
    !period || isNaN(period)
      ? isAnyAllowed
        ? undefined
        : formatDate(new Date(), 'date')
      : formatDate(new Date(period, 1, 1), 'date');

  return (
    <>
      <YearPicker
        name={name}
        inverted={inverted}
        date={date}
        setDate={(date: Date | null) => {
          setPeriod(date ? date.getFullYear() : undefined);
        }}
      />
    </>
  );
};

SelectPeriod.displayName = 'SelectPeriod';
