import {
  Button,
  Buttons,
  ButtonSecondary,
  Switch,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetFeatureFlagQuery,
  useUpdateFeatureFlagMutation,
} from 'api/feature/featureApi';
import {
  featureActions,
  selectSelectedFeature,
} from 'api/feature/featureSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Feature, FeatureAction, FeatureList, Rule, Variation } from 'models';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import { RuleAdd } from './RuleAdd';
import { RulesList } from './RulesList';
import { TargetsList } from './TargetsList';
import { VariationList } from './VariationList';

export const FeatureProperties = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element | null => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const selectedFeature = useAppSelector(selectSelectedFeature);

    const [isServing, setIsServing] = useState(false);
    const [rules, setRules] = useState<Rule[]>([]);
    const [variations, setVariations] = useState<Variation[]>([]);

    const [updateFeatureFlag] = useUpdateFeatureFlagMutation();

    const {
      data: feature,
      isLoading,
      isFetching,
    } = useGetFeatureFlagQuery(selectedFeature?.key ?? skipToken);

    const handleServing = (checked: boolean) => {
      setIsServing(checked);

      if (feature?.key) {
        updateFeatureFlag({
          actions: [
            {
              ...feature,
              kind: isServing === true ? 'TurnFlagOff' : 'TurnFlagOn',
            },
          ],
          key: feature.key,
        });
      }
    };

    const handleSave = () => {
      const actions: FeatureAction[] = [];

      if (!selectedFeature?.key || !feature) {
        return;
      }

      rules.forEach((rule) => {
        const add = getAddValuesToClauseAction(selectedFeature, feature, rule);
        const remove = getRemoveValuesFromClauseAction(
          selectedFeature,
          feature,
          rule
        );

        if (add?.values && add.values.length > 0) {
          actions.push(add);
        }

        if (remove?.values && remove.values.length > 0) {
          actions.push(remove);
        }
      });

      variations.forEach((variation) => {
        const add = getAddUserTargetsAction(
          selectedFeature,
          feature,
          variation
        );
        const remove = getRemoveUserTargetsAction(
          selectedFeature,
          feature,
          variation
        );

        if (add?.values && add.values.length > 0) {
          actions.push(add);
        }

        if (remove?.values && remove.values.length > 0) {
          actions.push(remove);
        }
      });

      if (actions.length > 0) {
        updateFeatureFlag({
          actions: [...actions],
          key: selectedFeature.key,
        });
      }
    };

    const handleCancel = () => {
      dispatch(featureActions.selectedFeature());
    };

    useEffect(() => {
      if (feature) {
        setIsServing(feature.on);
        setRules(feature.rules ?? []);
        setVariations(feature.variations ?? []);
      }
    }, [feature]);

    if (isLoading || isFetching) {
      return (
        <div style={{ margin: 10 }}>
          <LoaderSmall />
        </div>
      );
    }

    if (!feature) {
      return null;
    }

    return (
      <>
        <Heading2>{feature.name}</Heading2>
        <div style={{ marginBottom: 20 }}>{feature.description}</div>

        <div style={{ margin: '10px 0', display: 'flex' }}>
          <Switch
            isLabelRightAligned={true}
            label={
              isServing ? (
                <>
                  {t('FeatureFlags.OnOff.FeatureIsOn')}{' '}
                  {feature?.default.fallthrough ? (
                    <span
                      style={{
                        color: theme.colors.success,
                        borderBottom: `2px solid ${theme.colors.success}`,
                      }}
                    >
                      {t('FeatureFlags.OnOff.Available')}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: theme.colors.error,
                        borderBottom: `2px solid ${theme.colors.error}`,
                      }}
                    >
                      {t('FeatureFlags.OnOff.NotAvailable')}
                    </span>
                  )}
                  {t('FeatureFlags.OnOff.Exemptions')}
                </>
              ) : (
                <>
                  {t('FeatureFlags.OnOff.FeatureIsOff')}{' '}
                  {feature?.default.targetingOff ? (
                    <span
                      style={{
                        color: theme.colors.success,
                        borderBottom: `2px solid ${theme.colors.success}`,
                      }}
                    >
                      {t('FeatureFlags.OnOff.Available')}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: theme.colors.error,
                        borderBottom: `2px solid ${theme.colors.error}`,
                      }}
                    >
                      {t('FeatureFlags.OnOff.NotAvailable')}
                    </span>
                  )}
                  {t('FeatureFlags.OnOff.Exemptions')}
                </>
              )
            }
            tooltipTitle={t('FeatureFlags.OnOff.TooltipTitle')}
            tooltipText={<Trans i18nKey="FeatureFlags.OnOff.Tooltip" />}
            setIsChecked={handleServing}
            isChecked={isServing === true}
          />
        </div>

        <RuleAdd feature={feature} />

        <RulesList rules={rules} setRules={setRules} />

        <TargetsList variations={variations} setVariations={setVariations} />

        <VariationList feature={feature} />

        <Buttons style={{ marginTop: 20, padding: 5 }}>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>

          <Button type="button" onClick={handleSave}>
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </>
    );
  }
);

FeatureProperties.displayName = 'FeatureProperties';

const getAddValuesToClauseAction = (
  selectedFeature: FeatureList,
  feature: Feature,
  rule: Rule
): FeatureAction | null => {
  const originalRule = feature.rules?.find((r) => r.id === rule.id);

  if (!originalRule || !selectedFeature) {
    return null;
  }

  return {
    ...selectedFeature,
    kind: 'AddValuesToClause',
    ruleId: originalRule.id,
    clauseId: originalRule.clauseId,
    values:
      rule.values
        ?.filter(
          (customer) =>
            !originalRule.values?.find(
              (existing) => existing.id === customer.id
            )
        )
        .map((customer) => customer.id as string) ?? [],
  };
};

const getRemoveValuesFromClauseAction = (
  selectedFeature: FeatureList,
  feature: Feature,
  rule: Rule
): FeatureAction | null => {
  const originalRule = feature.rules?.find((r) => r.id === rule.id);

  if (!originalRule || !selectedFeature) {
    return null;
  }

  return {
    ...selectedFeature,
    kind: 'RemoveValuesFromClause',
    ruleId: originalRule.id,
    clauseId: originalRule.clauseId,
    values:
      originalRule.values
        ?.filter(
          (customer) =>
            !rule.values?.find((existing) => existing.id === customer.id)
        )
        .map((customer) => customer.id as string) ?? [],
  };
};

const getAddUserTargetsAction = (
  selectedFeature: FeatureList,
  feature: Feature,
  variation: Variation
) => {
  const originalVariation = feature.variations?.find(
    (v) => v.id === variation.id
  );

  if (!originalVariation || !selectedFeature) {
    return null;
  }

  return {
    ...feature,
    kind: 'AddUserTargets',
    variationId: originalVariation.id,
    values:
      variation.users
        .filter(
          (user) =>
            !originalVariation.users.find((existing) => existing.id === user.id)
        )
        .map((user) => user.id as string) ?? [],
  };
};

const getRemoveUserTargetsAction = (
  selectedFeature: FeatureList,
  feature: Feature,
  variation: Variation
) => {
  const originalVariation = feature.variations?.find(
    (v) => v.id === variation.id
  );

  if (!originalVariation || !selectedFeature) {
    return null;
  }

  return {
    ...feature,
    kind: 'RemoveUserTargets',
    variationId: originalVariation.id,
    values:
      originalVariation.users
        .filter(
          (user) => !variation.users.find((existing) => existing.id === user.id)
        )
        .map((user) => user.id as string) ?? [],
  };
};
