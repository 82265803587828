import { Loader } from '@dimatech/shared/lib/components/loader';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import {
  initialState,
  selectFilter,
  selectSelectedContext,
} from 'api/feature/contextSlice';
import { useLazyGetContextsQuery } from 'api/feature/featureApi';
import { useAppSelector } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContextEvaluateResult } from './components/ContextEvaluateResult';
import { ContextsList } from './components/ContextsList';

export const Context = (): JSX.Element | null => {
  const { t } = useTranslation();
  const filter = useAppSelector(selectFilter);
  const selectedContext = useAppSelector(selectSelectedContext);

  const [getContexts, { data, isLoading, isFetching, isError }] =
    useLazyGetContextsQuery();

  const handleGetContexts = () => {
    getContexts({ filter }, true);
  };

  useEffect(() => {
    if (filter === initialState.filter) {
      handleGetContexts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  if (isLoading || isFetching) {
    return (
      <div style={{ marginTop: 20 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Context.Title')}</Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody isLoading={isLoading || isFetching}>
          {selectedContext === undefined ? (
            <ContextsList
              data={data}
              handleGetContexts={handleGetContexts}
              isError={isError}
            />
          ) : (
            <ContextEvaluateResult />
          )}
        </CardBody>
      </Card>
    </>
  );
};

Context.displayName = 'Context';
