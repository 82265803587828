import { Logo } from '@dimatech/features-core/lib/features/administrate-account/components/Logo';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import {
  selectSelectedCustomer,
  selectSelectedCustomerProduct,
} from 'api/customer/customerSlice';
import { useAppSelector } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStopFill } from 'react-icons/bs';
import styled from 'styled-components';
import { Customer } from './components/Customer';
import { CustomerProductProperties } from './components/CustomerProductProperties';
import { CustomerProductsList } from './components/CustomerProductsList';
import { CustomersList } from './components/CustomersList';
import { DeactivateExpiredCustomers } from './components/DeactivateExpiredCustomers';

export const Customers = (): JSX.Element | null => {
  const { t } = useTranslation();

  const selectedCustomer = useAppSelector(selectSelectedCustomer);
  const selectedCustomerProduct = useAppSelector(selectSelectedCustomerProduct);

  const [isShowingDeactivateCustomers, setIsShowingDeactivateCustomers] =
    useState(false);

  return (
    <>
      <ViewHeader style={{ justifyContent: 'space-between', paddingRight: 35 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <Heading1>{t('Customer.Customers.Title')}</Heading1>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <LinkWithTooltip
            style={{ margin: '0 15px 0 0' }}
            handleClick={(e) => setIsShowingDeactivateCustomers(true)}
            title={t('Customer.Customers.DeActivate.LinkText')}
            tooltipTitle={t('Customer.Customers.DeActivate.TooltipTitle')}
            tooltip={t('Customer.Customers.DeActivate.Tooltip')}
            icon={<BsStopFill />}
            isInverted={true}
          />
        </div>
      </ViewHeader>

      <Card width="100%" innerStyle={{ display: 'table', width: '100%' }}>
        <CardBody>
          <Style>
            <div>
              <CustomersList />

              {selectedCustomer && <Customer />}

              {selectedCustomer?.id && <CustomerProductsList />}

              {selectedCustomer?.id && selectedCustomerProduct && (
                <CustomerProductProperties />
              )}
            </div>

            {isShowingDeactivateCustomers && (
              <DeactivateExpiredCustomers
                setIsShowingDeactivateCustomers={
                  setIsShowingDeactivateCustomers
                }
              />
            )}
          </Style>
        </CardBody>
      </Card>

      {selectedCustomer?.id && (
        <Card width="100%">
          <CardBody>
            <Logo
              isAdmin={true}
              customerId={selectedCustomer.id}
              customerName={selectedCustomer.name as string}
            />
          </CardBody>
        </Card>
      )}
    </>
  );
};

Customers.displayName = 'Customers';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
