import {
  Button,
  ButtonLink,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';

export const ClauseDelete = ({
  handleDeleteItem,
  index,
}: {
  handleDeleteItem: (index: number) => void;
  index: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    handleDeleteItem(index);

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('FeatureFlags.Rule.Clause.Delete.ConfirmTitle')}>
          <div>{t('FeatureFlags.Rule.Clause.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button type="button" onClick={(e) => handleDelete(e)}>
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-clause-delete"
        title={t('FeatureFlags.Rule.Clause.Delete.TooltipTitle')}
        text={t('FeatureFlags.Rule.Clause.Delete.Tooltip')}
      />

      <div style={{ marginLeft: 'auto' }}>
        <ButtonLink
          style={{
            margin: '10px 5px',
          }}
          data-tip
          data-for="tt-clause-delete"
          onClick={(e) => handleConfirmDelete(e, true)}
        >
          <BsTrash
            style={{
              marginRight: '2px',
            }}
          />
          {t('FeatureFlags.Rule.Clause.Delete.TooltipTitle')}
        </ButtonLink>
      </div>
    </>
  );
};

ClauseDelete.displayName = 'ClauseDelete';
