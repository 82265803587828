import {
  ButtonSecondary,
  Input,
  Select,
  Switch,
} from '@dimatech/shared/lib/components/form';
import { isValidEmail } from '@dimatech/shared/lib/utils';
import { messageActions, selectFilter } from 'api/message/messageSlice';
import { SelectProduct } from 'components/SelectProduct';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';

export const MessageFilters = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);

  const handleChangeEmail = (email: string) => {
    dispatch(messageActions.setFilter({ ...filter, email }));
  };

  const handleChangeUserId = (userId: string) => {
    dispatch(messageActions.setFilter({ ...filter, userId }));
  };

  const handleChangeProduct = (productId: string) => {
    dispatch(messageActions.setFilter({ ...filter, productId }));
  };

  const handleChangeIsRead = (isRead: boolean | undefined) => {
    dispatch(messageActions.setFilter({ ...filter, isRead }));
  };

  const handleChangePublishedOnly = (checked: boolean) => {
    dispatch(
      messageActions.setFilter({
        ...filter,
        publishedOnly: !!checked,
      })
    );
  };

  const handleResetFilter = () => {
    dispatch(messageActions.resetFilter());
  };

  return (
    <>
      <Input
        name="email"
        value={filter.email ?? ''}
        placeholder={t('User.SearchEmail')}
        onChange={(e) => handleChangeEmail(e.currentTarget.value)}
        invalid={!!(filter.email && isValidEmail(filter.email) === false)}
      />

      <Input
        name="userId"
        value={filter.userId ?? ''}
        placeholder={t('User.SearchId')}
        onChange={(e) => handleChangeUserId(e.currentTarget.value)}
      />

      <SelectProduct
        name={'product'}
        productId={filter.productId}
        setProductId={handleChangeProduct}
      />

      <Select
        name="isRead"
        id="isRead"
        value={
          filter.isRead === true ? '1' : filter.isRead === false ? '0' : ''
        }
        onChange={(e) =>
          handleChangeIsRead(
            e.target.value === '1'
              ? true
              : e.target.value === '0'
              ? false
              : undefined
          )
        }
      >
        <option value="">{t('Message.IsRead.All')}</option>
        <option value="1">{t('Message.IsRead.IsRead')}</option>
        <option value="0">{t('Message.IsRead.NotRead')}</option>
      </Select>

      <div>
        <Switch
          label={t('Message.PublishedOnly')}
          setIsChecked={handleChangePublishedOnly}
          isChecked={filter.publishedOnly === true}
        />
      </div>
      <ButtonSecondary
        type="button"
        onClick={() => handleResetFilter()}
        style={{ marginRight: 20 }}
      >
        {t('Message.ResetFilters')}
      </ButtonSecondary>
    </>
  );
};

MessageFilters.displayName = 'MessageFilters';
