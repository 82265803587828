import { adminApi, CacheTags } from 'api/adminApi';
import {
  Feature,
  FeatureAction,
  FeatureList,
  Kind,
  Rule,
  Target,
  Variation,
} from 'models';
import { Context, ContextFilter, ContextModel } from 'models/context';
import { nanoid } from 'nanoid';

const featureApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get feature flags
     */
    getFeatureFlags: build.query<FeatureList[], string | void>({
      query: (searchTerm) =>
        `feature${searchTerm ? `?searchTerm=${searchTerm}` : ''}`,
      providesTags: [CacheTags.FeatureFlags],
    }),

    /**
     * Get feature flag
     */
    getFeatureFlag: build.query<Feature, string | void>({
      query: (key) => `feature/${key}`,
      providesTags: [CacheTags.FeatureFlag],
      transformResponse: (
        feature: Feature & {
          variations?: Variation[];
          targets?: Target[];
          fallthrough: { variation: number };
          offVariation: number;
        }
      ) => {
        const rules: Rule[] =
          feature.rules?.map((rule) => ({
            ...rule,
            clauseId: rule.clauses ? rule.clauses[0].id : undefined,
            op: rule.clauses[0].op ?? null,
            values: rule.clauses ? rule.clauses[0].values : [],
            value:
              feature.variations && rule.variation
                ? feature.variations[rule.variation].value
                : null,
          })) ?? [];

        const targets: Target[] =
          feature.targets?.map((target) => ({
            ...target,
            value:
              feature.variations && target.variation
                ? feature.variations[target.variation].value
                : null,
          })) ?? [];

        const variations: Variation[] =
          feature.variations?.map((variation, index) => ({
            ...variation,
            users:
              feature.targets && feature.targets.length > index
                ? feature.targets[index].users
                : [],
          })) ?? [];

        return {
          ...feature,
          variations,
          rules,
          targets,
          default: {
            targetingOff: variations[feature.offVariation].value as boolean,
            fallthrough: variations[feature.fallthrough.variation]
              .value as boolean,
          },
        };
      },
    }),

    /**
     *  Update feature flag
     */
    updateFeatureFlag: build.mutation({
      query: ({ actions, key }: { actions: FeatureAction[]; key: string }) => ({
        url: `feature/${key}`,
        method: 'PATCH',
        body: actions,
      }),
      invalidatesTags: [CacheTags.FeatureFlag, CacheTags.FeatureFlags],
    }),

    /**
     * Get list of context kinds
     */
    getContextKinds: build.query<Kind[], void>({
      query: () => `feature/context/kinds`,
      providesTags: [CacheTags.FeatureFlagContextKind],
    }),

    /**
     * Get contexts
     */
    getContexts: build.query<
      ContextModel[],
      {
        filter: ContextFilter;
      }
    >({
      query: ({ filter }) => `feature/contexts${filterToQuery(filter)}`,
      providesTags: [CacheTags.Contexts],
      transformResponse: (result: ContextModel[]) => {
        return result.map((context) => ({
          ...context,
          uid: nanoid(6),
        }));
      },
    }),

    /**
     * Evaluate flags
     */
    evaluateFlags: build.mutation({
      query: (context: Context) => ({
        url: `feature/evaluate`,
        method: 'POST',
        body: {
          context,
        },
      }),
      invalidatesTags: [CacheTags.EvaluateFlags],
    }),
  }),
});

export const {
  useGetFeatureFlagsQuery,
  useGetFeatureFlagQuery,
  useUpdateFeatureFlagMutation,
  useGetContextKindsQuery,
  useGetContextsQuery,
  useEvaluateFlagsMutation,
  useLazyGetContextsQuery,
} = featureApi;

const filterToQuery = (filter: ContextFilter): string =>
  `?${filter.customerId ? `&customerId=${filter.customerId}` : ''}${
    filter.userId ? `&userId=${filter.userId}` : ''
  }`;
