import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Button,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  Table,
  Td,
  TdRight,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useGetCustomersQuery } from 'api/customer/customerApi';
import {
  customerActions,
  selectSelectedCustomerLicense,
  selectSelectedCustomerProduct,
} from 'api/customer/customerSlice';
import {
  useDeleteCustomerLicenseMutation,
  useGetLicensesTypesQuery,
} from 'api/license/licenseApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CustomerProduct, License } from 'models';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPatchCheck, BsTrash } from 'react-icons/bs';

export const CustomerProductLicensesList = ({
  setIsNewLicense,
}: {
  setIsNewLicense: Dispatch<SetStateAction<boolean>>;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedCustomerProduct = useAppSelector(selectSelectedCustomerProduct);
  const selectedLicense = useAppSelector(selectSelectedCustomerLicense);
  const [customerProduct, setCustomerProduct] = useState<CustomerProduct>();

  const { data, isFetching } = useGetCustomersQuery();
  const { data: licensesTypes, isFetching: isFetchingLicensesTypes } =
    useGetLicensesTypesQuery(selectedCustomerProduct?.productId);

  const handleSelect = (license: License) => {
    dispatch(customerActions.selectCustomerLicense(license));
    setIsNewLicense(false);
  };

  useEffect(() => {
    const customerProduct = data
      ?.find((customer) => customer.id === selectedCustomerProduct?.customerId)
      ?.products?.find(
        (product) => product.productId === selectedCustomerProduct?.productId
      );

    setCustomerProduct(customerProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedCustomerProduct]);

  if (isFetching || isFetchingLicensesTypes) {
    return <LoaderSmall>{t('Common.UI.Loading')}</LoaderSmall>;
  }

  return customerProduct?.licenses && customerProduct.licenses.length > 0 ? (
    <Table style={{ width: '100%', marginTop: 10 }}>
      <thead>
        <Tr>
          <Th style={{ width: 25 }} />
          <Th>{t('Customer.License.Title')}</Th>
          <Th colSpan={2} style={{ whiteSpace: 'nowrap' }}>
            {t('Customer.License.Period')}
          </Th>
        </Tr>
      </thead>
      <tbody>
        {customerProduct.licenses.map((license, index) => (
          <Tr
            key={index}
            isSelected={
              selectedLicense?.licenseTypeId === license.licenseTypeId
            }
            onSelect={() => handleSelect(license)}
          >
            <Td>
              <BsPatchCheck />
            </Td>
            <Td>
              {license.licenseTypeId &&
                t(
                  `LicenseType.${licensesTypes
                    ?.find((el) => el.id === license.licenseTypeId)
                    ?.name.replace(/\s/g, '_')}`
                )}
            </Td>
            <Td>
              {`${license.startDate > 1900 ? license.startDate : '????'} - ${
                license.endDate ? license.endDate : '->'
              }`}
            </Td>
            {license.invoices.length === 0 && (
              <TdRight
                style={{
                  verticalAlign: 'middle',
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <EditButtons
                  customerId={selectedCustomerProduct?.customerId}
                  productId={customerProduct.productId}
                  licenseId={license.id}
                />
              </TdRight>
            )}
          </Tr>
        ))}
      </tbody>
    </Table>
  ) : null;
};

CustomerProductLicensesList.displayName = 'CustomerProductLicensesList';

const EditButtons = ({
  customerId,
  productId,
  licenseId,
}: {
  customerId?: string;
  productId?: string;
  licenseId: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const [deleteLicense] = useDeleteCustomerLicenseMutation();

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (customerId && productId) {
      deleteLicense({ customerId, productId, id: licenseId })
        .unwrap()
        .then(() => {
          dispatch(customerActions.selectCustomerLicense(undefined));
        });
    }

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Customer.License.Delete.ConfirmTitle')}>
          <div>{t('Customer.License.Delete.ConfirmText')}</div>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button type="button" onClick={(e) => handleDelete(e)}>
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <TooltipContent
        id="tt-license-delete"
        title={t('Customer.License.Delete.TooltipTitle')}
        text={t('Customer.License.Delete.Tooltip')}
      />

      <BadgeMini
        data-tip
        data-for="tt-license-delete"
        onClick={(e) => handleConfirmDelete(e, true)}
      >
        <BsTrash />
      </BadgeMini>
    </>
  );
};
