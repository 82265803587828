import {
  ButtonLink,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdCentered,
  Th,
  ThCentered,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  contextActions,
  selectSelectedContext,
} from 'api/feature/contextSlice';
import { useEvaluateFlagsMutation } from 'api/feature/featureApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { EvaluateResult } from 'models/context';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircle, BsCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { withTheme } from 'styled-components';

export const ContextEvaluateResult = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element | null => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const selectedContext = useAppSelector(selectSelectedContext);

    const [flagVariations, setFlagVariations] = useState<EvaluateResult[]>();

    const [evaluateFlags, { isLoading }] = useEvaluateFlagsMutation();

    const handleCancel = () => {
      dispatch(contextActions.selectedContext());
    };

    const handleSearch = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      name?: string
    ) => {
      event.stopPropagation();

      navigate('/features', {
        state: { name },
      });
    };

    useEffect(() => {
      if (selectedContext) {
        evaluateFlags(selectedContext.context)
          .unwrap()
          .then((result) => {
            setFlagVariations(result);
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContext]);

    if (isLoading) {
      return (
        <div style={{ marginTop: 20 }}>
          <Loader>{t('Common.UI.Loading')}</Loader>
        </div>
      );
    }

    return (
      <>
        <Heading2 style={{ margin: ' 10px 0 10px 0' }}>
          {selectedContext?.context.kind === 'customer'
            ? selectedContext.context.name
            : selectedContext?.context.key}
        </Heading2>

        <TableResponsiveContainer>
          <Table>
            <thead>
              <tr>
                <ThCentered>{t('Context.Flag.OnOff.Title')}</ThCentered>
                <Th>{t('Context.Flag.Name')}</Th>
                <Th>{t('Context.Flag.EvaluationReason.Title')}</Th>
              </tr>
            </thead>
            <tbody>
              {flagVariations?.map((item, index) => (
                <Fragment key={index}>
                  <Tr key={index}>
                    <TdCentered style={{ width: '100px' }}>
                      <StatusTooltips item={item.value} theme={theme} />
                    </TdCentered>
                    <Td>
                      <ButtonLink onClick={(e) => handleSearch(e, item.name)}>
                        {item.name}
                      </ButtonLink>
                    </Td>
                    <Td>
                      {t(`Context.Flag.EvaluationReason.${item.reason.kind}`)}
                    </Td>
                  </Tr>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableResponsiveContainer>

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
        </Buttons>
      </>
    );
  }
);

ContextEvaluateResult.displayName = 'ContextEvaluateResult';

const StatusTooltips = ({
  item,
  theme,
}: {
  item: boolean;
  theme: Theme;
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <TooltipContent id="tt-flag-on" text={t('Context.Flag.OnOff.On')} />
      <TooltipContent id="tt-flag-off" text={t('Context.Flag.OnOff.Off')} />

      {item === true ? (
        <>
          <BsCircleFill
            data-tip
            data-for="tt-flag-on"
            style={{
              marginRight: 8,
              color: theme.colors.success,
            }}
          />
          {t('Context.Flag.OnOff.On')}
        </>
      ) : (
        <>
          <BsCircle
            data-tip
            data-for="tt-flag-off"
            style={{ marginRight: 8 }}
          />
          {t('Context.Flag.OnOff.Off')}
        </>
      )}
    </>
  );
};

StatusTooltips.displayName = 'StatusTooltips';
