import { adminActions, selectFilter } from 'api/adminSlice';
import { SelectCustomer } from 'components/SelectCustomer';
import { SelectPeriod } from 'components/SelectPeriod';
import { SelectProduct } from 'components/SelectProduct';
import { SelectStatus } from 'components/SelectStatus';
import { useAppDispatch, useAppSelector } from 'hooks';
import { InvoiceStatus } from 'models';

export const InvoiceFilters = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);

  const handleChangeCustomer = (customerId: string) => {
    dispatch(adminActions.setFilterCustomerId(customerId));
  };

  const handleChangeProduct = (productId: string) => {
    dispatch(adminActions.setFilterProductId(productId));
  };

  const handleChangeStatus = (status: InvoiceStatus) => {
    dispatch(adminActions.setFilterStatus(status));
  };

  const handleChangePeriod = (period?: number) => {
    dispatch(adminActions.setFilterPeriod(period));
  };

  return (
    <>
      <div>
        <SelectCustomer
          name={'customer'}
          style={{ width: 200 }}
          customerId={filter.customerId}
          setCustomerId={handleChangeCustomer}
        />
      </div>

      <div>
        <SelectProduct
          name={'product'}
          productId={filter.productId}
          setProductId={handleChangeProduct}
        />
      </div>

      <div>
        <SelectStatus
          isAnyAllowed={false}
          name={'status'}
          status={filter.status ?? InvoiceStatus.Sent}
          setStatus={handleChangeStatus}
        />
      </div>

      <div>
        <SelectPeriod
          isAnyAllowed={false}
          name={'period'}
          inverted={true}
          period={filter.period ?? new Date().getFullYear()}
          setPeriod={handleChangePeriod}
        />
      </div>
    </>
  );
};

InvoiceFilters.displayName = 'InvoiceFilters';
