import { adminApi, CacheTags } from 'api/adminApi';
import { Message, MessageFilter, Recipient } from 'models';

const messageApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get user messages
     */
    getUserMessages: build.query<
      Message[],
      {
        filter: MessageFilter;
      }
    >({
      query: ({ filter }) => `userMessage${filterToQuery(filter)}`,
      providesTags: [CacheTags.Message],
      transformResponse: (result: Message[]) => {
        return result.sort((a, b) =>
          (a.publishDate ?? '') > (b.publishDate ?? '') ? -1 : 1
        );
      },
    }),

    /**
     * Get user message recipients
     */
    getRecipients: build.query<
      Recipient[],
      {
        userMessageId: string;
      }
    >({
      query: ({ userMessageId }) => `userMessage/${userMessageId}/recipients`,
      providesTags: [CacheTags.MessageRecipients],
      transformResponse: (result: Recipient[]) => {
        return result.sort((a, b) =>
          (a.email ?? '') > (b.email ?? '') ? 1 : -1
        );
      },
    }),

    /**
     * Create new message
     */
    addMessage: build.mutation({
      query: ({
        message,
        userIds,
      }: {
        message: Message;
        userIds: string[];
      }) => ({
        url: `userMessage`,
        method: 'POST',
        body: {
          title: message.title,
          text: message.text,
          publishDate: message.publishDate,
          type: message.type,
          productId: message.productId,
          userIds,
        },
      }),
      invalidatesTags: [CacheTags.Message],
      transformResponse: (result: Message, _meta, arg) => {
        return {
          ...arg.message,
          ...result,
        };
      },
    }),

    /**
     * Update message
     */
    updateMessage: build.mutation({
      query: ({ message }: { message: Message }) => ({
        url: `userMessage/${message.id}`,
        method: 'PUT',
        body: {
          ...message,
        },
      }),
      invalidatesTags: [CacheTags.Message],
      transformResponse: (_result, _meta, arg) => {
        return {
          ...arg.message,
        };
      },
    }),

    /**
     * Delete message
     */
    deleteMessage: build.mutation({
      query: ({ userMessageId }: { userMessageId: string }) => ({
        url: `userMessage/${userMessageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [CacheTags.Message],
    }),

    /**
     * Add recipients to message
     */
    addRecipient: build.mutation({
      query: ({
        userMessageId,
        userIds,
      }: {
        userMessageId: string;
        userIds: string[];
      }) => ({
        url: `userMessage/${userMessageId}/recipients`,
        method: 'PUT',
        body: {
          userIds,
        },
      }),
      invalidatesTags: [CacheTags.MessageRecipients],
    }),

    /**
     * Delete recipient
     */
    deleteRecipient: build.mutation({
      query: ({
        userMessageId,
        userIds,
      }: {
        userMessageId: string;
        userIds: string[];
      }) => ({
        url: `userMessage/${userMessageId}/recipients`,
        method: 'DELETE',
        body: {
          userIds,
        },
      }),
      invalidatesTags: [CacheTags.MessageRecipients],
    }),
  }),
});

export const {
  useGetUserMessagesQuery,
  useGetRecipientsQuery,
  useLazyGetUserMessagesQuery,
  useUpdateMessageMutation,
  useAddMessageMutation,
  useAddRecipientMutation,
  useDeleteRecipientMutation,
  useDeleteMessageMutation,
} = messageApi;

const filterToQuery = (filter: MessageFilter): string =>
  `?publishedOnly=${filter.publishedOnly}${
    filter.productId ? `&productId=${filter.productId}` : ''
  }${filter.email ? `&email=${filter.email}` : ''}${
    filter.userId ? `&userId=${filter.userId}` : ''
  }${
    filter.isRead === true
      ? `&isRead=true`
      : filter.isRead === false
      ? `&isRead=false`
      : ''
  }`;
