import {
  Table,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useSortableData } from '@dimatech/shared/lib/hooks';
import { formatDate } from '@dimatech/shared/lib/utils';
import { selectSelectedAdmin, userActions } from 'api/user/userSlice';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SortDirection, User } from 'models';
import { useTranslation } from 'react-i18next';
import { BsPersonBadge } from 'react-icons/bs';

export const GlobalAdminsList = ({
  users,
}: {
  users?: User[];
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedAdmin = useAppSelector(selectSelectedAdmin);

  const { items, sorter } = useSortableData(users ?? [], {
    key: 'email',
    direction: SortDirection.Asc,
  });

  const handleSelect = (admin: User) => {
    dispatch(userActions.selectAdmin(admin));
  };

  return (
    <>
      {!selectedAdmin && (
        <Table style={{ minWidth: 300 }}>
          <thead>
            <tr>
              <Th style={{ width: 35 }} />
              <Th sortKey="email" sorter={sorter}>
                {t('User.Email')}
              </Th>
              <ThRight sortKey="lastLoginDate" sorter={sorter}>
                {t('User.LastLogin')}
              </ThRight>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <Tr key={index} onSelect={() => handleSelect(item)}>
                <Td>
                  <BsPersonBadge />
                </Td>
                <Td>{item.email}</Td>
                <TdRight
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {item.lastLoginDate
                    ? formatDate(parseISO(item.lastLoginDate))
                    : '-'}
                </TdRight>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

GlobalAdminsList.displayName = 'GlobalAdminsList';
