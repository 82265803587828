import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { CopyToClipboard } from '@dimatech/shared/lib/components/CopyToClipboard';
import {
  Button,
  ButtonFooterWithToast,
  Buttons,
  ButtonSecondary,
  Checkbox,
  Input,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  useAddResearcherMutation,
  useAddUserMutation,
  useDeleteResearcherMutation,
  useUpdateUserMutation,
} from 'api/user/userApi';
import { selectSelectedResearcher, userActions } from 'api/user/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Product, ResearcherUser } from 'models';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';

export const Researcher = withTheme(
  ({
    products,
    theme,
  }: {
    products: Product[];
    theme: Theme;
  }): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const selectedResearcher = useAppSelector(selectSelectedResearcher);
    const [selectedProducts, setSelectedProducts] = useState<
      string[] | undefined
    >(selectedResearcher?.products ?? []);
    const [isValid, setIsValid] = useState(true);

    const [addUser, { isLoading: addPosting, error: addErrors }] =
      useAddUserMutation();
    const [
      addResearcher,
      { isLoading: addResearcherPosting, error: addResearcherErrors },
    ] = useAddResearcherMutation();
    const [updateUser, { isLoading: updatePosting, error: updateErrors }] =
      useUpdateUserMutation();
    const [
      deleteResearcher,
      { isLoading: deleteResearcherPosting, error: deleteResearcherErrors },
    ] = useDeleteResearcherMutation();

    const [researcher, setResearcher] = useState<ResearcherUser>({});
    const [isChangesSaved, setIsChangesSaved] = useState(false);
    const isPosting =
      updatePosting ||
      addPosting ||
      addResearcherPosting ||
      deleteResearcherPosting;

    useEffect(() => {
      if (selectedResearcher) {
        setResearcher(selectedResearcher);
      }
      setIsValid(true);
    }, [selectedResearcher]);

    const handleChange = (
      e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const target = e.currentTarget;

      setResearcher({
        ...researcher,
        [target.name]: target.value,
      });
    };

    const handleSave = (e: React.SyntheticEvent) => {
      e.preventDefault();

      setIsValid(true);

      if (!researcher.email || selectedProducts?.length === 0) {
        setIsValid(false);
        return false;
      }

      if (researcher.id) {
        updateUser({
          ...researcher,
        })
          .unwrap()
          .then(() => {
            const addedProducts = selectedProducts?.filter(
              (productId) =>
                selectedResearcher?.products &&
                !selectedResearcher?.products.includes(productId)
            );

            if (addedProducts && addedProducts?.length > 0) {
              addedProducts?.forEach((productId) =>
                addResearcher({
                  productId,
                  userId: researcher.id as string,
                })
              );
            }

            const deletedProducts =
              selectedResearcher?.products &&
              selectedResearcher?.products.filter(
                (productId) => !selectedProducts?.includes(productId)
              );

            if (deletedProducts && deletedProducts?.length > 0) {
              deletedProducts?.forEach((productId) =>
                deleteResearcher({
                  productId,
                  userId: researcher.id as string,
                })
              );
            }

            setIsChangesSaved(true);
          });
      } else {
        addUser({
          ...researcher,
        })
          .unwrap()
          .then((result) => {
            selectedProducts?.forEach((product) =>
              addResearcher({
                productId: product as string,
                userId: result.id as string,
              })
            );

            setIsChangesSaved(true);
            dispatch(userActions.selectResearcher());
          })
          .catch(() => {
            // Do nothing
          });
      }
    };

    const handleDelete = (e: React.SyntheticEvent) => {
      e.preventDefault();

      if (researcher.id) {
        products.forEach((product) =>
          deleteResearcher({
            productId: product.id as string,
            userId: researcher.id as string,
          })
        );

        dispatch(userActions.selectResearcher());
      }
    };

    const handleCancel = () => {
      dispatch(userActions.selectResearcher());
    };

    const handleSelect = (productId?: string) => {
      if (productId && selectedProducts?.includes(productId)) {
        setSelectedProducts(selectedProducts.filter((id) => id !== productId));
      } else {
        selectedProducts &&
          productId &&
          setSelectedProducts([...selectedProducts, productId]);
      }
    };

    return researcher ? (
      <div>
        {researcher?.id && (
          <Heading2 style={{ marginTop: 5 }}>{researcher.email}</Heading2>
        )}

        <AlertErrors
          error={
            addErrors ??
            addResearcherErrors ??
            updateErrors ??
            deleteResearcherErrors
          }
          translationPath="User.ValidationError"
        />

        <div>
          <Label htmlFor="name">{t('User.Email')}</Label>
          <Input
            type="text"
            id="email"
            name="email"
            value={researcher.email || ''}
            onChange={handleChange}
            invalid={!isValid && !researcher.email}
          />
        </div>

        {researcher.id && (
          <div>
            <Label htmlFor="name">{t('User.Id')}</Label>
            <div
              style={{
                marginTop: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Input
                style={{ maxWidth: 200 }}
                value={researcher.id}
                disabled={true}
              />
              <CopyToClipboard
                value={researcher.id}
                text={t('User.CopyId')}
                tooltipId="tt-link-copy"
              />
            </div>
          </div>
        )}

        <div
          style={{
            borderBottom: `1px solid ${
              !isValid && selectedProducts?.length === 0
                ? theme.colors.error
                : 'transparent'
            }`,
          }}
        >
          <Label>{t('Researcher.SelectProduct')}</Label>
          {products.map((product) => (
            <div
              key={product.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '5px',
              }}
            >
              <Checkbox
                onChange={() => handleSelect(product.id)}
                id={product.id}
                name={product.id}
                value={product.id}
                defaultChecked={selectedProducts?.includes(product.id || '')}
              />
              <Label
                htmlFor={product.id}
                style={{ marginLeft: '10px', marginTop: 0 }}
              >
                {product.name}
              </Label>
            </div>
          ))}
        </div>

        <ButtonFooterWithToast
          isSaved={isChangesSaved}
          setIsSaved={setIsChangesSaved}
        >
          <Buttons style={{ justifyContent: 'flex-start' }}>
            <ButtonSecondary type="button" onClick={handleDelete}>
              {t('Common.Form.Delete')}
            </ButtonSecondary>
          </Buttons>

          <Buttons>
            <ButtonSecondary type="button" onClick={handleCancel}>
              {t('Common.Form.Cancel')}
            </ButtonSecondary>
            <Button type="button" onClick={handleSave} disabled={isPosting}>
              {t('Common.Form.Save')}
            </Button>
          </Buttons>
        </ButtonFooterWithToast>
      </div>
    ) : null;
  }
);

Researcher.displayName = 'Researcher';
