import { Switch } from '@dimatech/shared/lib/components/form';
import { adminActions, selectFilter } from 'api/adminSlice';
import { SelectLicenseType } from 'components/SelectLicenseType';
import { SelectPeriod } from 'components/SelectPeriod';
import { SelectProduct } from 'components/SelectProduct';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';

export const CustomerFilters = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);

  const handleChangeProduct = (productId: string) => {
    dispatch(adminActions.setFilterProductId(productId));
  };

  const handleChangeLicenseType = (licenseTypeId: string) => {
    dispatch(adminActions.setFilterLicenseTypeId(licenseTypeId));
  };

  const handleChangePeriod = (period?: number) => {
    dispatch(adminActions.setFilterPeriod(period));
  };

  const handleChangeShowActiveLicensesOnly = (checked: boolean) => {
    dispatch(
      adminActions.setFilter({
        ...filter,
        hasActiveLicense: checked ? true : undefined,
      })
    );
  };

  const handleChangeShowInternationalOnly = (checked: boolean) => {
    dispatch(
      adminActions.setFilter({
        ...filter,
        isInternational: checked,
      })
    );
  };

  return (
    <>
      <div>
        <SelectProduct
          name="product"
          productId={filter.productId}
          setProductId={handleChangeProduct}
        />
      </div>

      <div>
        <SelectLicenseType
          name="licenseType"
          style={{ minWidth: 150, maxWidth: 'unset' }}
          licenseTypeId={filter.licenseTypeId}
          setLicenseTypeId={handleChangeLicenseType}
        />
      </div>

      <div>
        <SelectPeriod
          isAnyAllowed={true}
          name="period"
          inverted={true}
          period={filter.period}
          setPeriod={handleChangePeriod}
        />
      </div>

      <div>
        <Switch
          label={t('Filter.IsActive')}
          setIsChecked={handleChangeShowActiveLicensesOnly}
          isChecked={filter.hasActiveLicense === true}
        />
      </div>

      <div>
        <Switch
          label={t('Filter.IsInternational')}
          setIsChecked={handleChangeShowInternationalOnly}
          isChecked={filter.isInternational === true}
        />
      </div>
    </>
  );
};

CustomerFilters.displayName = 'CustomerFilters';
