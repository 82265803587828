import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  Button,
  ButtonFooterWithToast,
  ButtonSecondary,
  Buttons,
  YearPicker,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import {
  useActivateCustomerProductMutation,
  useApproveCustomerProductMutation,
  useDeactivateCustomerProductMutation,
  useDisapproveCustomerProductMutation,
  useUpdateCustomerProductBillingInfoMutation,
} from 'api/customer/customerProductApi';
import {
  customerActions,
  selectSelectedCustomer,
  selectSelectedCustomerProduct,
} from 'api/customer/customerSlice';
import {
  useAddCustomerLicenseMutation,
  useUpdateCustomerLicenseMutation,
} from 'api/license/licenseApi';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BillingInfo, License } from 'models';
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerProductBillingInfo } from './CustomerProductBillingInfo';
import { CustomerProductLicense } from './CustomerProductLicense';

export const CustomerProductProperties = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedCustomerProduct = useAppSelector(selectSelectedCustomerProduct);

  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const [billingInfo, setBillingInfo] = useState<BillingInfo | undefined>({});
  const [license, setLicense] = useState<License>();
  const [isShowingConfirmDeactivate, setIsShowingConfirmDeactivate] =
    useState(false);
  const [isNewLicense, setIsNewLicense] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isLicenseDiscontinued, setIsLicenseDiscontinued] = useState(false);

  const [approve] = useApproveCustomerProductMutation();
  const [disapprove] = useDisapproveCustomerProductMutation();
  const [activate] = useActivateCustomerProductMutation();
  const [updateBillingInfo, { isLoading }] =
    useUpdateCustomerProductBillingInfoMutation();
  const [updateCustomerLicense, { isLoading: isLoadingUpdateCustomerLicense }] =
    useUpdateCustomerLicenseMutation();
  const [addCustomerLicense, { isLoading: isLoadingAddCustomerLicense }] =
    useAddCustomerLicenseMutation();

  const handleActivate = () => {
    if (!selectedCustomerProduct) {
      return;
    }

    if (selectedCustomerProduct?.deactivated) {
      activate({
        customerId: selectedCustomerProduct.customerId as string,
        productId: selectedCustomerProduct.productId as string,
      });
    }

    dispatch(
      customerActions.selectCustomerProduct({
        ...selectedCustomerProduct,
        deactivated: false,
      })
    );
  };

  const handleApprove = () => {
    if (!selectedCustomerProduct) {
      return;
    }

    approve({
      customerId: selectedCustomerProduct.customerId as string,
      productId: selectedCustomerProduct.productId as string,
    });

    dispatch(
      customerActions.selectCustomerProduct({
        ...selectedCustomerProduct,
        isApproved: true,
      })
    );
  };

  const handleDisapprove = () => {
    if (!selectedCustomerProduct) {
      return;
    }

    disapprove({
      customerId: selectedCustomerProduct.customerId as string,
      productId: selectedCustomerProduct.productId as string,
    });

    dispatch(
      customerActions.selectCustomerProduct({
        ...selectedCustomerProduct,
        isApproved: false,
      })
    );
  };

  const handleCancel = () => {
    dispatch(customerActions.selectCustomerProduct());
    dispatch(customerActions.selectCustomerLicense());
  };

  const handleSave = () => {
    if (isLicenseDiscontinued) {
      return;
    }

    setIsValid(true);

    if (!selectedCustomerProduct) {
      return;
    }

    if (license && !license.licenseTypeId && isNewLicense === true) {
      setIsValid(false);
      return;
    }

    if (selectedCustomerProduct?.isRegistered) {
      if (license && !license.licenseTypeId) {
        setIsValid(false);
        return;
      }
      updateBillingInfo({
        customerId: selectedCustomerProduct.customerId as string,
        productId: selectedCustomerProduct.productId as string,
        billingInfo: { ...billingInfo },
      });

      setIsChangesSaved(true);
    }
    if (license) {
      if (license.id) {
        updateCustomerLicense({
          customerId: selectedCustomerProduct.customerId as string,
          productId: selectedCustomerProduct.productId as string,
          license,
        });
      }

      if (!license.id) {
        addCustomerLicense({
          customerId: selectedCustomerProduct.customerId as string,
          productId: selectedCustomerProduct.productId as string,
          license,
        });
        setIsNewLicense(false);
      }

      setIsChangesSaved(true);
      dispatch(customerActions.selectCustomerLicense());
    }
  };

  const handleBillingInfoChange = (
    event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = event.currentTarget;
    setBillingInfo({
      ...billingInfo,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    setBillingInfo(selectedCustomerProduct?.billingInfo ?? {});
  }, [selectedCustomerProduct]);

  if (!selectedCustomerProduct || !selectedCustomerProduct.isRegistered) {
    return (
      <div style={{ padding: '20px 10px 10px 10px', fontStyle: 'italic' }}>
        {t('Customer.CustomerProduct.NoRegistrationForProduct', {
          product: selectedCustomerProduct?.name,
        })}
      </div>
    );
  }

  return (
    <>
      <ConfirmDeactivate
        isShowingConfirmDeactivate={isShowingConfirmDeactivate}
        setIsShowingConfirmDeactivate={setIsShowingConfirmDeactivate}
      />

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ width: '50%', paddingRight: 30, minWidth: 400 }}>
          <CustomerProductLicense
            license={license}
            setLicense={setLicense}
            isNewLicense={isNewLicense}
            setIsNewLicense={setIsNewLicense}
            isValid={isValid}
            setIsValid={setIsValid}
            isLicenseDiscontinued={isLicenseDiscontinued}
            setIsLicenseDiscontinued={setIsLicenseDiscontinued}
          />
        </div>

        <div style={{ width: '50%', minWidth: 400 }}>
          {billingInfo && (
            <CustomerProductBillingInfo
              billingInfo={billingInfo}
              handleChange={handleBillingInfoChange}
            />
          )}
        </div>
      </div>

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        <Buttons style={{ justifyContent: 'flex-start' }}>
          {!selectedCustomerProduct.isApproved && (
            <Button
              type="button"
              onClick={handleApprove}
              style={{ marginLeft: 0 }}
            >
              {t('Customer.CustomerProduct.Approve', {
                product: selectedCustomerProduct.name,
              })}
            </Button>
          )}

          {selectedCustomerProduct.isApproved && (
            <ButtonSecondary
              type="button"
              onClick={handleDisapprove}
              style={{ marginLeft: 0 }}
            >
              {t('Customer.CustomerProduct.Disapprove', {
                product: selectedCustomerProduct.name,
              })}
            </ButtonSecondary>
          )}

          {!selectedCustomerProduct.deactivated && (
            <ButtonSecondary
              type="button"
              onClick={() => setIsShowingConfirmDeactivate(true)}
              style={{ marginLeft: 0 }}
            >
              {t('Customer.CustomerProduct.Deactivate', {
                product: selectedCustomerProduct.name,
              })}
            </ButtonSecondary>
          )}

          {!!selectedCustomerProduct.deactivated && (
            <Button
              type="button"
              onClick={handleActivate}
              style={{ marginLeft: 0 }}
            >
              {t('Customer.CustomerProduct.Activate', {
                product: selectedCustomerProduct.name,
              })}
            </Button>
          )}
        </Buttons>

        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button
            type="button"
            disabled={
              isLoading ||
              isLoadingUpdateCustomerLicense ||
              isLoadingAddCustomerLicense ||
              isLicenseDiscontinued
            }
            onClick={handleSave}
          >
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </>
  );
};

CustomerProductProperties.displayName = 'CustomerProductProperties';

const ConfirmDeactivate = ({
  isShowingConfirmDeactivate,
  setIsShowingConfirmDeactivate,
}: {
  isShowingConfirmDeactivate: boolean;
  setIsShowingConfirmDeactivate: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const selectedCustomer = useAppSelector(selectSelectedCustomer);
  const selectedCustomerProduct = useAppSelector(selectSelectedCustomerProduct);
  const dispatch = useAppDispatch();

  const [deactivate] = useDeactivateCustomerProductMutation();
  const [updateCustomerLicense] = useUpdateCustomerLicenseMutation();

  const [licenseEndYear, setLicenseEndYear] = useState(
    new Date().getFullYear()
  );

  const handleDeactivate = () => {
    if (!selectedCustomerProduct) {
      return;
    }

    deactivate({
      customerId: selectedCustomerProduct.customerId as string,
      productId: selectedCustomerProduct.productId as string,
    })
      .unwrap()
      .then(() => {
        if (!selectedCustomerProduct.activeLicense) {
          dispatch(customerActions.selectCustomerProduct());

          setIsShowingConfirmDeactivate(false);
          return;
        }
        updateCustomerLicense({
          customerId: selectedCustomerProduct.customerId as string,
          productId: selectedCustomerProduct.productId as string,
          license: {
            ...(selectedCustomerProduct.activeLicense as License),
            endDate: licenseEndYear,
          },
        })
          .unwrap()
          .then(() => {
            dispatch(customerActions.selectCustomerProduct());

            setIsShowingConfirmDeactivate(false);
          });
      });
  };

  return (
    <>
      {isShowingConfirmDeactivate && (
        <Modal
          title={t('Customer.CustomerProduct.ConfirmDeactivate.Title', {
            product: selectedCustomerProduct?.name,
          })}
          handleKeyEnter={() => handleDeactivate()}
          handleKeyEsc={() => setIsShowingConfirmDeactivate(false)}
        >
          <div>
            {t('Customer.CustomerProduct.ConfirmDeactivate.Text', {
              product: selectedCustomerProduct?.name,
              customer: selectedCustomer?.name,
            })}
          </div>

          {selectedCustomerProduct?.haveActiveLicense && (
            <div style={{ marginBottom: 20, marginTop: 20 }}>
              <div>
                {t('Customer.CustomerProduct.ConfirmDeactivate.EndLicence')}
              </div>

              <YearPicker
                name="licenseEndYear"
                date={licenseEndYear.toString()}
                setDate={(date: Date | null) => {
                  setLicenseEndYear(date?.getFullYear() ?? licenseEndYear);
                }}
              />
            </div>
          )}

          <AlertWarning style={{ marginBottom: 10, marginTop: 10 }}>
            {t('Customer.CustomerProduct.ConfirmDeactivate.Warning', {
              product: selectedCustomerProduct?.name,
            })}
          </AlertWarning>

          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={() => setIsShowingConfirmDeactivate(false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button type="button" onClick={() => handleDeactivate()}>
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}
    </>
  );
};
