import { Select } from '@dimatech/shared/lib/components/form';
import { LoaderSmall } from '@dimatech/shared/lib/components/loader';
import { useGetCustomersQuery } from 'api/customer/customerApi';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectCustomer = ({
  customerId,
  setCustomerId,
  style,
  autoFocus,
  name,
}: {
  customerId?: string;
  setCustomerId: (customerId: string) => void;
  style?: CSSProperties;
  autoFocus?: boolean;
  name?: string;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const { data, isLoading } = useGetCustomersQuery();

  if (isLoading) {
    return (
      <div style={{ margin: 10 }}>
        <LoaderSmall />
      </div>
    );
  }

  return (
    <>
      <Select
        name={name}
        autoFocus={!!autoFocus}
        value={customerId ?? ''}
        onChange={(e) => setCustomerId(e.currentTarget.value)}
        style={style}
      >
        <option value="">{t('Filter.AllCustomers')}</option>
        {data?.map((customer) => (
          <option key={customer.id} value={customer.id}>
            {customer.name}
          </option>
        ))}
      </Select>
    </>
  );
};

SelectCustomer.displayName = 'SelectCustomer';
