import { User } from '@dimatech/shared/lib/models';
import { CustomerMutation } from './customer';

export type Feature = {
  name: string;
  description: string;
  key: string;
  creationDate: string;
  on: boolean;
  targets?: Target[];
  rules?: Rule[];
  variations?: Variation[];
  default: {
    targetingOff: boolean;
    fallthrough: boolean;
  };
  tags?: string[];
};

export type FeatureList = {
  name: string;
  description: string;
  key: string;
  creationDate: string;
  on: boolean;
  tags?: string[];
};

export type FeatureAction = FeatureList & {
  kind: string;
  variationId?: string;
  values?: string[];
  clauses?: ClauseEdit[];
  ruleId?: string;
  clauseId?: string;
  value?: string;
};

export type Variation = {
  id: string;
  index: number;
  value: boolean | string;
  users: User[];
};

// REMOVE
export type Target = {
  variation: number;
  users: User[];
  value: boolean | null | string;
};

export type Rule = {
  id: string;
  variation: number;
  description: string;
  clauses: Clause[];
  value: boolean | null | string;
  clauseId?: string;
  op: Op | null;
  values?: CustomerMutation[];
};

export type ClauseEdit = {
  id?: string;
  op?: Op | null;
  values: string[];
  negate?: boolean | null;
  contextKind?: string;
  attribute?: string;
};

export type Clause = {
  id?: string;
  op?: Op | null;
  values?: ClauseValue[];
  negate: boolean | null;
  contextKind?: string;
  attribute?: string;
};

export type Kind = {
  key: string;
  name: string;
};

export type ClauseValue = {
  id?: string;
  name?: string;
  email?: string;
};

export enum Op {
  In = 'in',
}
