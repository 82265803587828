import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import { CopyToClipboardInline } from '@dimatech/shared/lib/components/CopyToClipboard';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  Th,
  Tr,
} from '@dimatech/shared/lib/components/table';
import {
  LinkWithTooltip,
  TooltipContent,
} from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import {
  formatAsCurrency,
  formatDate,
  saveTableAsExcel,
} from '@dimatech/shared/lib/utils';
import { selectFilter } from 'api/adminSlice';
import { useLazyGetInvoicesQuery } from 'api/billing/billingApi';
import {
  billingActions,
  selectSelectedInvoice,
  selectSelectedInvoices,
} from 'api/billing/billingSlice';
import { useGetProductsQuery } from 'api/product/productApi';
import { isValid, parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  Invoice as InvoiceModel,
  InvoiceStatus,
  Paginator,
  SortDirection,
} from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsCheckAll,
  BsFileExcel,
  BsFillPencilFill,
  BsPencilFill,
  BsX,
} from 'react-icons/bs';
import styled from 'styled-components';
import { Invoice } from './Invoice';
import { InvoiceBulkEdit } from './InvoiceBulkEdit';
import { InvoiceFilters } from './InvoiceFilters';

// TODO: Split code
/* eslint-disable max-lines-per-function */

export const InvoiceList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const selectedInvoice = useAppSelector(selectSelectedInvoice);
  const selectedInvoices = useAppSelector(selectSelectedInvoices);
  const filter = useAppSelector(selectFilter);

  const [getInvoices, { data, isFetching, isLoading }] =
    useLazyGetInvoicesQuery();

  const { data: products } = useGetProductsQuery();

  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);
  const [isBulkEditing, setIsBulkEditing] = useState(false);

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 100,
    orderBy: 'customerName',
    orderDirection: SortDirection.Asc,
  };

  const noOfSelected = selectedInvoices ? selectedInvoices.length : 0;
  const noOfTotal = invoices.length;
  const isAllChecked = noOfSelected === noOfTotal;

  const handleEdit = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsBulkEditing(show);
  };

  const handleSearch = (paginator: Paginator = initialPaginator) => {
    getInvoices(
      {
        customerId: filter.customerId,
        productId: filter.productId,
        year: filter.period,
        status: filter.status,
        paginator,
      },
      true
    );
  };

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange: handleSearch,
  });

  const handleSelect = (isSelected: boolean, uid: string | null) => {
    const newInvoices = [...invoices];
    const index = newInvoices.findIndex((i) => i.uid === uid);

    newInvoices[index] = {
      ...newInvoices[index],
      isSelected,
    };

    setInvoices(newInvoices);

    dispatch(
      billingActions.selectInvoices(
        newInvoices.filter((invoice) => invoice.isSelected)
      )
    );
  };

  const handleSelectOne = (invoice: InvoiceModel) => {
    dispatch(billingActions.selectInvoice({ ...invoice }));
  };

  const handleSelectAll = () => {
    const checked = !isAllChecked;

    const newInvoices = invoices.map((invoice) => ({
      ...invoice,
      isSelected: checked,
    }));

    setInvoices(newInvoices);

    dispatch(
      billingActions.selectInvoices(
        newInvoices.filter((invoice) => invoice.isSelected)
      )
    );
  };

  useEffect(() => {
    setInvoices([]);
    handleSearch();
    dispatch(billingActions.selectInvoice());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const newInvoices = (data?.records ?? []).map((invoice) => ({
      ...invoice,
      isSelected: selectedInvoices?.some((i) => i.id === invoice.id),
    }));

    setInvoices(newInvoices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {!selectedInvoice && (
        <SearchContainer>
          <InvoiceFilters />

          <LinkWithTooltip
            style={{ marginTop: 10, marginBottom: 10, marginLeft: 'auto' }}
            handleClick={() =>
              saveTableAsExcel('exp-invoices', t('Invoice.Title'))
            }
            title={t('Common.Export.SaveAsCsv')}
            tooltipTitle={t('Common.Export.SaveAsCsv')}
            tooltip={t('Common.Export.SaveElementAsCsv', {
              title: t('Invoice.Title'),
            })}
            icon={<BsFileExcel />}
          />
        </SearchContainer>
      )}

      {(isFetching || isLoading) && <LoaderOverlay />}

      {data && data.totalRecords === 0 && (
        <div style={{ marginTop: 20 }}>
          {t('Invoice.NoInvoicesMatchingFilter')}
        </div>
      )}

      {!selectedInvoice && invoices && invoices.length > 0 && (
        <>
          <div
            style={{
              margin: '20px 0 10px',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {isBulkEditing && (
              <InvoiceBulkEdit setIsBulkEditing={setIsBulkEditing} />
            )}

            <LinkWithTooltip
              style={{ margin: '0 5px 0 0' }}
              handleClick={handleSelectAll}
              title={
                isAllChecked
                  ? t(`Common.UI.DeselectAll`)
                  : t(`Common.UI.SelectAll`)
              }
              icon={isAllChecked ? <BsX /> : <BsCheckAll />}
              disabledTimerInMs={1}
            />

            <LinkWithTooltip
              style={{ margin: '0 5px' }}
              handleClick={(e) => handleEdit(e, true)}
              title={t(`Invoice.Edit.Title`, {
                count: selectedInvoices?.length,
              })}
              icon={<BsPencilFill />}
              isDisabled={noOfSelected === 0}
              disabledTimerInMs={500}
            />
          </div>

          <Pagination
            currentPage={paginator.page}
            totalCount={data?.totalRecords ?? 0}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />

          <TooltipContent
            id="tt-invoice-edit"
            text={t('Invoice.Edit.Tooltip')}
          />

          <TableResponsiveContainer>
            <StyledTable id="exp-invoices">
              <thead>
                <tr>
                  <Th style={{ width: 35 }} />
                  <Th sortKey="customerName" sorter={sorter}>
                    {t('Invoice.Customer')}
                  </Th>
                  <Th sortKey="organizationNumber" sorter={sorter}>
                    {t('Invoice.OrganizationNumber')}
                  </Th>
                  <Th sortKey="product" sorter={sorter}>
                    {t('Invoice.Product')}
                  </Th>
                  <Th sortKey="status" sorter={sorter} style={{ width: 80 }}>
                    {t('Invoice.Status')}
                  </Th>
                  <Th sortKey="startYear" sorter={sorter} style={{ width: 80 }}>
                    {t('Invoice.StartYear')}
                  </Th>
                  <Th sortKey="endYear" sorter={sorter} style={{ width: 80 }}>
                    {t('Invoice.EndYear')}
                  </Th>
                  <Th sortKey="contactEmail" sorter={sorter}>
                    {t('Invoice.ContactReference')}
                  </Th>
                  <Th style={{ display: 'none' }} />
                  <Th sortKey="invoiceEmail" sorter={sorter}>
                    {t('Invoice.InvoiceReference')}
                  </Th>
                  <Th style={{ display: 'none' }} />
                  <Th sortKey="licenseTypeName" sorter={sorter}>
                    {t('Invoice.LicenseType')}
                  </Th>
                  <Th sortKey="amount" sorter={sorter} style={{ width: 80 }}>
                    {t('Invoice.Amount')}
                  </Th>
                </tr>
              </thead>

              <tbody>
                {invoices.map((invoice, index) => (
                  <Tr
                    key={invoice.uid}
                    isSelected={invoice.isSelected}
                    onSelect={() =>
                      handleSelect(!invoice.isSelected, invoice.uid)
                    }
                  >
                    <Td>
                      <BadgeMini
                        data-tip
                        data-for="tt-invoice-edit"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectOne(invoice);
                        }}
                      >
                        <BsFillPencilFill />
                      </BadgeMini>
                    </Td>
                    <Td>{invoice.customerName}</Td>
                    <Td>
                      {invoice.organizationNumber && (
                        <div>
                          {invoice.organizationNumber}
                          <CopyToClipboardInline
                            value={invoice.organizationNumber}
                          />
                        </div>
                      )}
                    </Td>
                    <Td>
                      {invoice.productId &&
                        products?.find((p) => p.id === invoice.productId)?.name}
                    </Td>
                    <Td>
                      {t(`Billing.InvoiceStatus.${invoice.status}`)}
                      {invoice.status === InvoiceStatus.Sent &&
                        isValid(parseISO(invoice.sentDate)) && (
                          <div style={{ whiteSpace: 'nowrap' }}>
                            {formatDate(parseISO(invoice.sentDate), 'date')}
                          </div>
                        )}
                    </Td>
                    <Td>
                      {invoice.startYear > 1990 ? invoice.startYear : '????'}
                    </Td>
                    <Td>{invoice.endYear ? invoice.endYear : '->'}</Td>

                    <Td data-value={invoice.contactName ?? ''}>
                      {invoice.contactName && (
                        <div style={{ whiteSpace: 'nowrap' }}>
                          {invoice.contactName}
                          <CopyToClipboardInline value={invoice.contactName} />
                        </div>
                      )}
                      {invoice.contactEmail && (
                        <div style={{ whiteSpace: 'nowrap' }}>
                          {invoice.contactEmail}
                          <CopyToClipboardInline value={invoice.contactEmail} />
                        </div>
                      )}
                    </Td>
                    <Td
                      style={{ display: 'none' }}
                      data-value={invoice.contactEmail ?? ''}
                    />

                    <Td data-value={invoice.invoiceEmail ?? ''}>
                      {invoice.invoiceEmail && (
                        <div>
                          {invoice.invoiceEmail}
                          <CopyToClipboardInline value={invoice.invoiceEmail} />
                        </div>
                      )}
                      {invoice.invoiceReference && (
                        <div>
                          <span style={{ whiteSpace: 'break-spaces' }}>
                            {invoice.invoiceReference}
                          </span>
                          <CopyToClipboardInline
                            value={invoice.invoiceReference}
                          />
                        </div>
                      )}
                    </Td>
                    <Td
                      style={{ display: 'none' }}
                      data-value={invoice.invoiceReference ?? ''}
                    />

                    <Td>{invoice.licenseTypeName}</Td>
                    <Td
                      data-value={invoice.amount}
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {invoice.amount && (
                        <>
                          {formatAsCurrency(invoice.amount)}
                          <CopyToClipboardInline
                            value={invoice.amount.toString()}
                          />
                        </>
                      )}
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </StyledTable>
          </TableResponsiveContainer>

          <Pagination
            currentPage={paginator.page}
            totalCount={data?.totalRecords ?? 0}
            pageSize={paginator.pageSize}
            handlePageChange={(page) => setPage(page)}
          />
        </>
      )}

      {selectedInvoice && <Invoice />}
    </>
  );
};

InvoiceList.displayName = 'InvoiceList';

const StyledTable = styled(Table)`
  margin-top: 20px;
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};

  td > div {
    white-space: nowrap;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;

  label {
    margin-top: 0;
    margin-right: 10px;
    white-space: nowrap;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 25px;

    .react-datepicker-wrapper {
      input {
        max-width: 50px;
      }
    }
  }

  > div:first-of-type {
    > input {
      width: 300px;
      padding-right: 20px;
    }

    > svg {
      margin-left: -20px;
      cursor: pointer;
    }
  }
`;
