import { UserMessageType } from '@dimatech/features-core/lib/models';
import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { TextEditor } from '@dimatech/shared/lib/components/TextEditor';
import {
  Button,
  ButtonFooter,
  ButtonSecondary,
  Buttons,
  DateTimePicker,
  Input,
  Label,
  Select,
} from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { config } from '@dimatech/shared/lib/config';
import {
  useAddMessageMutation,
  useAddRecipientMutation,
  useDeleteRecipientMutation,
  useUpdateMessageMutation,
} from 'api/message/messageApi';
import { selectSelectedMessage } from 'api/message/messageSlice';
import { SelectProduct } from 'components/SelectProduct';
import { useAppSelector } from 'hooks';
import { Message, Recipient } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Recipients } from './Recipients';

export const MessageProperties = (): JSX.Element | null => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const userMessage = useAppSelector(selectSelectedMessage);

  const [updateMessage, { isLoading: updatePosting }] =
    useUpdateMessageMutation();
  const [addMessage, { isLoading: addPosting }] = useAddMessageMutation();
  const [addRecipient, { isLoading: addRecipientPosting }] =
    useAddRecipientMutation();
  const [deleteRecipient, { isLoading: deleteRecipientPosting }] =
    useDeleteRecipientMutation();

  const isPosting =
    updatePosting ||
    addPosting ||
    addRecipientPosting ||
    deleteRecipientPosting;

  const [hasChanges, setHasChanges] = useState(false);
  const [message, setMessage] = useState<Message>();
  const [recipientsToDelete, setRecipientsToDelete] = useState<Recipient[]>([]);
  const [recipientsToAdd, setRecipientsToAdd] = useState<Recipient[]>([]);
  const [isValid, setIsValid] = useState(true);
  const [isTextValid, setIsTextValid] = useState(true);

  const handleCancel = () => {
    navigate(`/messages`);
  };

  const handleAddRecipients = (recipientsToAdd: Recipient[]) => {
    setHasChanges(true);

    setRecipientsToAdd(recipientsToAdd);
    setRecipientsToDelete(
      recipientsToDelete.filter(
        (r) => !recipientsToAdd.some((n) => n.userId === r.userId)
      )
    );
  };

  const handleDeleteRecipients = (newRecipientsToDelete: Recipient[]) => {
    setHasChanges(true);

    setRecipientsToDelete(recipientsToDelete.concat(newRecipientsToDelete));
    setRecipientsToAdd(
      recipientsToAdd.filter(
        (r) => !newRecipientsToDelete.some((n) => n.userId === r.userId)
      )
    );
  };

  const handleSave = () => {
    setIsValid(true);
    setIsTextValid(true);

    const limitInBytes = config.ui.textEditor.maxBytes;
    const textBytes = new TextEncoder().encode(message?.text);

    if (
      !message?.title ||
      !message?.text ||
      message.publishDate === undefined ||
      !message.type
    ) {
      setIsValid(false);
      return;
    }

    if (textBytes.length > limitInBytes) {
      setIsTextValid(false);
      return;
    }

    if (!message.id) {
      const userIds = recipientsToAdd.map((r) => r.userId) as string[];

      addMessage({ message, userIds })
        .unwrap()
        .then((result) => {
          setHasChanges(false);

          navigate(`/message/${result.id}`);
        });

      return;
    }

    updateMessage({
      message,
    })
      .unwrap()
      .then(() => {
        addRecipients(message.id as string);
        deleteRecipients(message.id as string);

        setHasChanges(false);
      });
  };

  const deleteRecipients = (userMessageId: string) => {
    const userIds = recipientsToDelete.map((r) => r.userId as string);

    if (userIds.length > 0) {
      deleteRecipient({
        userMessageId,
        userIds,
      });
    }
  };

  const addRecipients = (userMessageId: string) => {
    const userIds = recipientsToAdd.map((r) => r.userId as string);

    if (userIds.length > 0) {
      addRecipient({
        userMessageId,
        userIds,
      });
    }
  };

  useEffect(() => {
    if (location.pathname.endsWith('new')) {
      setMessage({} as Message);
      return;
    }

    setMessage(userMessage);
  }, [userMessage, location]);

  useEffect(() => {
    setIsValid(true);
    setIsTextValid(true);
  }, [userMessage]);

  if (isPosting) {
    return (
      <div style={{ margin: 10 }}>
        <Loader>{t('Common.UI.Loading')}</Loader>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '30px',
        }}
      >
        <div style={{ flexBasis: '40%', flexGrow: 1 }}>
          <Select
            name="type"
            id="type"
            value={message?.type ?? undefined}
            onChange={(e) => {
              setHasChanges(true);
              setMessage({
                ...message,
                type: e.currentTarget.value as UserMessageType,
              });
            }}
            invalid={!isValid && !message?.type}
            style={{ marginRight: 20, width: '200px' }}
          >
            <option value="">- {t('Message.Type.SelectType')}</option>
            {Object.keys(UserMessageType).map((value) => (
              <option key={value} value={value}>
                {t(`Message.Type.${value}`)}
              </option>
            ))}
          </Select>

          <SelectProduct
            name={'product'}
            productId={message?.productId ?? undefined}
            setProductId={(productId) => {
              setHasChanges(true);
              setMessage({
                ...message,
                productId,
              });
            }}
            style={{ marginRight: 20, width: '200px' }}
            disabled={
              message && new Date(message?.publishDate || '') < new Date()
            }
          />

          <div>
            <Label htmlFor="publishDate">{t('Message.PublishDate.Text')}</Label>
            <DateTimePicker
              name="publishDate"
              date={message?.publishDate ?? ''}
              setDate={(date) => {
                setHasChanges(true);
                setMessage({
                  ...message,
                  publishDate: date
                    ? date.toISOString()
                    : new Date().toISOString(),
                });
              }}
              isValid={
                !(isValid === false && message?.publishDate === undefined)
              }
              placeholderText={t('Message.PublishDate.Title')}
              min={new Date()}
            />
          </div>

          <div>
            <Label htmlFor="title">{t('Message.Title')}</Label>
            <Input
              type="text"
              id="title"
              name="title"
              value={message?.title ?? ''}
              invalid={!isValid && !message?.title}
              onChange={(e) => {
                setHasChanges(true);
                setMessage({
                  ...message,
                  title: e.currentTarget.value,
                });
              }}
            />

            {!isTextValid && (
              <AlertWarning style={{ marginTop: 10 }}>
                {t(`TextEditor.ValidationError.LimitExceeded`)}
              </AlertWarning>
            )}

            <Label htmlFor="body">{t('Message.Text')}</Label>
            <TextEditor
              value={message?.text as string}
              setValue={(val) => {
                setHasChanges(true);
                setMessage({
                  ...message,
                  text: val ? val : '',
                });
              }}
              messageId={message?.id ?? message?.uid ?? ''}
              style={{ maxWidth: 800 }}
              allowLinks={true}
              invalid={!isValid && !message?.text}
              validateHtmlIsEmpty={true}
            />
          </div>

          <ButtonFooter style={{ marginBottom: '50px', padding: '0 20px' }}>
            <Buttons>
              <ButtonSecondary type="button" onClick={handleCancel}>
                {t('Common.Form.Cancel')}
              </ButtonSecondary>
              <Button
                onClick={handleSave}
                type="button"
                disabled={isPosting || !hasChanges}
              >
                {t('Common.Form.Save')}
              </Button>
            </Buttons>
          </ButtonFooter>
        </div>

        <div style={{ flexBasis: '40%', flexGrow: 1 }}>
          {isPosting && <Loader>{t('Common.UI.Loading')}</Loader>}

          {!isPosting && message && (
            <Recipients
              userMessage={message}
              handleDeleteRecipients={handleDeleteRecipients}
              handleAddRecipients={handleAddRecipients}
            />
          )}
        </div>
      </div>
    </>
  );
};

MessageProperties.displayName = 'MessageProperties';
