import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import {
  Button,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { useDeleteMessageMutation } from 'api/message/messageApi';
import { Message } from 'models';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil, BsTrash } from 'react-icons/bs';

export const MessageListEditButtons = ({
  message,
}: {
  message: Message;
}): JSX.Element => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const [deleteMessage] = useDeleteMessageMutation();

  const handleDelete = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (message) {
      deleteMessage({ userMessageId: message.id as string });
    }

    setIsDeleting(false);
  };

  const handleConfirmDelete = (e: React.SyntheticEvent, show: boolean) => {
    e.stopPropagation();

    setIsDeleting(show);
  };

  return (
    <>
      {isDeleting && (
        <Modal title={t('Message.Delete.ConfirmTitle')}>
          <div>{t('Message.Delete.ConfirmText')}</div>
          <AlertWarning style={{ margin: '10px 0' }}>
            {t('Message.Delete.ConfirmWarning')}
          </AlertWarning>
          <Buttons>
            <ButtonSecondary
              type="button"
              onClick={(e) => handleConfirmDelete(e, false)}
            >
              {t('Common.Form.Cancel')}
            </ButtonSecondary>

            <Button type="button" onClick={(e) => handleDelete(e)}>
              {t('Common.UI.Yes')}
            </Button>
          </Buttons>
        </Modal>
      )}

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <LinkWithTooltip
          tooltipTitle={t('Message.Edit.TooltipTitle')}
          tooltip={t('Message.Edit.Tooltip')}
          icon={<BsPencil />}
          handleClick={() => {
            //
          }}
        />

        <LinkWithTooltip
          tooltipTitle={t('Message.Delete.TooltipTitle')}
          tooltip={t('Message.Delete.Tooltip')}
          icon={<BsTrash />}
          style={{ marginLeft: 10 }}
          handleClick={(e) => handleConfirmDelete(e, true)}
        />
      </div>
    </>
  );
};

MessageListEditButtons.displayName = 'MessageListEditButtons';
