import { LoaderFill } from '@dimatech/shared/lib/components/loader';
import { Tab, Tabs } from '@dimatech/shared/lib/components/tab';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { useGetFeatureFlagsQuery } from 'api/feature/featureApi';
import {
  featureActions,
  selectSelectedFeature,
} from 'api/feature/featureSlice';
import { config } from 'config';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FeatureList } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { FeatureProperties } from './components/FeatureProperties';
import { FeaturesList } from './components/FeaturesList';

export const FeatureFlags = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const [productId, setProductId] = useState<string>('');
  const [features, setFeatures] = useState<FeatureList[]>([]);
  const [debouncedSearch] = useDebounce(search, 300);

  const isDisabled = true;

  const { data, isLoading, isFetching } = useGetFeatureFlagsQuery(
    debouncedSearch.toLowerCase()
  );
  const selectedFeature = useAppSelector(selectSelectedFeature);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const location = useLocation();
  const searchedForName = (location?.state as Record<string, string>)
    ?.name as string;
  const searchedFeature = data?.find((flag) => flag.name === searchedForName);

  const handleSelectTab = (index: number) => {
    setSelectedTabIndex(index);
  };

  useEffect(() => {
    const productName =
      productId === config.products.dimios.id
        ? 'app-dimios'
        : productId === config.products.pios.id
        ? 'app-pios'
        : productId === config.products.dios.id
        ? 'app-dios'
        : '';

    setFeatures(
      (data ?? []).filter(
        (feature) =>
          feature.key.startsWith(productName) ||
          feature.key.startsWith('shared')
      )
    );
  }, [data, productId]);

  useEffect(() => {
    if (searchedFeature) {
      dispatch(featureActions.selectedFeature(searchedFeature));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedFeature]);

  return (
    <div style={{ width: '100%' }}>
      <ViewHeader>
        <Heading1>{t('FeatureFlags.Title')}</Heading1>
      </ViewHeader>

      {isDisabled && (
        <Card width="100%">
          <CardBody>Temp disabled, needs fixing</CardBody>
        </Card>
      )}

      {!isDisabled && (
        <>
          {isLoading ? (
            <LoaderFill />
          ) : (
            <div style={{ width: '100%' }}>
              {!selectedFeature && (
                <Tabs>
                  <Tab
                    isSelected={selectedTabIndex === 0}
                    handleSelect={() => handleSelectTab(0)}
                  >
                    {t('FeatureFlags.Regular.Title')}
                  </Tab>
                  <Tab
                    isSelected={selectedTabIndex === 1}
                    handleSelect={() => handleSelectTab(1)}
                  >
                    {t('FeatureFlags.Maintenance.Title')}
                  </Tab>
                  <Tab
                    isSelected={selectedTabIndex === 2}
                    handleSelect={() => handleSelectTab(2)}
                  >
                    {t('FeatureFlags.Dev.Title')}
                  </Tab>
                  <Tab
                    isSelected={selectedTabIndex === 3}
                    handleSelect={() => handleSelectTab(3)}
                  >
                    {t('FeatureFlags.Test.Title')}
                  </Tab>
                </Tabs>
              )}

              <Card width="100%">
                <CardBody isLoading={isLoading || isFetching}>
                  {selectedTabIndex === 0 && (
                    <FeaturesList
                      setSearch={setSearch}
                      search={search}
                      setProductId={setProductId}
                      productId={productId}
                      features={features.filter(
                        (feature) =>
                          !feature.tags?.includes('dev') &&
                          !feature.tags?.includes('maintenance') &&
                          !feature.tags?.includes('test')
                      )}
                    />
                  )}

                  {selectedTabIndex === 1 && (
                    <FeaturesList
                      setSearch={setSearch}
                      search={search}
                      setProductId={setProductId}
                      productId={productId}
                      features={features.filter(
                        (feature) =>
                          feature.tags?.includes('maintenance') &&
                          !feature.tags?.includes('test')
                      )}
                    />
                  )}

                  {selectedTabIndex === 2 && (
                    <FeaturesList
                      setSearch={setSearch}
                      search={search}
                      setProductId={setProductId}
                      productId={productId}
                      features={features.filter(
                        (feature) =>
                          feature.tags?.includes('dev') &&
                          !feature.tags?.includes('test')
                      )}
                    />
                  )}

                  {selectedTabIndex === 3 && (
                    <FeaturesList
                      setSearch={setSearch}
                      search={search}
                      setProductId={setProductId}
                      productId={productId}
                      features={features.filter((feature) =>
                        feature.tags?.includes('test')
                      )}
                    />
                  )}

                  {selectedFeature && <FeatureProperties />}
                </CardBody>
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
};

FeatureFlags.displayName = 'FeatureFlags';
