import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Invoice } from 'models';
import { RootState } from 'store';

const initialState: {
  selectedInvoices?: Invoice[];
  selectedInvoice?: Invoice;
} = {};

export const { reducer: billingReducer, actions: billingActions } = createSlice(
  {
    name: 'billing',
    initialState,
    reducers: {
      selectInvoices: (state, action: PayloadAction<Invoice[] | undefined>) => {
        state.selectedInvoices = action.payload;
      },
      selectInvoice: (state, action: PayloadAction<Invoice | undefined>) => {
        state.selectedInvoice = action.payload;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedInvoices = initialState.selectedInvoices;
        state.selectedInvoice = initialState.selectedInvoice;
      });
    },
  }
);

export const selectSelectedInvoices = (
  state: RootState
): Invoice[] | undefined => state.billing.selectedInvoices;

export const selectSelectedInvoice = (state: RootState): Invoice | undefined =>
  state.billing.selectedInvoice;
