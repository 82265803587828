import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { useGetProductsQuery } from 'api/product/productApi';
import { useTranslation } from 'react-i18next';
import { ProductsList } from './components/ProductsList';

export const Products = (): JSX.Element | null => {
  const { t } = useTranslation();

  const { data: products, isLoading } = useGetProductsQuery();

  return (
    <>
      <ViewHeader>
        <Heading1>{t('Product.Products')}</Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody isLoading={isLoading}>
          <ProductsList products={products} />
        </CardBody>
      </Card>
    </>
  );
};

Products.displayName = 'Products';
