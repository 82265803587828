import {
  Button,
  Buttons,
  ButtonSecondary,
  DatePicker,
  Label,
  Select,
  YearPicker,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { useAddOrUpdateInvoicesMutation } from 'api/billing/billingApi';
import { selectSelectedInvoices } from 'api/billing/billingSlice';
import { useAppSelector } from 'hooks';
import { Invoice, InvoiceMutation, InvoiceStatus } from 'models';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const InvoiceBulkEdit = ({
  setIsBulkEditing,
}: {
  setIsBulkEditing: (isEditing: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const selectedInvoices = useAppSelector(selectSelectedInvoices);

  const [invoice, setInvoice] = useState<InvoiceMutation>({});
  const [addOrUpdate] = useAddOrUpdateInvoicesMutation();

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!selectedInvoices || selectedInvoices.length === 0) {
      return;
    }

    const newInvoices = selectedInvoices.map((selectedInvoice) => {
      const newInvoice: Invoice = {
        ...selectedInvoice,

        sentDate: invoice.sentDate ?? selectedInvoice.sentDate ?? undefined,
        status: invoice.status ?? selectedInvoice.status ?? undefined,
        startYear: invoice.startYear ?? selectedInvoice.startYear ?? undefined,
        endYear: invoice.endYear ?? selectedInvoice.endYear ?? undefined,
      };

      return newInvoice;
    });

    addOrUpdate(newInvoices);

    setIsBulkEditing(false);
  };

  useEffect(() => {
    if (selectedInvoices && selectedInvoices.length === 1) {
      setInvoice(selectedInvoices[0] as InvoiceMutation);
    } else {
      setInvoice({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvoices]);

  return invoice ? (
    <Modal
      title={t('Invoice.Edit.Title', {
        count: selectedInvoices?.length,
      })}
      handleKeyEnter={handleSave}
      handleKeyEsc={() => setIsBulkEditing(false)}
    >
      <Style>
        <div>
          <div>
            <Label htmlFor="startYear">{t('Invoice.StartYear')}</Label>
            <YearPicker
              name="startYear"
              date={invoice?.startYear?.toString() ?? ''}
              setDate={(date: Date | null) =>
                setInvoice({
                  ...invoice,
                  startYear: Number(date?.getFullYear()),
                })
              }
            />
          </div>
          <div>
            <Label htmlFor="endYear">{t('Invoice.EndYear')}</Label>
            <YearPicker
              name="endYear"
              date={invoice?.endYear?.toString() ?? ''}
              setDate={(date: Date | null) =>
                setInvoice({
                  ...invoice,
                  endYear: Number(date?.getFullYear()),
                })
              }
            />
          </div>
        </div>

        <div>
          <div>
            <Label htmlFor="status">{t('Invoice.Status')}</Label>
            <Select
              id="status"
              name="status"
              value={invoice.status ?? ''}
              onChange={(e) =>
                setInvoice({
                  ...invoice,
                  status: e.currentTarget.value as InvoiceStatus,
                })
              }
            >
              <option value="">- {t('Common.UI.Select')}</option>
              {Object.keys(InvoiceStatus).map((value) => (
                <option key={value} value={value}>
                  {t(`Billing.InvoiceStatus.${value}`)}
                </option>
              ))}
            </Select>
          </div>

          <div>
            <Label htmlFor="sentDate">{t('Invoice.SentDate')}</Label>
            <DatePicker
              name="sentDate"
              date={invoice?.sentDate ?? null}
              setDate={(date: Date | null) =>
                setInvoice({
                  ...invoice,
                  sentDate: date ? date.toISOString().toString() : '',
                })
              }
            />
          </div>
        </div>
      </Style>

      <Buttons>
        <ButtonSecondary type="button" onClick={() => setIsBulkEditing(false)}>
          {t('Common.Form.Cancel')}
        </ButtonSecondary>

        <Button type="button" onClick={handleSave}>
          {t('Common.Form.Save')}
        </Button>
      </Buttons>
    </Modal>
  ) : null;
};

InvoiceBulkEdit.displayName = 'InvoiceBulkEdit';

const Style = styled.form`
  > div {
    width: 100%;
    display: flex;
    align-items: flex-end;

    > div {
      width: 50%;
    }

    > div:first-of-type {
      padding-right: 20px;
    }
  }
`;
