import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Theme } from '@dimatech/shared/lib/themes';
import { Variation } from 'models';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCircleFill } from 'react-icons/bs';
import styled, { withTheme } from 'styled-components';
import { TargetEdit } from './TargetEdit';

export const TargetsList = withTheme(
  ({
    variations,
    setVariations,
    theme,
  }: {
    variations: Variation[];
    setVariations: (variations: Variation[]) => void;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div>
        <Heading3 style={{ marginTop: 30 }}>
          {t('FeatureFlags.Users.Title')}
        </Heading3>

        {variations.length > 0 && (
          <Style>
            {variations.map((variation, index) => (
              <Fragment key={index}>
                <div>
                  <VariationStyle>
                    {variation.value === true ? (
                      <div
                        style={{
                          borderBottom: `2px solid ${theme.colors.success}`,
                        }}
                      >
                        <BsCircleFill
                          style={{
                            color: theme.colors.success,
                          }}
                        />
                        <div>{t('FeatureFlags.OnOff.On')}</div>
                      </div>
                    ) : (
                      <div
                        style={{
                          borderBottom: `2px solid ${theme.colors.error}`,
                        }}
                      >
                        <BsCircleFill
                          style={{
                            color: theme.colors.error,
                          }}
                        />
                        <div>{t('FeatureFlags.OnOff.Off')}</div>
                      </div>
                    )}
                  </VariationStyle>

                  {variation.users && (
                    <TargetEdit
                      selectedVariation={variation}
                      variations={variations}
                      setVariations={setVariations}
                    />
                  )}
                </div>
              </Fragment>
            ))}
          </Style>
        )}
      </div>
    );
  }
);

TargetsList.displayName = 'TargetsList';

const Style = styled.div`
  padding: 5px;

  > div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  table {
    border-top: 1px solid
      ${({ theme }: { theme: Theme }) => theme.colors.border};
  }
`;

const VariationStyle = styled.div`
  margin-bottom: 0;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    > svg {
      margin-right: 7px;
    }
  }
`;
