import { ButtonLink } from '@dimatech/shared/lib/components/form';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Theme } from '@dimatech/shared/lib/themes';
import produce from 'immer';
import { Clause, Op } from 'models';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { BsPlus } from 'react-icons/bs';
import { withTheme } from 'styled-components';

export const ClauseAdd = withTheme(
  ({
    clauses,
    setClauses,
    setIsValid,
    isValid,
    theme,
  }: {
    clauses: Clause[];
    setClauses: (clauses: Clause[]) => void;
    setIsValid: (isValid: boolean) => void;
    isValid: boolean;
    theme: Theme;
  }): JSX.Element => {
    const { t } = useTranslation();

    const handleAddClause = (): void => {
      if (
        clauses.some(
          (clause) =>
            clause.values?.length === 0 ||
            clause.contextKind === '' ||
            typeof clause.negate !== 'boolean'
        )
      ) {
        setIsValid(false);
        return;
      }
      setClauses(
        produce(clauses, (draftState) => {
          draftState.push({
            op: Op.In,
            values: [],
            negate: null,
            contextKind: '',
            attribute: 'key',
          });
        })
      );
    };

    return (
      <div>
        <TooltipContent
          id="tt-clause-add"
          title={t('FeatureFlags.Rule.Clause.Add.TooltipTitle')}
          text={t('FeatureFlags.Rule.Clause.Add.Tooltip')}
        />
        <ButtonLink
          style={{
            margin: '10px 5px',
            borderColor:
              !isValid && clauses.length === 0 ? theme.colors.error : '',
          }}
          data-tip
          data-for="tt-clause-add"
          onClick={handleAddClause}
        >
          <BsPlus />
          {t('FeatureFlags.Rule.Clause.Add.Title')}
        </ButtonLink>
      </div>
    );
  }
);

ClauseAdd.displayName = 'ClauseAdd';
