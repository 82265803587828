import {
  Button,
  ButtonSecondary,
  Buttons,
} from '@dimatech/shared/lib/components/form';
import { Loader } from '@dimatech/shared/lib/components/loader';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { selectFilterUsers } from 'api/user/filterUsersSlice';
import { useLazySearchQuery } from 'api/user/userApi';
import { selectSelectedUsers } from 'api/user/userSlice';
import { UserFilters } from 'features/users/components/UserFilters';
import { useAppSelector } from 'hooks';
import { Paginator, Recipient, SortDirection } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { RecipientsSearchResult } from './RecipientsSearchResult';

export const RecipientsAdd = ({
  handleAddRecipients,
  setIsAddingRecipients,
}: {
  handleAddRecipients: (users: Recipient[]) => void;
  setIsAddingRecipients: (isAdding: boolean) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const filter = useAppSelector(selectFilterUsers);
  const selectedUsers = useAppSelector(selectSelectedUsers);

  const [users, setUsers] = useState<Recipient[]>([]);

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 10000,
    orderBy: 'email',
    orderDirection: SortDirection.Asc,
  };

  const [search, { data, isLoading, isFetching }] = useLazySearchQuery();

  const handleSave = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    handleAddRecipients(users);
  };

  const handleSearch = (paginator: Paginator = initialPaginator) => {
    search({ paginator, ...filter }, true);
  };

  useEffect(() => {
    if (selectedUsers) {
      const users = selectedUsers.map((user) => ({
        userId: user.id,
        email: user.email,
        uid: user.uid,
      }));

      setUsers(users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  return (
    <Modal
      handleKeyEsc={() => setIsAddingRecipients(false)}
      title={t('Message.Recipient.Add.Title')}
      style={{ width: '90%', maxWidth: 1100 }}
    >
      <Style>
        <div>
          <UserFilters />

          <Button type="button" onClick={() => handleSearch()}>
            {t('User.Search')}
          </Button>
        </div>

        {(isLoading || isFetching) && <Loader>{t('Common.UI.Loading')}</Loader>}

        {!isLoading && !isFetching && (
          <RecipientsSearchResult
            data={data}
            handlePaginatorChange={handleSearch}
            initialPaginator={initialPaginator}
          />
        )}
      </Style>

      <Buttons style={{ margin: '10px' }}>
        <ButtonSecondary
          type="button"
          onClick={() => setIsAddingRecipients(false)}
        >
          {t('Common.Form.Cancel')}
        </ButtonSecondary>

        <Button
          type="button"
          onClick={handleSave}
          disabled={users.length === 0}
        >
          {t('Message.Recipient.Add.Add')}
        </Button>
      </Buttons>
    </Modal>
  );
};

RecipientsAdd.displayName = 'RecipientsAdd';

const Style = styled.div`
  > div:first-of-type {
    padding: 0 10px 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > input {
      width: 180px;
      padding-right: 0px;
    }

    > button {
      min-width: 60px;
    }
  }
`;
