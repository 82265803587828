import { CommonRoles } from '@dimatech/features-core/lib/models';
import { SearchUsers, User } from '@dimatech/shared/lib/models';

export type AdminSearchUsers = Omit<SearchUsers, 'role'> & {
  isActive?: boolean;
  roles?: CommonRoles[];
  lastLoginFrom?: string | null;
  lastLoginTo?: string | null;
};

export enum IsActive {
  true = 'true',
  false = 'false',
}

export type FilterUsers = {
  email?: string;
  userId?: string;
  isActive?: boolean;
  roles?: CommonRoles[];
  lastLoginFrom?: string | null;
  lastLoginTo?: string | null;
  productId?: string;
  customerId?: string;
};

export type AdminUser = User & {
  isSelected: boolean;
  uid: string;
};

export type ResearcherUser = User & {
  products?: string[];
};
