import { CommonRoles } from '@dimatech/features-core/lib/models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterUsers } from 'models';
import { RootState } from 'store';

type State = {
  filter: FilterUsers;
};

const initialState: State = {
  filter: {
    roles: [CommonRoles.CustomerAccountAdmin, CommonRoles.CustomerAdmin],
    isActive: true,
  },
};

export const { reducer: filterUsersReducer, actions: filterUsersActions } =
  createSlice({
    name: 'filterUsers',
    initialState,
    reducers: {
      setFilter: (state, action: PayloadAction<FilterUsers>) => {
        state.filter = action.payload;
      },
      resetFilter: (state) => {
        state.filter = initialState.filter;
      },
    },
    extraReducers: {},
  });

export const selectFilterUsers = (state: RootState): FilterUsers =>
  state.filterUsers.filter;
