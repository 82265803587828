import { AlertWarning } from '@dimatech/shared/lib/components/Alert';
import { TextEditor } from '@dimatech/shared/lib/components/TextEditor';
import { Button, ButtonSecondary } from '@dimatech/shared/lib/components/form';
import { config } from '@dimatech/shared/lib/config';
import { useUpdateFeatureFlagMutation } from 'api/feature/featureApi';
import {
  featureActions,
  selectSelectedFeature,
} from 'api/feature/featureSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Variation } from 'models';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const VariationEdit = ({
  variation,
}: {
  variation: Variation;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedFeature = useAppSelector(selectSelectedFeature);

  const [text, setText] = useState(variation?.value as string);
  const [isValid, setIsValid] = useState(true);
  const [isTextValid, setIsTextValid] = useState(true);

  const [updateFeatureFlag] = useUpdateFeatureFlagMutation();

  const handleSave = () => {
    setIsValid(true);
    setIsTextValid(true);

    const limitInBytes = config.ui.textEditor.maxBytes;
    const textBytes = new TextEncoder().encode(text);

    if (!text) {
      setIsValid(false);
      return;
    }
    if (textBytes.length > limitInBytes) {
      setIsTextValid(false);
      return;
    }

    if (selectedFeature?.key) {
      updateFeatureFlag({
        actions: [
          {
            ...selectedFeature,
            kind: 'UpdateVariation',
            variationId: variation.id,
            value: text,
          },
        ],
        key: selectedFeature?.key,
      });
    }
  };

  const handleCancel = () => {
    dispatch(featureActions.selectedVariation());
  };

  useEffect(() => {
    setIsValid(true);
    setIsTextValid(true);
  }, [variation]);

  return (
    <Style>
      {!isTextValid && (
        <AlertWarning>
          {t(`TextEditor.ValidationError.LimitExceeded`)}
        </AlertWarning>
      )}

      <TextEditor
        value={text ?? ''}
        setValue={(val) => setText(val ? val : '')}
        messageId={variation.id}
        allowLinks={false}
        invalid={!isValid && !text}
        validateHtmlIsEmpty={true}
      />

      <ButtonSecondary type="button" onClick={handleCancel}>
        {t('Common.Form.Cancel')}
      </ButtonSecondary>
      <Button onClick={handleSave} type="button">
        {t('Common.Form.Save')}
      </Button>
    </Style>
  );
};

VariationEdit.displayName = 'VariationEdit';

const Style = styled.div`
  margin: 10px 0 20px 0;

  > button {
    margin-top: 10px;
    min-width: 100px;

    &:last-of-type {
      margin-left: 20px;
    }
  }
`;
