import { Checkbox, Label } from '@dimatech/shared/lib/components/form';
import { useSortablePaginator } from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { LinkWithTooltip } from '@dimatech/shared/lib/components/tooltip';
import { formatDate } from '@dimatech/shared/lib/utils';
import { selectSelectedUsers, userActions } from 'api/user/userSlice';
import { parseISO } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'hooks';
import { AdminUser, PaginatedResult, Paginator } from 'models';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckAll, BsX } from 'react-icons/bs';

export const RecipientsSearchResult = ({
  data,
  initialPaginator,
  handlePaginatorChange,
}: {
  data?: PaginatedResult<AdminUser>;
  initialPaginator: Paginator;
  handlePaginatorChange: (paginator: Paginator) => void;
}): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedUsers = useAppSelector(selectSelectedUsers);
  const [users, setUsers] = useState<AdminUser[]>();

  const noOfSelected = selectedUsers ? selectedUsers.length : 0;
  const noOfTotal = data?.records.length;
  const isAllChecked = noOfSelected === noOfTotal;

  const { sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange,
  });

  const handleSelectAll = (checked: boolean) => {
    const newUsers = users?.map((user) => ({
      ...user,
      isSelected: checked,
    }));

    setUsers(newUsers);

    dispatch(
      userActions.selectedUsers(newUsers?.filter((user) => user.isSelected))
    );
  };

  const handleSelect = (e: ChangeEvent<HTMLInputElement>, userId: string) => {
    const newUsers = [...(users ?? [])];
    const index = newUsers.findIndex((user) => user.id === userId);

    newUsers[index] = {
      ...newUsers[index],
      isSelected: e.target.checked,
    };

    setUsers(newUsers);

    dispatch(
      userActions.selectedUsers(newUsers.filter((user) => user.isSelected))
    );
  };

  useEffect(() => {
    if (data) {
      setUsers(data?.records);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.records]);

  return users && users.length === 0 ? (
    <div>{t('User.NoMatches')}</div>
  ) : (
    <>
      <LinkWithTooltip
        style={{ margin: '10px 20px 10px 0' }}
        handleClick={() => handleSelectAll(!isAllChecked)}
        title={
          isAllChecked ? t(`Common.UI.DeselectAll`) : t(`Common.UI.SelectAll`)
        }
        icon={isAllChecked ? <BsX /> : <BsCheckAll />}
        disabledTimerInMs={1}
      />

      {users && (
        <div style={{ overflowY: 'scroll', height: '50%', maxHeight: 500 }}>
          <Table>
            <thead>
              <tr>
                <Th style={{ width: 35 }} isExportIgnored={true} />
                <Th sortKey="email" sorter={sorter}>
                  {t('User.Email')}
                </Th>
                <ThRight
                  sortKey="lastLoginDate"
                  sorter={sorter}
                  isExportIgnored={true}
                >
                  {t('User.LastLogin')}
                </ThRight>
              </tr>
            </thead>

            <tbody>
              {users.map((item, index) => (
                <Tr key={index}>
                  <Td data-export-ignore={true}>
                    <Checkbox
                      onChange={(e) => {
                        handleSelect(e, item.id ?? item.uid);
                      }}
                      id={index + `_${item.id}`}
                      name={item.id}
                      value={item.id}
                      checked={!!item.isSelected}
                    />
                  </Td>
                  <Td>
                    <Label
                      style={{ margin: 0 }}
                      htmlFor={index + `_${item.id}`}
                    >
                      {item.email}
                    </Label>
                  </Td>
                  <TdRight
                    data-export-ignore={true}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Label
                      style={{ margin: 0, justifyContent: 'end' }}
                      htmlFor={index + `_${item.id}`}
                    >
                      {item.lastLoginDate
                        ? formatDate(parseISO(item.lastLoginDate))
                        : '-'}
                    </Label>
                  </TdRight>
                </Tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

RecipientsSearchResult.displayName = 'RecipientsSearchResult';
