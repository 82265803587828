import { BadgeMini } from '@dimatech/shared/lib/components/Badge';
import {
  Table,
  Td,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { htmlDecode } from '@dimatech/shared/lib/utils';
import {
  featureActions,
  selectSelectedVariation,
} from 'api/feature/featureSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Feature, Variation } from 'models';
import { Fragment, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { BsFillRecordFill, BsPencil } from 'react-icons/bs';
import styled from 'styled-components';
import { VariationEdit } from './VariationEdit';

export const VariationList = ({
  feature,
}: {
  feature: Feature;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedVariation = useAppSelector(selectSelectedVariation);
  const [textVariations, setTextVariations] = useState<Variation[]>([]);

  const handleSelect = (variation: Variation) => {
    dispatch(
      featureActions.selectedVariation(
        selectedVariation?.id === variation.id ? undefined : variation
      )
    );
  };

  useEffect(() => {
    if (feature.variations) {
      const variations = feature.variations.filter(
        (variation) => typeof variation.value !== 'boolean'
      );
      setTextVariations(variations);
    }
  }, [feature]);

  return (
    <>
      {textVariations.length > 0 && (
        <>
          <Heading3 style={{ marginTop: 30 }}>
            {t('FeatureFlags.Variations.Title')}
          </Heading3>

          <Table>
            <thead>
              <tr>
                <Th style={{ width: 35 }} />
                <Th>{t('FeatureFlags.Variations.Value')}</Th>
                <ThRight style={{ width: 35 }} />
              </tr>
            </thead>
            <tbody>
              {textVariations?.map((variation, index) => (
                <Fragment key={variation.id}>
                  <Tr
                    key={index}
                    isSelected={selectedVariation?.id === variation.id}
                    onSelect={() => handleSelect(variation)}
                  >
                    <Td>
                      <BsFillRecordFill />
                    </Td>
                    <TdStyle
                      dangerouslySetInnerHTML={{
                        __html: htmlDecode(variation.value as string) ?? '',
                      }}
                    />
                    <Td>
                      <EditButtons />
                    </Td>
                  </Tr>
                  {selectedVariation && selectedVariation.id === variation.id && (
                    <Tr>
                      <Td colSpan={3}>
                        <VariationEdit variation={variation} />
                      </Td>
                    </Tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

VariationList.displayName = 'VariationList';

const TdStyle = styled(Td)`
  > p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const EditButtons = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <TooltipContent
        id="tt-variation-edit"
        title={t('FeatureFlags.Variations.Edit.TooltipTitle')}
        text={t('FeatureFlags.Variations.Edit.Tooltip')}
      />

      <BadgeMini data-tip data-for="tt-variation-edit">
        <BsPencil />
      </BadgeMini>
    </>
  );
};

EditButtons.displayName = 'EditButtons';
