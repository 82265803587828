import { SearchUser } from '@dimatech/features-core/lib/components/SearchUser';
import { ButtonSecondary } from '@dimatech/shared/lib/components/form';
import { Admin } from '@dimatech/shared/lib/models';
import { contextActions, selectFilter } from 'api/feature/contextSlice';
import { SelectCustomer } from 'components/SelectCustomer';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';

export const ContextFilters = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectFilter);

  const handleChangeCustomer = (customerId: string) => {
    dispatch(contextActions.setFilter({ ...filter, customerId }));
  };

  const handleChangeUserId = (user: Admin | null) => {
    const userId = user?.id;
    dispatch(contextActions.setFilter({ ...filter, userId }));
  };

  const handleResetFilter = () => {
    dispatch(contextActions.resetFilter());
  };

  return (
    <>
      <div>
        <SearchUser setUser={handleChangeUserId} admin={true} />
      </div>

      <SelectCustomer
        name={'customer'}
        customerId={filter.customerId}
        setCustomerId={handleChangeCustomer}
      />

      <ButtonSecondary
        type="button"
        onClick={() => handleResetFilter()}
        style={{ marginRight: 20 }}
      >
        {t('Context.ResetFilters')}
      </ButtonSecondary>
    </>
  );
};

ContextFilters.displayName = 'ContextFilters';
