import { base, Color, Theme, ThemeType } from '@dimatech/shared/lib/themes';

// Light theme, only overrides necessary properties
export const light: Theme = {
  ...base,
  name: 'Dimatech Light',
  type: ThemeType.Light,

  colors: {
    ...base.colors,

    primary: Color.DimatechBlue,
    primaryVariant: Color.DimatechBlueDark,
    onPrimary: Color.White,

    secondary: Color.CaribbeanGreen,
    secondaryVariant: Color.CaribbeanGreenDark,
    onSecondary: Color.White,

    secondaryDim: Color.CaribbeanGreenDark,
    onSecondaryDim: Color.Grey80,

    chart: {
      ...base.colors.chart,

      primary: Color.CaribbeanGreen,
      primaryHighlight: Color.CaribbeanGreenDark,

      secondary: Color.DimiosBlue,
      secondaryHighlight: Color.DimiosBlueDark,
    },
  },
};
