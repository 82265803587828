import { Message as MessageFeature } from 'features/message/Message';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Message = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Navigation.Messages')}>
      <MessageFeature />
    </View>
  );
};

Message.displayName = 'Message';
