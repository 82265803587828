import { TooltipContent } from '@dimatech/shared/lib/components/tooltip';
import { useTranslation } from 'react-i18next';

export const FeatureFlagsStatusTooltips = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <>
      <TooltipContent
        id="tt-status-on"
        text={t('FeatureFlags.OnOff.FeatureIsOn')}
      />
      <TooltipContent
        id="tt-status-off"
        text={t('FeatureFlags.OnOff.FeatureIsOff')}
      />
    </>
  );
};

FeatureFlagsStatusTooltips.displayName = 'FeatureFlagsStatusTooltips';
