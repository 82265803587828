import { appReducer } from '@dimatech/features-core/lib/api/appSlice';
import { featuresCoreApi } from '@dimatech/features-core/lib/api/featuresCoreApi';
import { combineReducers } from '@reduxjs/toolkit';
import { adminApi } from 'api/adminApi';
import { adminReducer } from 'api/adminSlice';
import { billingReducer } from 'api/billing/billingSlice';
import { customerReducer } from 'api/customer/customerSlice';
import { contextReducer } from 'api/feature/contextSlice';
import { featureReducer } from 'api/feature/featureSlice';
import { messageReducer } from 'api/message/messageSlice';
import { filterUsersReducer } from 'api/user/filterUsersSlice';
import { userReducer } from 'api/user/userSlice';

export const rootReducer = combineReducers({
  customer: customerReducer,
  billing: billingReducer,
  user: userReducer,
  filterUsers: filterUsersReducer,
  app: appReducer,
  admin: adminReducer,
  features: featureReducer,
  message: messageReducer,
  context: contextReducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [featuresCoreApi.reducerPath]: featuresCoreApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
