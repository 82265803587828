import { adminApi, CacheTags } from 'api/adminApi';
import { License, LicenseType } from 'models';

const licenseApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get licenses types list
     */
    getLicensesTypes: build.query<LicenseType[], string | void>({
      query: (productId) =>
        `license/licenseTypesList${productId ? `?productId=${productId}` : ''}`,
      providesTags: [CacheTags.License],
      transformResponse: (result: LicenseType[]) => {
        return result.sort(
          (a, b) => Number(a.isDiscontinued) - Number(b.isDiscontinued)
        );
      },
    }),

    /**
     * Update customer license
     */
    updateCustomerLicense: build.mutation({
      query: ({
        customerId,
        productId,
        license,
      }: {
        customerId: string;
        productId: string;
        license: License;
      }) => ({
        url: `license/${customerId}/${productId}/license`,
        method: 'PUT',
        body: license,
      }),
      invalidatesTags: [CacheTags.License],
      transformResponse: (result: License, _meta, _arg) => {
        return {
          ...result,
        };
      },
    }),

    /**
     * Add customer license
     */
    addCustomerLicense: build.mutation({
      query: ({
        customerId,
        productId,
        license,
      }: {
        customerId: string;
        productId: string;
        license: License;
      }) => ({
        url: `license/${customerId}/${productId}/license`,
        method: 'POST',
        body: license,
      }),
      invalidatesTags: [CacheTags.License, CacheTags.Customer],
      transformResponse: (result: License, _meta, _arg) => {
        return {
          ...result,
        };
      },
    }),

    /**
     * Delete customer license
     */
    deleteCustomerLicense: build.mutation({
      query: ({
        customerId,
        productId,
        id,
      }: {
        customerId: string;
        productId: string;
        id: string;
      }) => ({
        url: `license/${customerId}/${productId}/license`,
        method: 'DELETE',
        body: {
          id,
        },
      }),
      invalidatesTags: [CacheTags.License, CacheTags.Customer],
    }),
  }),
});

export const {
  useGetLicensesTypesQuery,
  useUpdateCustomerLicenseMutation,
  useAddCustomerLicenseMutation,
  useDeleteCustomerLicenseMutation,
} = licenseApi;
