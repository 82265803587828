import { AlertErrors } from '@dimatech/shared/lib/components/AlertErrors';
import { CopyToClipboard } from '@dimatech/shared/lib/components/CopyToClipboard';
import {
  Button,
  ButtonFooterWithToast,
  Buttons,
  ButtonSecondary,
  Input,
  Label,
} from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { useAddUserMutation, useUpdateUserMutation } from 'api/user/userApi';
import { selectSelectedAdmin, userActions } from 'api/user/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Admin as AdminUser } from 'models';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const GlobalAdmin = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selectedAdmin = useAppSelector(selectSelectedAdmin);

  const [addUser, { isLoading: addPosting, error: addErrors }] =
    useAddUserMutation();
  const [updateUser, { isLoading: updatePosting, error: updateErrors }] =
    useUpdateUserMutation();

  const [admin, setAdmin] = useState<AdminUser>({});
  const [isChangesSaved, setIsChangesSaved] = useState(false);
  const isPosting = updatePosting || addPosting;

  useEffect(() => {
    if (selectedAdmin) {
      setAdmin(selectedAdmin);
    }
  }, [selectedAdmin]);

  const handleChange = (
    e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = e.currentTarget;

    setAdmin({
      ...admin,
      [target.name]: target.value,
    });
  };

  const handleSave = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (admin.id) {
      updateUser({
        ...admin,
        isAdmin: true,
      })
        .unwrap()
        .then(() => {
          setIsChangesSaved(true);
        });
    } else {
      addUser({
        ...admin,
        isAdmin: true,
      })
        .unwrap()
        .then(() => {
          setIsChangesSaved(true);
          dispatch(userActions.selectAdmin());
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const handleCancel = () => {
    dispatch(userActions.selectAdmin());
  };

  return admin ? (
    <div>
      {admin?.id && <Heading2 style={{ marginTop: 5 }}>{admin.email}</Heading2>}

      <AlertErrors
        error={addErrors ?? updateErrors}
        translationPath="User.ValidationError"
      />

      <div>
        <Label htmlFor="name">{t('User.Email')}</Label>
        <Input
          type="text"
          id="email"
          name="email"
          value={admin.email || ''}
          onChange={handleChange}
        />
      </div>

      {!admin.id && (
        <div>
          <Label htmlFor="name">{t('User.Password')}</Label>
          <Input
            type="password"
            id="password"
            name="password"
            onChange={handleChange}
          />
        </div>
      )}

      {admin.id && (
        <div>
          <Label htmlFor="name">{t('User.Id')}</Label>
          <div
            style={{
              marginTop: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Input style={{ maxWidth: 200 }} value={admin.id} disabled={true} />
            <CopyToClipboard
              value={admin.id}
              text={t('User.CopyId')}
              tooltipId="tt-link-copy"
            />
          </div>
        </div>
      )}

      <ButtonFooterWithToast
        isSaved={isChangesSaved}
        setIsSaved={setIsChangesSaved}
      >
        <Buttons>
          <ButtonSecondary type="button" onClick={handleCancel}>
            {t('Common.Form.Cancel')}
          </ButtonSecondary>
          <Button type="button" onClick={handleSave} disabled={isPosting}>
            {t('Common.Form.Save')}
          </Button>
        </Buttons>
      </ButtonFooterWithToast>
    </div>
  ) : null;
};

GlobalAdmin.displayName = 'GlobalAdmin';
