import { Billing as BillingFeature } from 'features/billing';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Billing = (): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <View title={t('Billing.Title')}>
      <BillingFeature />
    </View>
  );
};

Billing.displayName = 'Billing';
