import {
  CustomerLineOfBusiness,
  CustomerType,
} from '@dimatech/features-core/lib/models';
import { Number } from '@dimatech/shared/lib/components/Number';
import { ViewRow } from '@dimatech/shared/lib/components/workspace';
import { Theme } from '@dimatech/shared/lib/themes';
import { formatAsNumber } from '@dimatech/shared/lib/utils';
import { CustomerMutation } from 'models';
import { useTranslation } from 'react-i18next';
import styled, { withTheme } from 'styled-components';

export const Statistics = withTheme(
  ({
    theme,
    customers,
  }: {
    theme: Theme;
    customers: CustomerMutation[];
  }): JSX.Element => {
    const { t } = useTranslation();

    return (
      <Style>
        <Number
          color={theme.colors.primary}
          text={t('Customer.Type.Municipality')}
          formattedValue={formatAsNumber(
            customers.filter(
              (customer) => customer.customerType === CustomerType.Municipality
            ).length,
            '0'
          )}
        />

        <Number
          color={theme.colors.primary}
          text={t('Customer.Type.Region')}
          formattedValue={formatAsNumber(
            customers.filter(
              (customer) => customer.customerType === CustomerType.Region
            ).length,
            '0'
          )}
        />

        <Number
          color={theme.colors.primary}
          text={t('Customer.Type.Government')}
          formattedValue={formatAsNumber(
            customers.filter(
              (customer) => customer.customerType === CustomerType.Government
            ).length,
            '0'
          )}
        />

        <Number
          color={theme.colors.primary}
          text={t('Customer.Type.PubliclyOwnedOrganization')}
          formattedValue={formatAsNumber(
            customers.filter((customer) => {
              const lineOfBusiness =
                customer.customerType as unknown as CustomerLineOfBusiness;
              return (
                customer.customerType ===
                  CustomerType.PubliclyOwnedOrganization ||
                lineOfBusiness === CustomerLineOfBusiness.Energy ||
                lineOfBusiness === CustomerLineOfBusiness.HealthAndSocialCare ||
                lineOfBusiness === CustomerLineOfBusiness.Infrastructure ||
                lineOfBusiness === CustomerLineOfBusiness.OtherPoo ||
                lineOfBusiness === CustomerLineOfBusiness.RealEstate
              );
            }).length,
            '0'
          )}
        />

        <Number
          color={theme.colors.primary}
          text={t('Customer.Type.Other')}
          formattedValue={formatAsNumber(
            customers.filter(
              (customer) => customer.customerType === CustomerType.Other
            ).length,
            '0'
          )}
        />
      </Style>
    );
  }
);

Statistics.displayName = 'Statistics';

const Style = styled(ViewRow)`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};
  margin-top: 20px;

  > div {
    align-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
  }
`;
