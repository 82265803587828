import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Button } from '@dimatech/shared/lib/components/form';
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import {
  Card,
  CardBody,
  ViewHeader,
} from '@dimatech/shared/lib/components/workspace';
import { selectFilterUsers } from 'api/user/filterUsersSlice';
import { useLazySearchQuery } from 'api/user/userApi';
import { selectSelectedUser, userActions } from 'api/user/userSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Paginator, SortDirection } from 'models';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { User } from './components/User';
import { UserFilters } from './components/UserFilters';
import { UsersList } from './components/UsersList';

export const Users = (): JSX.Element | null => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(selectSelectedUser);
  const { accessToken } = useContext(AuthenticationContext);
  const filter = useAppSelector(selectFilterUsers);

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 10000,
    orderBy: 'email',
    orderDirection: SortDirection.Asc,
  };

  const [search, { data, isLoading, isFetching }] = useLazySearchQuery();

  const handleSearch = (paginator: Paginator = initialPaginator) => {
    dispatch(userActions.selectUser());

    if (accessToken.isLoggedIn) {
      search(
        {
          paginator,
          email: filter.email,
          userId: filter.userId,
          productId: filter.productId,
          customerId: filter.customerId,
          roles: filter.roles,
          isActive: filter.isActive,
          lastLoginFrom: filter.lastLoginFrom,
          lastLoginTo: filter.lastLoginTo,
        },
        true
      );
    }
  };

  useEffect(() => {
    dispatch(userActions.selectUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ViewHeader>
        <Heading1>{t('User.Users')}</Heading1>
      </ViewHeader>

      <Card width="100%">
        <CardBody isLoading={isLoading || isFetching}>
          <Style>
            <div>
              <UserFilters />

              <Button type="button" onClick={() => handleSearch()}>
                {t('User.Search')}
              </Button>
            </div>

            <div>
              <UsersList
                data={data}
                handlePaginatorChange={handleSearch}
                initialPaginator={initialPaginator}
              />

              {selectedUser && <User />}
            </div>
          </Style>
        </CardBody>
      </Card>
    </>
  );
};

Users.displayName = 'Users';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > div:first-of-type {
    padding: 0 10px 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > input {
      width: 220px;
      padding-right: 0px;
    }

    > button {
      min-width: 60px;
    }
  }

  > div:last-of-type {
    > div {
      margin-bottom: 20px;
      max-width: 700px;
    }
  }
`;
