import { adminApi, CacheTags } from 'api/adminApi';
import { Product } from 'models';

const productApi = adminApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Get products
     */
    getProducts: build.query<Product[], void>({
      query: () => 'product',
      providesTags: [CacheTags.Product],
      transformResponse: (result: Product[]) => {
        return result.sort((a, b) =>
          (a.name ?? '') > (b.name ?? '') ? 1 : -1
        );
      },
    }),
  }),
});

export const { useGetProductsQuery } = productApi;
