import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ContextFilter, ContextModel } from 'models/context';
import { RootState } from 'store';

type State = {
  selectedContext?: ContextModel;
  filter: ContextFilter;
};

export const initialState: State = {
  filter: {
    customerId: '',
    userId: '',
  },
};

export const { reducer: contextReducer, actions: contextActions } = createSlice(
  {
    name: 'context',
    initialState,
    reducers: {
      selectedContext: (
        state,
        action: PayloadAction<ContextModel | undefined>
      ) => {
        state.selectedContext = action.payload;
      },
      setFilter: (state, action: PayloadAction<ContextFilter>) => {
        state.filter = action.payload;
      },
      resetFilter: (state) => {
        state.filter = initialState.filter;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, (state) => {
        state.selectedContext = initialState.selectedContext;
      });
    },
  }
);

export const selectSelectedContext = (
  state: RootState
): ContextModel | undefined => state.context.selectedContext;

export const selectFilter = (state: RootState): ContextFilter =>
  state.context.filter;
