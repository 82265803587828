import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { Login as LoginComponent } from '@dimatech/features-core/lib/features/authentication/components';
import { CommonRoles } from '@dimatech/features-core/lib/models';
import { useAppDispatch } from 'hooks';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewCentered } from 'views';

export const Login = (): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { accessToken, logout } = useContext(AuthenticationContext);

  useEffect(() => {
    dispatch(appActions.resetApplication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoggedIn = () => {
    if (!accessToken.isInRole(CommonRoles.GlobalAdmin)) {
      logout();
      navigate('/unauthorized', {
        state: { from: location.pathname },
      });
    } else {
      navigate((location.state as { from: string })?.from ?? '/');
    }
  };

  return (
    <ViewCentered title={t('Login.Title')}>
      <LoginComponent
        isRestrictedLogin={true}
        handleLoggedIn={handleLoggedIn}
      />
    </ViewCentered>
  );
};

Login.displayName = 'Login';
