import { axiosBaseQueryWithReAuthentication } from '@dimatech/features-core/lib/middleware/axiosBaseQueryWithReAuthentication';
import { initializeAxiosRequestInterceptor } from '@dimatech/features-core/lib/middleware/axiosRequestInterceptor';
import { initializeAxiosConfig } from '@dimatech/shared/lib/middleware/axiosConfig';
import { initializeAxiosResponseInterceptor } from '@dimatech/shared/lib/middleware/axiosResponseInterceptor';
import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';

// Initialize an empty Admin api service that we'll inject endpoints into

export enum CacheTags {
  Product = 'Product',
  Customer = 'Customer',
  CustomerProduct = 'CustomerProduct',
  CustomerProductUser = 'CustomerProductUser',
  User = 'User',
  GlobalAdmin = 'GlobalAdmin',
  License = 'License',
  Invoice = 'Invoice',
  FeatureFlags = 'Features',
  FeatureFlag = 'FeatureFlag',
  FeatureFlagContextKind = 'FeatureFlagContextKind',
  Contexts = 'Contexts',
  EvaluateFlags = 'EvaluateFlags',
  Message = 'Message',
  MessageRecipients = 'MessageRecipients',
  Researcher = 'Researcher',
}

initializeAxiosConfig({ ...config.axiosDefaults });
initializeAxiosRequestInterceptor();
initializeAxiosResponseInterceptor();

// Api
export const adminApi = createApi({
  reducerPath: 'adminApi',
  keepUnusedDataFor: 3600,
  tagTypes: [Object.values(CacheTags).join(',')],
  baseQuery: axiosBaseQueryWithReAuthentication({
    baseUrl: config.apiUrl.product.current,
  }),
  endpoints: () => ({}),
});
